import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div``;

const BreadcrumbItem = styled(Link)`
  text-decoration: none;
  padding-right: 10px;
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.primaryTextColor};
`;
const Separator = styled.span`
  padding-right: 10px;
`;

const Breadcrumb = ({ path }) => {
  return (
    <Wrapper>
      {path.map(({ description, to }, index) => (
        <BreadcrumbItem to={to} key={description}>
          {index >= 1 && <Separator>{">"}</Separator>}
          {description}
        </BreadcrumbItem>
      ))}
    </Wrapper>
  );
};

export default Breadcrumb;
