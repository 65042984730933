import React from "react";
import styled from "styled-components";

import useOnChange from "./hooks/useOnChange";

import { base, disabled, valid, invalid } from "./styles";

export const Wrapper = styled.span`
  ${base}
  ${disabled}
  ${(props) => props.valid && valid};
  ${(props) => !props.valid && invalid};
`;

const Input = React.forwardRef(
  (
    {
      name,
      value = "",
      type = "text",
      placeholder,
      valid = true,
      disabled = false,
      onChange,
      onFocus,
      onBlur,
      className,
    },
    ref
  ) => {
    const { newValue, onChangeHandler } = useOnChange(onChange, value);

    return (
      <Wrapper valid={valid} className={className}>
        <input
          ref={ref}
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          value={newValue}
          type={type}
          onChange={onChangeHandler}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Wrapper>
    );
  }
);

export default Input;
