import { css } from "styled-components";

export const FONT_BASE = "'10";
export const pixelToRem = (px, base = FONT_BASE) => `${(1 / base) * px}rem`;

export const DEFAULT_PADDING = "15px";

export const mediaSizes = {
  small: "screen and (max-width: 39.9375rem)",
  medium: "screen and (min-width: 40rem)",
  mediumOnly: "screen and (min-width: 40rem) and (max-width: 63.9375rem)",
  large: "screen and (min-width: 64rem)",
  largeOnly: "screen and (min-width: 64rem) and (max-width: 74.9375rem)",
};

export const mediaQueries = Object.keys(mediaSizes).reduce((acc, size) => {
  acc[size] = (strings, ...interpolations) => css`
    @media ${mediaSizes[size]} {
      ${css(strings, ...interpolations)};
    }
  `;
  return acc;
}, {});

export const palette = {
  defaultColor: "#e3e3e3",
  initialColor: "#a9a9a9",
  primaryColor: "#A62A13",
  secondaryColor: "#7D7B7B",
  lightGrayColor: "#AFAFAB",
  mediumGrayColor: "#5E5E5D",
  darkerGrayColor: "#484848",
  darkerColor: "#2a2a2a",

  primaryTextColor: "#2A2A2A",
  secondaryTextColor: "#7D7B7B",
  tertiaryTextColor: "#A2A2A2",
  quaternaryTextColor: "#F3F3F3",
  quinternaryTextColor: "#0DB8D9",

  dangerColor: "#D93617",
  alertColor: "#E6710E",
  infoColor: "#0DB8D9",
  successColor: "#02D984",
  inactiveColor: "#A2A2A2",
};
