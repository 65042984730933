import { useState, useEffect } from "react";

const { REACT_APP_API_BASE_URI } = process.env;

const AbortController = window.AbortController;

const useFetchApi = (url, method = "GET") => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const controller = new AbortController();
  const uri = `${REACT_APP_API_BASE_URI}${url}`;

  const startFetch = async (body, token, overrideHeaders = {}, options = { file: false }) => {
    const authorizationHeaders =
      (token && { Authorization: `Bearer ${token}` }) || {};

    const defaultHeaders = options.file ? {} : { "Content-Type": "application/json", };

    let headers = {
      ...defaultHeaders,
      ...authorizationHeaders,
      ...overrideHeaders,
    };

    setIsFetching(true);
    setError(null);
    try {
      const response = await fetch(uri, {
        method,
        body,
        headers,
        signal: controller.signal,
      });

      const res = await response.json();

      if (!response.ok) {
        throw res;
      }

      setData(res);
    } catch (err) {
      setError(err);
    } finally {
      setIsFetching(false);
    }
  };

  const cancelFetch = () => controller.abort();

  useEffect(() => {
    return () => {
      controller.abort();
      setError(null);
      setData(null);
    };
    // eslint-disable-next-line
  }, []);

  return {
    isFetching,
    data,
    error,
    startFetch,
    cancelFetch,
  };
};

export default useFetchApi;
