import React from "react";

import Context from "./SidebarContext";

const SidebarProvider = ({ children }) => {
  const [hidden, setHidden] = React.useState(true);
  const value = {
    hidden,
    setHidden,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default SidebarProvider;
