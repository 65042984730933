import React from "react";
import styled from "styled-components";

import StyledButton from "../Button";
import StyledIcon from "../Icon";
import Input from "../Input";
import ButtonLink from "../ButtonLink";

import NewsletterAlertModal from "../../containers/alerts/NewsletterAlertModalContainer";

import logo from "../../assets/images/logoxlung.svg";

import { DEFAULT_PADDING } from "../../settings";

const mediaSizeBotton = "900px";
const data = new Date();
const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

const Wrapper = styled.footer`
  color: ${(props) => props.theme.palette.secondaryTextColor};
  font-size: 16px;
  min-height: 80px;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.initialColor};
`;

const Top = styled.div`
  padding-left: 90px;
  padding-right: ${DEFAULT_PADDING};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 400px) {
    flex-direction: column;
    position: relative;
  }
`;

const Langs = styled.div`
  color: ${(props) => props.theme.palette.primaryTextColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  max-width: 400px;

  @media (max-width: 550px) {
    flex-direction: column;
    position: relative;
  }
`;

const ButtonBackToTop = styled(StyledButton)`
  height: 40px;
  width: 120px;
  transform: translate(0px, -15px);
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  transform: rotate(90deg);
`;

const IconButton = styled(StyledIcon)`
  fill: white;
`;

const Bottom = styled.div`
  padding: ${DEFAULT_PADDING} 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${mediaSizeBotton}) {
    flex-direction: column;
    position: relative;
  }
`;

const FooterElement = styled.section`
  display: flex;
  flex-direction: column;

  @media (max-width: ${mediaSizeBotton}) {
    margin: 10px auto;
  }
`;

const ErrorMessage = styled.p`
  margin: 5px 0 0;
  font-size: 0.8em;
  color: ${(props) => props.theme.palette.dangerColor};
`;

const Icon = styled(StyledIcon)`
  fill: ${(props) => props.theme.palette.primaryColor};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled(StyledButton)`
  margin-left: 20px;
`;

const renderList = (list) =>
  list.map((item, index) => (
    <ButtonLink key={index} to={item.link} active={item.active}>
      {item.name}
    </ButtonLink>
  ));

const langs = [
  { name: "Português (Brasil)", link: "#", active: true },
  { name: "English (US)", link: "#", active: false },
  { name: "Español", link: "#", active: false },
  { name: "Français (France)", link: "#", active: false },
];

const optionsList = [
  { name: "Dê sua opinião", link: "/opinion", active: false },
  { name: "Contato", link: "mailto:contact@xlung.net", active: false },
  { name: "Quem somos", link: "https://xlung.net/quem-somos", active: false },
  { name: "Acesso Institucional", link: "https://xlung.net/institution/login", active: false },
];

const socialNetworkList = [
  {
    name: (
      <>
        <Icon icon="facebook" /> @xlungofficial
      </>
    ),
    link: "https://www.facebook.com/xlungofficial",
    active: false,
  },
  {
    name: (
      <>
        <Icon icon="twitter" /> @xlungnet
      </>
    ),
    link: "https://twitter.com/xlungnet",
    active: false,
  },
  {
    name: (
      <>
        <Icon icon="instagram" /> @xlungofficial
      </>
    ),
    link: "https://www.instagram.com/xlungofficial/?hl=pt-br",
    active: false,
  },
  {
    name: (
      <>
        <Icon icon="youtube" /> @TheXlung
      </>
    ),
    link: "https://www.youtube.com/user/TheXlung",
    active: false,
  },
];

const bottomElements = ({
  newsletterRegisterEmailField,
  onSubmitNewsletterForm,
  newsletterFormErrors,
  newsletterFormValues,
}) => [
  <>
    <img src={logo} alt="Xlung Logo" height={50} width={120} />
    <span>&copy; {data.getFullYear()} xlung.net</span>
  </>,
  <>{renderList(optionsList)}</>,
  <>{renderList(socialNetworkList)}</>,
  // <>
  //   <span>Receba as nossas atualizações</span>
  //   <Form onSubmit={onSubmitNewsletterForm}>
  //     <label> Email </label>
  //     <FormField>
  //       <Input
  //         ref={newsletterRegisterEmailField}
  //         name="email"
  //         value={newsletterFormValues.email}
  //         placeholder="Ex: amanda@exemplo.com"
  //       />
  //       <Button palette="primary" variant="filled" size="medium">
  //         Ok
  //       </Button>
  //     </FormField>
  //     {newsletterFormErrors.email?.message && (
  //       <ErrorMessage>{newsletterFormErrors.email.message}</ErrorMessage>
  //     )}
  //   </Form>
  // </>,
  <>
    <span>Av. Dom Luis, 1200 - 8° Andar, SI 805 / 806</span>
    <span>Meireles, CEP 60.160-230, Fortaleza-CE</span>
    <span>Telefone: +55 85 3039-5629</span>
  </>,
];

const Footer = ({
  newsletterRegisterEmailField,
  onSubmitNewsletterForm,
  newsletterFormErrors = {},
  newsletterFormValues = {},
}) => (
  <Wrapper>
    <Top>
      {/* <Langs>{renderList(langs)}</Langs> */}
      {/* <ButtonBackToTop palette="primary" variant="filled" onClick={scrollToTop}>
        <IconContainer>
          <IconButton icon="chevronLeft" name="backToTop" size={40} />
        </IconContainer>
      </ButtonBackToTop> */}
    </Top>
    <Divider />
    <Bottom>
      {bottomElements({
        newsletterRegisterEmailField,
        onSubmitNewsletterForm,
        newsletterFormErrors,
        newsletterFormValues,
      }).map((el, index) => (
        <FooterElement key={index}> {el} </FooterElement>
      ))}
    </Bottom>
    <NewsletterAlertModal />
  </Wrapper>
);

export default Footer;
