import React from "react";
import styled from "styled-components";

const Wrapper = styled.header`
  height: 50%;
  flex-direction: column;
  display: flex;
`;

const CardSection = styled.section`
  display: flex;
  flex: 1;
  padding: 10px;
  flex-direction: column;
  background-color: ${props => props.theme.palette.darkerColor};
  color: ${props => props.theme.palette.quaternaryTextColor};
  font-size: 14px;
  white-space: pre-wrap;
  overflow-y: auto;
`;

const Button = styled.section`
  display: flex;
  flex: 1;
  height: 35px;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  color: ${props => props.theme.palette.tertiaryTextColor};
  background-color: ${props =>
    props.active
      ? props.theme.palette.darkerColor
      : props.theme.palette.mediumGrayColor};
`;

const ButtonsContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.darkerColor};
`;

const StudentInfoDisplay = ({ children, onClick = () => null }) => {
  const [tabActiveButton, setTabActiveButton] = React.useState("mission");

  return (
    <Wrapper>
      <ButtonsContainer>
        <Button
          onClick={() => {
            setTabActiveButton("mission");
            onClick("mission");
          }}
          active={tabActiveButton === "mission"}
        >
          Missão
        </Button>
        <Button
          onClick={() => {
            setTabActiveButton("scenario");
            onClick("scenario");
          }}
          active={tabActiveButton === "scenario"}
        >
          {"Cenário\nClínico"}
        </Button>
        <Button
          onClick={() => {
            setTabActiveButton("question");
            onClick("question");
          }}
          active={tabActiveButton === "question"}
        >
          Questões
        </Button>
      </ButtonsContainer>
      <CardSection>{children}</CardSection>
    </Wrapper>
  );
};

export default StudentInfoDisplay;
