import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

import StudentInfoDisplay from "../StudentInfoDisplay";
import InputRadio from "../../../components/InputRadio";
import MonitorDisplay from "../MonitorDisplay";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 20%;
  min-width: 200px;
  overflow-y: auto;
`;

const Question = styled.main`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const LeftPainel = ({
  studentInfo,
  monitorData,
  lungAnimation,
  lungSound,
  setLungSound,
  volumeNode,
  setVolume
}) => {
  const [studentTabContent, setStudentTabContent] = React.useState("mission");

  return (
    <Wrapper>
      <MonitorDisplay
        monitorData={monitorData}
        lungAnimation={lungAnimation}
        lungSound={lungSound}
        setLungSound={setLungSound}
        volumeNode={volumeNode}
        setVolume={setVolume}
      />
      <StudentInfoDisplay onClick={setStudentTabContent}>
        {studentTabContent === "mission" &&
          ReactHtmlParser(studentInfo.mission)}
        {studentTabContent === "scenario" &&
          ReactHtmlParser(studentInfo.clinicalScenario)}
        {studentTabContent === "question" &&
          studentInfo.question.map(question => (
            <Question>
              {question.title}
              {question.optionsList.map((option, index) => (
                <InputRadio
                  key={index}
                  title={option}
                  name="question"
                  tint="defaultColor"
                  tintText="quaternaryTextColor"
                  preset="simulator"
                />
              ))}
            </Question>
          ))}
      </StudentInfoDisplay>
    </Wrapper>
  );
};

export default LeftPainel;
