import React from "react";
import styled from "styled-components";
import { synchronize } from "./synchronization";
import StyledIcon from "../../../components/Icon";

import Graph from "../Graph";

const Icon = styled(StyledIcon)`
  width: 2em;
  height: 2em;
  margin: 0 5px;
  cursor: pointer;
  fill: ${props => props.theme.palette[props.fill]};

  &:hover,
  &:focus {
    fill: ${props => props.theme.palette.alertColor};
  }
`;

const Wrapper = styled.section``;

const GraphicsHeader = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  padding: 10px;
  color: ${props => props.theme.palette.quaternaryTextColor};
  background-color: ${props => props.theme.palette.darkerColor};
`;

const GraphicsContainer = styled.main`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  font-size: 16px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-color: ${props => props.theme.palette.secondaryColor};
  border-bottom-color: ${props => props.theme.palette.secondaryColor};
  border-top-style: solid;
  border-bottom-style: solid;
  background-color: ${props => props.theme.palette.darkerColor};
`;

const Graphics = styled.main`
  display: flex;
  height: 200px;
  width: 80%;
  overflow: hidden;
  background-color: ${props => props.theme.palette.darkerColor};
`;

const GraphicsButton = styled.main`
  display: flex;
  width: 5%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right-width: 1px;
  border-right-color: ${props => props.theme.palette.secondaryColor};
  border-right-style: solid;
`;

const GraphicInfo = styled.main`
  display: flex;
  width: 15%;
  flex-direction: column;
  padding: 5px;
  color: ${props => props.theme.palette.quaternaryTextColor};
  border-left-width: 3px;
  border-left-color: ${props => props.theme.palette.secondaryColor};
  border-left-style: solid;
`;

const GraphicsValue = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
  font-size: 28px;
  font-weight: bold;
  color: ${props => props.theme.palette.alertColor};
`;

const GraphContainer = ({
  tints,
  graphConfig,
  data,
  stage,
  scale,
  setScale
}) => {
  const graphVolume = React.useRef(null);
  const graphFlow = React.useRef(null);
  const graphPressure = React.useRef(null);

  const [selectValueVol, setSelectValueVol] = React.useState(0);
  const [selectValueFlow, setSelectValueFlow] = React.useState(0);
  const [selectValuePress, setSelectValuePress] = React.useState(0);

  const [update, setUpdate] = React.useState(false);
  const [timeScale, setTimeScale] = React.useState(1);

  const times = [700, 1500, 3000];

  const updateScale = async (scaleConfig, index, value) => {
    let list = scale;
    if (list[index] === 0 && value < 0) {
      list[index] = scaleConfig.length - 1;
    } else if (list[index] >= scaleConfig.length - 1 && value > 0) {
      list[index] = 0;
    } else {
      list[index] += value;
    }
    setScale(list);
    setUpdate(!update);
  };

  let sync = null;
  const syncGraph = () => {
    sync = synchronize(
      [graphVolume.current, graphFlow.current, graphPressure.current],
      {
        selection: true,
        range: false
      }
    );
  };
  React.useEffect(() => {
    if (stage === "PAUSE") syncGraph();
    else {
      setSelectValueVol(0);
      setSelectValueFlow(0);
      setSelectValuePress(0);
    }
  }, [stage]);

  return (
    <Wrapper>
      <GraphicsHeader>
        Gráficos
        <div>
          <Icon
            icon="chevronLeft"
            fill={"initial"}
            onClick={() => timeScale > 0 && setTimeScale(timeScale - 1)}
          />
          {`${times[timeScale] / 100} Segundos`}
          <Icon
            icon="chevronRight"
            fill={"initial"}
            onClick={() => timeScale < 2 && setTimeScale(timeScale + 1)}
          />
        </div>
        <div />
      </GraphicsHeader>
      <GraphicsContainer>
        <GraphicsButton>
          <Icon
            icon="chevronUp"
            fill={"initial"}
            onClick={() =>
              updateScale(graphConfig.volume.scale.ranges, "volume", 1)
            }
          />
          <Icon
            icon="chevronDown"
            fill={"initial"}
            onClick={() =>
              updateScale(graphConfig.volume.scale.ranges, "volume", -1)
            }
          />
        </GraphicsButton>
        <Graphics>
          <Graph
            graph={graphVolume}
            tint={tints[0]}
            message={"volume"}
            data={data[0]}
            scale={graphConfig["volume"].scale.ranges[scale["volume"]]}
            timeScale={times[timeScale]}
            setSelectValue={setSelectValueVol}
            isPause={stage !== "PLAY"}
          />
        </Graphics>
        <GraphicInfo>
          {graphConfig.volume.series.Volume.name} (ml)
          <GraphicsValue>
            {stage === "PAUSE" ? (
              <span style={{ fontSize: "28px" }}>
                {selectValueVol.toFixed(0)}{" "}
                <span style={{ fontSize: "16px" }}>ml</span>{" "}
              </span>
            ) : (
              "--"
            )}
          </GraphicsValue>
        </GraphicInfo>
      </GraphicsContainer>
      <GraphicsContainer>
        <GraphicsButton>
          <Icon
            icon="chevronUp"
            fill={"initial"}
            onClick={() =>
              updateScale(graphConfig.flow.scale.ranges, "flow", 1)
            }
          />
          <Icon
            icon="chevronDown"
            fill={"initial"}
            onClick={() =>
              updateScale(graphConfig.flow.scale.ranges, "flow", -1)
            }
          />
        </GraphicsButton>
        <Graphics>
          <Graph
            graph={graphFlow}
            tint={tints[1]}
            message={"flow"}
            data={data[1]}
            scale={graphConfig["flow"].scale.ranges[scale["flow"]]}
            timeScale={times[timeScale]}
            setSelectValue={setSelectValueFlow}
            isPause={stage !== "PLAY"}
          />
        </Graphics>
        <GraphicInfo>
          {graphConfig.flow.series.Fluxo.name} (l/min)
          <GraphicsValue>
            {stage === "PAUSE" ? (
              <span style={{ fontSize: "28px" }}>
                {selectValueFlow.toFixed(0)}{" "}
                <span style={{ fontSize: "16px" }}>l/min</span>{" "}
              </span>
            ) : (
              "--"
            )}
          </GraphicsValue>
        </GraphicInfo>
      </GraphicsContainer>
      <GraphicsContainer>
        <GraphicsButton>
          <Icon
            icon="chevronUp"
            fill={"initial"}
            onClick={() =>
              updateScale(graphConfig.pressure.scale.ranges, "pressure", 1)
            }
          />
          <Icon
            icon="chevronDown"
            fill={"initial"}
            onClick={() =>
              updateScale(graphConfig.pressure.scale.ranges, "pressure", -1)
            }
          />
        </GraphicsButton>
        <Graphics>
          <Graph
            graph={graphPressure}
            tint={tints[2]}
            message={"pressure"}
            data={data[2]}
            scale={graphConfig["pressure"].scale.ranges[scale["pressure"]]}
            timeScale={times[timeScale]}
            setSelectValue={setSelectValuePress}
            isPause={stage !== "PLAY"}
          />
        </Graphics>
        <GraphicInfo>
          <span>
            {graphConfig.pressure.series.Pva.name} (cmH<sub>2</sub>0)
          </span>
          <GraphicsValue>
            {stage === "PAUSE" ? (
              <span style={{ fontSize: "28px" }}>
                {selectValuePress.toFixed(0)}{" "}
                <span style={{ fontSize: "16px" }}>
                  cmH<sub>2</sub>0
                </span>{" "}
              </span>
            ) : (
              "--"
            )}
          </GraphicsValue>
        </GraphicInfo>
      </GraphicsContainer>
    </Wrapper>
  );
};

export default GraphContainer;
