import React from "react";

import Button from "../Button";
import BuilderWizardStep from "../BuilderWizardStep";

const BuilderWizardStepFive = ({ pos, startEditStep, completeStep }) => {
  const onStartEditStep = () => {
    if (startEditStep) {
      startEditStep(pos);
    }
  };

  const onCompleteStep = () => {
    if (completeStep) {
      completeStep(pos);
    }
  };

  return (
    <BuilderWizardStep>
      <p>STEP 5</p>
      <Button onClick={onStartEditStep}>Start step</Button>
      <Button onClick={onCompleteStep}>Complete step</Button>
    </BuilderWizardStep>
  );
};

export default BuilderWizardStepFive;
