import React from "react";
import styled from "styled-components";

import Card from "../Card";
import ModalBody from "../ModalBody";

const ModalBackdrop = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
`;

const Wrapper = styled(Card).attrs((props) => ({
  ...props,
  padding: false,
}))`
  min-width: 450px;
  width: 450px;
  max-width: 80%;
  border-radius: 5px;
  box-shadow: 0 1px 3px ${(props) => props.theme.palette.secondaryColor};
`;

const Modal = ({ header, body, footer, children }) => {
  return (
    <ModalBackdrop>
      <Wrapper>
        {header}
        {body || <ModalBody>{children}</ModalBody>}
        {footer}
      </Wrapper>
    </ModalBackdrop>
  );
};

export default Modal;
