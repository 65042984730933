import React from "react";

import ContentHeader from "../../components/ContentHeader";
import ContentHeaderRow from "../../components/ContentHeaderRow";
import ContentHeaderColumn from "../../components/ContentHeaderColumn";
import Breadcrumb from "../../components/Breadcrumb";

const pathBreadcrumb = [
  { description: "Meus Exercícios", to: "myexercises" },
  { description: "Progresso", to: "progress" },
];

const HeaderProgress = () => {
  return (
    <ContentHeader>
      <ContentHeaderRow>
        <ContentHeaderColumn>
          <Breadcrumb path={pathBreadcrumb} />
        </ContentHeaderColumn>
      </ContentHeaderRow>
    </ContentHeader>
  );
};

export default HeaderProgress;
