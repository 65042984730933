import { useEffect } from "react";

import useFetchApi from "./useFetchApi";

import { useAuthContext } from "../contexts/Auth/";
import { useUserDisconnectedAlertModalContext } from "../contexts/UserDisconnectedAlertModal";

const useAuthFetchApi = (uri, method) => {
  const { user } = useAuthContext();
  const { setIsOpen } = useUserDisconnectedAlertModalContext();

  const { startFetch: _startFetch, error, ...rest } = useFetchApi(uri, method);

  const startFetch = (body, overrideHeaders, options) => {
    _startFetch(body, user.token, overrideHeaders, options);
  };

  useEffect(() => {
    if (error?.statusCode === 401) {
      setIsOpen(true);
    }
  }, [error, setIsOpen]);

  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
    // eslint-disable-next-line
  }, []);

  return {
    startFetch,
    error,
    ...rest,
  };
};

export default useAuthFetchApi;
