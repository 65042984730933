import React, { useEffect, useState } from "react";
import axios from "axios";

import Carousel from "../../components/Carousel";
// import Banner from "../../components/Banner";
import Grid from "../../components/Grid";
import Row from "../../components/GridRow";
import Column from "../../components/GridColumn";

import ViewContainer from "../ViewContainer";

// import useAuthFetchApi from "../../hooks/useAuthFetchApi";

const { REACT_APP_API_BASE_URI } = process.env;

const HomeContainer = () => {
  // const { data, startFetch } = useAuthFetchApi("/exercises");
  const [carousel, setCarousel] = useState(null);
  // const [categories, setCategories] = React.useState([]);
  // const [exercisesByCategory, setExercisesByCategory] = React.useState([]);
  // const [uncategorizedExercises, setUncategorizedExercises] = React.useState(
  //   []
  // );

  useEffect(() => {
    axios.get(`${REACT_APP_API_BASE_URI}/exercises`).then(response => {
      setCarousel(
        <Carousel title="Todos" cardsToShow={4} exercises={response.data} />
      );
    }, []);

    //   <Carousel
    //   title="Todos"
    //   statements={`${exercises.length}/${totalExercises}`}
    //   cardsToShow={4}
    //   exercises={exercises}
    // />

    // fetchExercises();
    // eslint-disable-next-line
  }, []);

  // React.useEffect(() => {

  //   // if (data) {
  //   //   setExercises(data.items);
  //   // }
  // }, [data, setExercises]);

  // React.useEffect(() => {
  //   const extractCategories = (exercises = []) =>
  //     exercises.reduce((acc, curr) => [...acc, ...curr.categories], []);

  //   const removeRepetead = (categories = []) =>
  //     categories.filter((category, i, arr) => {
  //       const index = arr.findIndex((el) => el.id === category.id);
  //       if (index === i) {
  //         return category;
  //       }
  //     });
  //   setCategories(removeRepetead(extractCategories(exercises)));
  // }, [exercises]);

  // React.useEffect(() => {
  //   // const filterExercisesByCategory = (category) =>
  //   //   exercises.filter((exercise) => {
  //   //     const isInThisCategory = exercise.categories.some(
  //   //       (cat) => cat.id === category.id
  //   //     );
  //   //     if (isInThisCategory) {
  //   //       return exercise;
  //   //     }
  //   //   });

  //   setExercisesByCategory(
  //     categories.map((category) => {
  //       return {
  //         ...category,
  //         exercises: filterExercisesByCategory(category),
  //       };
  //     })
  //   );
  // }, [exercises, categories, setExercisesByCategory]);

  // React.useEffect(() => {
  //   setUncategorizedExercises(
  //     exercises.filter((exercise) => !exercise?.categories.length)
  //   );
  // }, [exercises, setUncategorizedExercises]);

  return (
    <ViewContainer>
      {/* <Grid>
        <Row>
          <Column>
            <Banner />
          </Column>
        </Row>
      </Grid> */}
      <Grid>
        <Row>
          <Column>
            {carousel && carousel}
            {/* <Carousel
              title="Todos"
              statements={`${exercises.length}/${totalExercises}`}
              cardsToShow={4}
              exercises={exercises}
            /> */}
          </Column>
        </Row>
        {/* {exercisesByCategory.map((category) => {
          return (
            <Row key={category.id}>
              <Column>
                <Carousel
                  title={category.name}
                  statements={`${category.exercises.length}/${totalExercises}`}
                  cardsToShow={4}
                  exercises={category.exercises}
                />
              </Column>
            </Row>
          );
        })}
        {!!uncategorizedExercises.length && (
          <Row>
            <Column>
              <Carousel
                title="Não categorizados"
                statements={`${uncategorizedExercises.length}/${totalExercises}`}
                cardsToShow={4}
                exercises={uncategorizedExercises}
              />
            </Column>
          </Row>
        )} */}
      </Grid>
    </ViewContainer>
  );
};

export default HomeContainer;
