import React from "react";
import styled from "styled-components";

import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ReactHtmlParser from "react-html-parser";

import exhaleSound from "../../../assets/audios/expire-2.mp3";
import inhaleSound from "../../../assets/audios/inspire-2.mp3";

import AlertModal from "../../../components/AlertModal";
import Button from "../../../components/Button";
import ButtonWithIcon from "../../../components/ButtonWithIcon";
import SimulatorBottomDisplay from "../SimulatorBottomDisplay";
import RightPainel from "../RightPainel";
import LeftPainel from "../LeftPainel";
import GraphContainer from "../GraphContainer";

// Modals
import { RequestExams } from "../modals/examModals";

const Wrapper = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const Main = styled.main`
  display: flex;
  flex: 1;
  width: 65%;
  flex-direction: column;
  padding: 10px;
  min-height: 570px;
`;

const MainHeader = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  margin-right: 10px;
`;

const ButtonSection = styled.section`
  display: flex;
  flex-direction: row;
`;

const ButtonText = styled.h1`
  margin: 0;
  line-height: 10px;
  font-size: 10px;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const AudioContext = new window.AudioContext();
let volumeNode = AudioContext.createGain();
let nextNote = 0;
volumeNode.gain.value = 0.7;

const SimulatorView = ({
  simulatorData = null,
  setSimulatorData,
  finishSimulation,
  isBuilder = false
}) => {
  const [stage, setStage] = React.useState(isBuilder ? "PLAY" : "START");
  const [soundOn, setSoundOn] = React.useState(true);
  const [lungSound, setLungSound] = React.useState(null);
  const [requestExam, setRequestExam] = React.useState(false);
  const [gasometria, setGasometria] = React.useState({});
  const [gasometrias, setGasometrias] = React.useState([]);
  const [monitorData, setMonitorData] = React.useState({
    SpO2: { value: 0, rating: "ok" },
    heartRate: { value: 0, rating: "ok" }
  });
  const [requestGas, setRequestGas] = React.useState(false);
  const [scale, setScale] = React.useState({ volume: 1, flow: 1, pressure: 1 });

  const [updateExams, setUpdateExams] = React.useState(null);

  const Socket = React.useRef();
  const volume = React.useRef([]);
  const flow = React.useRef([]);
  const pressure = React.useRef([]);
  const lungAnimation = React.useRef(null);

  const history = useHistory();
  const uuid = uuidv4();
  const exhale = new Audio(exhaleSound);
  const inhale = new Audio(inhaleSound);

  const [currentTimer, setCurrentTimer] = React.useState(
    simulatorData.duration * 60
  );

  const playBeep = (nextNoteTime, heartValue, maxCount = 6) => {
    let noteTime = nextNoteTime;
    const next = 60 / heartValue;
    let count = 0;
    while (count < maxCount + 1) {
      const oscillatorNode = AudioContext.createOscillator();

      oscillatorNode.connect(volumeNode);
      oscillatorNode.frequency.value = 700;
      oscillatorNode.type = "triangle";

      oscillatorNode.start(noteTime);
      oscillatorNode.stop(noteTime + 0.1);
      noteTime += next;
      count++;
      nextNote = noteTime;
    }
  };

  React.useEffect(() => {
    let interval;
    if (currentTimer > 0 && !isBuilder) {
      interval = setTimeout(
        () =>
          stage !== "START" &&
          currentTimer > 0 &&
          setCurrentTimer(currentTimer - 1),
        1000
      );
    } else {
    }
    return () => clearInterval(interval);
  }, [currentTimer, stage, isBuilder]);

  const updateSimulatorParams = (key, value, mode = null) => {
    const newSimulator = simulatorData;
    if (key) {
      if (key === "syncTrigger") newSimulator.ventilator.syncTrigger = value;
      else newSimulator.ventilator.parameters[key] = value;
    }

    if (mode) newSimulator.ventilator.ventilationMode = mode;

    Socket.current.send(
      JSON.stringify({
        data: {
          mode: newSimulator.ventilator.ventilationMode,
          id: uuid,
          patient: newSimulator.patient,
          syncTrigger: newSimulator.ventilator.syncTrigger,
          ventilator: newSimulator.ventilator.parameters
        },
        message: "update"
      })
    );
    setSimulatorData({ ...newSimulator });
  };
  const stageControl = async () => {
    if (stage === "START") {
      Socket.current.send(
        JSON.stringify({
          data: {
            mode: simulatorData.ventilator.ventilationMode,
            application: "Xlung",
            id: uuid
          },
          message: "start"
        })
      );
      volumeNode.connect(AudioContext.destination);
      return setStage("PLAY");
    }
    if (stage === "PLAY") {
      Socket.current.send(
        JSON.stringify({
          message: "pause"
        })
      );
      volumeNode.gain.value = 0;
      return setStage("PAUSE");
    }
    if (stage === "PAUSE") {
      Socket.current.send(
        JSON.stringify({
          message: "resume"
        })
      );
      volumeNode.gain.value = 0.7;
      return setStage("PLAY");
    }
  };

  const getStage = () => {
    if (stage === "START") return "Iniciar";
    if (stage === "PLAY") return "Pause";
    if (stage === "PAUSE") return "Continue";
  };

  //Web Socket

  const onOpen = evt => {
    let i = 0;
    while (i < 60) {
      volume.current.push([i, 0]);
      flow.current.push([i, 0]);
      pressure.current.push([i, 0]);
      i += 0.01;
    }

    const config = {
      mode: simulatorData.ventilator.ventilatoryMode,
      patient: simulatorData.patient,
      ventilator: simulatorData.ventilator.parameters,
      syncTrigger: simulatorData.ventilator.syncTrigger
    };
    Socket.current.send(JSON.stringify(config));

    if (isBuilder)
      Socket.current.send(
        JSON.stringify({
          data: {
            mode: simulatorData.ventilator.ventilationMode,
            application: "Xlung",
            id: uuid
          },
          message: "start"
        })
      );
  };

  const onClose = evt => {
    // const message = JSON.parse(evt.data);
    console.log(evt);
    Socket.current.close();

    console.log("close");
  };

  const handleNextLung = () => {
    if (lungAnimation.current) {
      const newFrame = lungAnimation.current.getInfo("frame") - 1;
      if (newFrame > 0) {
        lungAnimation.current.setDirection("forward");
        lungAnimation.current.goToAndPlay(newFrame);
      } else {
        lungAnimation.current.setDirection("forward");
        lungAnimation.current.goToAndPlay(1);
      }
    }
  };

  const handlePrevLung = () => {
    if (lungAnimation.current) {
      const newFrame = lungAnimation.current.getInfo("frame") - 1;
      if (newFrame > 20) {
        lungAnimation.current.setDirection("rewind");
        lungAnimation.current.goToAndPlay(20);
      } else {
        lungAnimation.current.setDirection("rewind");
        lungAnimation.current.goToAndPlay(newFrame);
      }
    }
  };

  React.useEffect(() => {
    if (lungSound && soundOn) lungSound.play();
  }, [lungSound, soundOn]);
  let preTime = 0;
  const onMessage = evt => {
    const message = JSON.parse(evt.data);
    if (message.name === "gasometric-update") {
      setMonitorData({
        SpO2: message.data.sao2,
        heartRate: message.data.heartRate
      });
      setGasometria(message.data);

      const note =
        nextNote < AudioContext.currentTime + 0.1
          ? AudioContext.currentTime
          : nextNote;
      playBeep(
        note,
        message.data.heartRate.value,
        AudioContext.currentTime - preTime
      );
      preTime = AudioContext.currentTime;
    }

    if (message.name === "start-expiration") setLungSound(exhale);
    if (message.name === "start-inspiration") setLungSound(inhale);

    if (message.name === "inspire") {
      handleNextLung();
    }
    if (message.name === "expire") {
      handlePrevLung();
    }

    if (message.name === "ping") {
      Socket.current.send(JSON.stringify({ message: "pong" }));
    }
    if (message.name === "plot") {
      const currentGraphs = message.data;
      const timeStamp = message.data.currentTime;

      volume.current.push([
        timeStamp,
        currentGraphs.upperVolumeSafeZone * currentGraphs.volume
      ]);
      flow.current.push([timeStamp, currentGraphs.flow]);
      pressure.current.push([timeStamp, currentGraphs.pressure.va]);

      volume.current.shift();
      flow.current.shift();
      pressure.current.shift();
    }

    if (message.name === "simulation-update") {
      console.log(message.data);
      setUpdateExams(message.data);
    }
  };

  React.useEffect(() => {
    if (requestGas) {
      const realGas = {
        pH: {
          ...gasometria.ph,
          unit: "-",
          value: gasometria.ph.value.toFixed(2)
        },
        paco2: {
          ...gasometria.paco2,
          unit: "mmHg",
          value: gasometria.paco2.value.toFixed(0)
        },
        HCO3: {
          ...gasometria.hco3,
          unit: "mEq/l",
          value: gasometria.hco3.value.toFixed(1)
        },
        BE: {
          ...gasometria.be,
          unit: "-",
          value: gasometria.be.value.toFixed(1)
        },
        PaO2: {
          ...gasometria.PaO2,
          unit: "mmHg",
          value: gasometria.PaO2.value.toFixed(1)
        },
        SaO2: {
          ...gasometria.sao2,
          unit: "%",
          value: gasometria.sao2.value.toFixed(1)
        }
      };

      setGasometrias([...gasometrias, { ...realGas, date: currentTimer }]);
      setRequestGas(false);
      setGasometria(null);
    }
  }, [requestGas]);

  React.useEffect(() => {
    const url = "wss://s2.xlung.net/xlung/ws";

    Socket.current = new WebSocket(url);
    Socket.current.onopen = onOpen;
    Socket.current.onmessage = onMessage;
    Socket.current.onclose = onClose;

    return () => Socket.current.close();
  }, []);

  const submitExercise = () => {
    const requestObj = {
      finished: true,
      time_remain: currentTimer / 60,
      exercise_uuid: uuid,
      user_id: 1,
      simulation: {
        ventilator: simulatorData.ventilator,
        gasometria: gasometria
      }
    };
    finishSimulation(requestObj);
    volumeNode.gain.value = 0;
    Socket.current.close();
  };

  return (
    <Wrapper height={window.innerHeight} width={window.innerWidth}>
      <MainWrapper>
        {!isBuilder && (
          <AlertModal
            title={ReactHtmlParser(simulatorData.exerciseName)}
            subtitle={ReactHtmlParser(simulatorData.description)}
            isOpen={stage === "START"}
            onConfirmText="Iniciar"
            onConfirm={() => stageControl()}
            onCancel={() => history.replace("/")}
            type="info"
          />
        )}
        <LeftPainel
          studentInfo={{
            mission: simulatorData.tasks,
            question: simulatorData.extraQuestions,
            clinicalScenario: simulatorData.description
          }}
          monitorData={monitorData}
          lungAnimation={lungAnimation}
          lungSound={soundOn}
          setLungSound={setSoundOn}
          volumeNode={volumeNode.gain.value}
          setVolume={() => {
            volumeNode.gain.value = volumeNode.gain.value === 0 ? 0.7 : 0;
          }}
        />
        <Main>
          <MainHeader>
            <ButtonSection>
              <ButtonContainer>
                <ButtonWithIcon
                  icon={stage === "PLAY" ? "pause" : "play"}
                  position="right"
                  palette="darker"
                  variant="filled"
                  size="tiny"
                  onClick={() => stageControl()}
                >
                  <ButtonText>{getStage()}</ButtonText>
                </ButtonWithIcon>
              </ButtonContainer>
              {stage !== "START" && (
                <>
                  <ButtonContainer
                    onClick={() =>
                      gasometria && gasometrias.length < 3
                        ? setRequestGas(true)
                        : null
                    }
                  >
                    <ButtonWithIcon palette="darker" variant="filled">
                      <ButtonText>
                        Coletar Gasometria {gasometrias.length} de 3
                      </ButtonText>
                    </ButtonWithIcon>
                  </ButtonContainer>
                  {/* <ButtonContainer>
                    <Button
                      palette="darker"
                      variant="filled"
                      size="tiny"
                      onClick={() => setRequestExam(true)}
                    >
                      <ButtonText>Solicitar Exames</ButtonText>
                    </Button>
                  </ButtonContainer> */}
                </>
              )}
            </ButtonSection>
            <Button onClick={submitExercise}>
              {isBuilder ? "Salvar" : "Finalizar"}
            </Button>
          </MainHeader>
          <GraphContainer
            tints={[
              simulatorData.charts.curves.volume.series.Volume.color,
              simulatorData.charts.curves.flow.series.Fluxo.color,
              simulatorData.charts.curves.pressure.series.Pva.color
            ]}
            graphConfig={simulatorData.charts.curves}
            data={[volume.current, flow.current, pressure.current]}
            stage={stage}
            scale={scale}
            setScale={setScale}
          />
          {requestExam && <RequestExams close={() => setRequestExam(false)} />}
        </Main>
        <RightPainel
          timer={simulatorData.duration * 60}
          currentTimer={currentTimer}
          gasometria={gasometrias}
          updateExams={updateExams}
        />
      </MainWrapper>
      <ContentWrapper>
        <SimulatorBottomDisplay
          parameters={simulatorData.ventilator.parameters}
          syncTrigger={simulatorData.ventilator.syncTrigger}
          setParameters={(key, value, mode = null) =>
            updateSimulatorParams(key, value, mode)
          }
          onSubmit={
            setSimulatorData
              ? () => setSimulatorData(simulatorData)
              : () => null
          }
          builderMode={isBuilder}
        />
      </ContentWrapper>
    </Wrapper>
  );
};

export default SimulatorView;
