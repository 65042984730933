import React, {useState, useEffect} from "react";
import styled from "styled-components";

import StyledButton from "../Button";
// import Searchbar from "../Searchbar";
import ButtonLink from "../ButtonLink";
import StyledIcon from "../Icon";

import defaultAvatar from "../../assets/images/avatar.png";

import { DEFAULT_PADDING } from "../../settings";

import { useHistory } from "react-router-dom";


const Wrapper = styled.header`
  min-height: 80px;
  align-items: center;
  display: flex;
  padding: 0 ${DEFAULT_PADDING};
`;

const HeaderSection = styled.section`
  display: flex;
  align-items: center;
  margin: 0 6px;
`;

const Button = styled(StyledButton)`
  text-transform: none;
`;

const Icon = styled(StyledIcon)`
  margin: 0 5px;
  fill: ${(props) => props.theme.palette.primaryColor};
  size: 70px;
`;

const ProfilePicture = styled.img`
  border-radius: 100%;
  margin-right: 5px;
  height: 35px;
  width: 35px;
`;

const sizeIcons = 25;

const Header = ({ user = {},  logout }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  let history = useHistory();

  useEffect(() => {
    setIsAuthenticated(localStorage.getItem('xt-user') !== null)
  }, [])

  const _logout = () => {
    localStorage.removeItem('xt-user');
    setIsAuthenticated(false);
    // history.pushState(null, '/');
  }

  return(<Wrapper>
    {/* <Searchbar />
    <HeaderSection>
      <ButtonLink active>Português (Brasil)</ButtonLink>
      <ButtonLink>English (US)</ButtonLink>
    </HeaderSection> */}
    {!user.isPremium && (
      <Button palette="primary" variant="outlined">
        Quero ser Premium
      </Button>
    )}
    {!isAuthenticated ? (
      <HeaderSection>
        <ButtonLink active to="/login">
          Entrar
        </ButtonLink>
        <ButtonLink active to="https://xlung.net/register">
          Cadastrar
        </ButtonLink>
      </HeaderSection>
    ) : (
      <>
        <HeaderSection>
          <ButtonLink active to="/account">
            <Icon icon="school" size={sizeIcons} />
            <ProfilePicture
              src={user.avatar || defaultAvatar}
              alt="UserProfilePicture"
            />
            {user.name || "Anônimo"}
          </ButtonLink>
        </HeaderSection>
        <HeaderSection>
          <ButtonLink active onClick={_logout}>
            Sair <Icon icon="exit" size={sizeIcons} />
          </ButtonLink>
        </HeaderSection>
      </>
    )}
  </Wrapper>)
};

export default Header;
