import { css } from "styled-components";
import { darken } from "polished";

export const base = css`
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  /* text-transform: uppercase; */
  padding: 0.9em 1.2em;
  font-weight: 400;
  white-space: nowrap;
  align-self: center;
  line-height: 1.2;
`;

export const disabled = css`
  ${(props) =>
    props.disabled &&
    `
  opacity: 0.3;
  cursor: not-allowed;
`};
`;

export const size = css`
  ${(props) =>
    props.size === "tiny" &&
    `
font-size:0.7em;
`};
  ${(props) =>
    props.size === "small" &&
    `
  font-size:0.8em;
`};
  ${(props) =>
    props.size === "medium" &&
    `
  font-size: 1em;
`};
  ${(props) =>
    props.size === "large" &&
    `
  font-size: 1.2em;
`};
`;

export const initialFilled = css`
  color: ${(props) => props.theme.palette.primaryTextColor};
  background-color: ${(props) => props.theme.palette.initialColor};
  border-color: ${(props) => darken("0.05", props.theme.palette.initialColor)};
  ${(props) =>
    !props.disabled &&
    `
    &:hover, &:focus {
      background-color: ${darken("0.05", props.theme.palette.initialColor)};
    }
  `}
`;

export const initialOutlined = css`
  color: ${(props) => props.theme.palette.primaryTextColor};
  background-color: transparent;
  border-color: ${(props) =>
    darken("0.05", props.theme.palette.primaryTextColor)};
  ${(props) =>
    !props.disabled &&
    `
  &:hover, &:focus {
    background-color: ${props.theme.palette.initialColor};
  }
  `}
`;

export const initial = css`
  ${(props) => props.variant === "filled" && initialFilled};
  ${(props) => props.variant === "outlined" && initialOutlined};
`;

export const primaryFilled = css`
  color: ${(props) => props.theme.palette.quaternaryTextColor};
  background-color: ${(props) => props.theme.palette.primaryColor};
  border-color: ${(props) => darken("0.05", props.theme.palette.primaryColor)};
  ${(props) =>
    !props.disabled &&
    `
    &:hover, &:focus {
      background-color: ${darken("0.05", props.theme.palette.primaryColor)};
    }
  `};
`;

export const primaryOutlined = css`
  color: ${(props) => props.theme.palette.primaryColor};
  background-color: transparent;
  border-color: ${(props) => props.theme.palette.primaryColor};
  ${(props) =>
    !props.disabled &&
    `
  &:hover, &:focus {
    color: ${props.theme.palette.quaternaryTextColor};
    background-color: ${props.theme.palette.primaryColor};
  }
  `}
`;

export const primary = css`
  ${(props) => props.variant === "filled" && primaryFilled};
  ${(props) => props.variant === "outlined" && primaryOutlined};
`;

export const secondaryFilled = css`
  color: ${(props) => props.theme.palette.quaternaryTextColor};
  background-color: ${(props) => props.theme.palette.secondaryColor};
  border-color: ${(props) =>
    darken("0.05", props.theme.palette.secondaryColor)};
  ${(props) =>
    !props.disabled &&
    `
    &:hover, &:focus {
      background-color: ${darken("0.05", props.theme.palette.secondaryColor)};
    }
  `}
`;

export const secondaryOutlined = css`
  color: ${(props) => props.theme.palette.secondaryColor};
  background-color: transparent;
  border-color: ${(props) => props.theme.palette.secondaryColor};
  ${(props) =>
    !props.disabled &&
    `
  &:hover, &:focus {
    color: ${props.theme.palette.quaternaryTextColor};
    background-color: ${props.theme.palette.secondaryColor};
  }
  `}
`;

export const secondary = css`
  ${(props) => props.variant === "filled" && secondaryFilled};
  ${(props) => props.variant === "outlined" && secondaryOutlined};
`;

export const darkerGrayFilled = css`
  color: ${(props) => props.theme.palette.quaternaryTextColor};
  background-color: ${(props) => props.theme.palette.darkerGrayColor};
  border-color: ${(props) =>
    darken("0.05", props.theme.palette.darkerGrayColor)};
  ${(props) =>
    !props.disabled &&
    `
    &:hover, &:focus {
      background-color: ${darken("0.05", props.theme.palette.darkerGrayColor)};
    }
  `}
`;

export const darkerGrayOutlined = css`
  color: ${(props) => props.theme.palette.darkerGrayColor};
  background-color: transparent;
  border-color: ${(props) => props.theme.palette.darkerGrayColor};
  ${(props) =>
    !props.disabled &&
    `
  &:hover, &:focus {
    color: ${props.theme.palette.quaternaryTextColor};
    background-color: ${props.theme.palette.darkerGrayColor};
  }
  `}
`;

export const darkerGray = css`
  ${(props) => props.variant === "filled" && darkerGrayFilled};
  ${(props) => props.variant === "outlined" && darkerGrayOutlined};
`;

export const darkerFilled = css`
  color: ${(props) => props.theme.palette.quaternaryTextColor};
  background-color: ${(props) => props.theme.palette.darkerColor};
  border-color: ${(props) => darken("0.05", props.theme.palette.darkerColor)};
  ${(props) =>
    !props.disabled &&
    `
    &:hover, &:focus {
      background-color: ${darken("0.05", props.theme.palette.darkerColor)};
    }
  `}
`;

export const darkerOutlined = css`
  color: ${(props) => props.theme.palette.darkerColor};
  background-color: transparent;
  border-color: ${(props) => props.theme.palette.darkerColor};
  ${(props) =>
    !props.disabled &&
    `
  &:hover, &:focus {
    color: ${props.theme.palette.quaternaryTextColor};
    background-color: ${props.theme.palette.darkerColor};
  }
  `}
`;

export const darker = css`
  ${(props) => props.variant === "filled" && darkerFilled};
  ${(props) => props.variant === "outlined" && darkerOutlined};
`;

export const infoFilled = css`
  color: ${(props) => props.theme.palette.quaternaryTextColor};
  background-color: ${(props) => props.theme.palette.infoColor};
  border-color: ${(props) => darken("0.05", props.theme.palette.infoColor)};
  ${(props) =>
    !props.disabled &&
    `
    &:hover, &:focus {
      background-color: ${darken("0.05", props.theme.palette.infoColor)};
    }
  `}
`;

export const infoOutlined = css`
  color: ${(props) => props.theme.palette.infoColor};
  background-color: transparent;
  border-color: ${(props) => props.theme.palette.infoColor};
  ${(props) =>
    !props.disabled &&
    `
  &:hover, &:focus {
    color: ${props.theme.palette.quaternaryTextColor};
    background-color: ${props.theme.palette.infoColor};
  }
  `}
`;

export const info = css`
  ${(props) => props.variant === "filled" && infoFilled};
  ${(props) => props.variant === "outlined" && infoOutlined};
`;

export const successFilled = css`
  color: ${(props) => props.theme.palette.quaternaryTextColor};
  background-color: ${(props) => props.theme.palette.successColor};
  border-color: ${(props) => darken("0.05", props.theme.palette.successColor)};
`;

export const success = css`
  ${(props) => props.variant === "filled" && successFilled};
`;

export const alertFilled = css`
  color: ${(props) => props.theme.palette.quaternaryTextColor};
  background-color: ${(props) => props.theme.palette.alertColor};
  border-color: ${(props) => darken("0.05", props.theme.palette.alertColor)};
  ${(props) =>
    !props.disabled &&
    `
    &:hover, &:focus {
      background-color: ${darken("0.05", props.theme.palette.alertColor)};
    }
  `}
`;

export const alertOutlined = css`
  color: ${(props) => props.theme.palette.initialColor};
  background-color: transparent;
  border-color: ${(props) => props.theme.palette.alertColor};
  ${(props) =>
    !props.disabled &&
    `
  &:hover, &:focus {
    color: ${props.theme.palette.initialColor};
    background-color: ${darken("0.02", props.theme.palette.quaternaryTextColor)};
  }
  `}
`;

export const alert = css`
  ${(props) => props.variant === "filled" && alertFilled};
  ${(props) => props.variant === "outlined" && alertOutlined};
`;
