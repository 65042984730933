export const values = {
  categories: [],
  charts: {
    autoScale: false,
    curves: {
      volume: {
        scale: {
          minIntervals: 4,
          interval: 500,
          rangeIndex: 3,
          ranges: [
            { min: 0, max: 250, interval: 60 },
            { min: 0, max: 500, interval: 250 },
            { min: 0, max: 1000 },
            { min: 0, max: 2000 }
          ]
        },
        series: {
          "6ml/kg": {
            index: 1,
            name: "6ml/kg",
            visible: false,
            color: "#DAD40E"
          },
          "8ml/kg": {
            index: 2,
            name: "8ml/kg",
            visible: false,
            color: "#DAD40E"
          },
          "ARDS6ml/kg": {
            index: 3,
            name: "ARDS6ml/kg",
            visible: false,
            color: "#DAD40E"
          },
          "ARDS8ml/kg": {
            index: 4,
            name: "ARDS8ml/kg",
            visible: false,
            color: "#DAD40E"
          },
          Volume: { index: 0, name: "Volume", visible: true, color: "#DAD40E" }
        },
        visible: true
      },
      flow: {
        scale: {
          interval: 60,
          rangeIndex: 3,
          ranges: [
            { min: -50, max: 50, interval: 25 },
            { min: -100, max: 100, interval: 50 },
            { min: -120, max: 120 },
            { min: -150, max: 150, interval: 75 },
            { min: -300, max: 300, interval: 150 }
          ]
        },
        series: {
          Fluxo: { index: 0, name: "Fluxo", visible: true, color: "#38BA0A" },
          Zero: { index: 1, name: "Zero", visible: true, color: "grey" }
        },
        visible: true
      },
      pressure: {
        scale: {
          interval: 10,
          rangeIndex: 3,
          ranges: [
            { min: 0, max: 15, interval: 5 },
            { min: 0, max: 30, interval: 10 },
            { min: 0, max: 45, interval: 15 },
            { min: 0, max: 60, interval: 20 },
            { min: -10, max: 15, interval: 5 },
            { min: -10, max: 30, interval: 10 },
            { min: -10, max: 45, interval: 15 },
            { min: -10, max: 60, interval: 20 },
            { min: -20, max: 15, interval: 10 },
            { min: -20, max: 30, interval: 15 },
            { min: -20, max: 45, interval: 20 },
            { min: -20, max: 60, interval: 30 }
          ]
        },
        series: {
          Palv: { index: 1, name: "Palv", visible: false, color: "#48CCBF" },
          Pmus: { index: 2, name: "Pmus", visible: false, color: "#BDBDBD" },
          Pva: { index: 0, name: "Pva", visible: true, color: "red" }
        },
        visible: true
      }
    },
    height: 95,
    menuVisible: false,
    scrollPosition: 100,
    scrollVisible: false,
    timeWindow: 15,
    width: 470,
    zoomEnabled: false
  },
  description: "",
  duration: 4,
  examsFiles: [],
  exerciseName: "",
  expectedResults: {},
  extraQuestions: [],
  gasometry: {
    options: {
      colored: false,
      continuous: false,
      limitedRequests: false,
      menuVisible: true,
      pao2fio2Visible: false,
      requestLimit: 0
    }
  },
  level: "",
  monitor: { options: { displayTidalVolumePerKilogram: true } },
  objectives: "",
  patient: {
    options: {
      visibility: {
        clinicalConditions: true,
        metabolicStatus: true,
        elevation: true
      }
    },
    parameters: {
      age: 1,
      airwayResistance: 10,
      clinicalCondition: "CUSTOM",
      deadSpace: 0.26,
      duration: 0,
      elevation: 0,
      frequency: 0,
      gender: "MALE",
      height: 1,
      idealBodyWeight: 10,
      intensity: 0,
      leak: 0,
      lungCompliance: 38,
      metabolicStatus: "NEUTRAL",
      shuntEffect: 13,
      weight: 10
    }
  },
  tasks: "",
  userId: 0,
  ventilator: {
    options: {
      displayMenu: false
    },
    parameters: {
      endOfCycle: 25,
      expiratoryPause: 0,
      fio2: 35,
      fio2Control: "NASAL_CANNULA",
      flow: 40,
      frequency: 14,
      inspiratoryPause: 0,
      inspiratoryTime: 1,
      pattern: "CONSTANT",
      peep: 7,
      pressure: 15,
      riseTime: 0.1,
      supportPressure: 15,
      volume: 550
    },
    syncTrigger: {
      max: 10,
      min: 1,
      type: "FLOW",
      value: 2
    },
    ventilationMode: "PCV"
  },
  _id: ""
};
