import React, { useState, useEffect } from "react";
import styled from "styled-components";

import StyledIcon from "../Icon";
import useOnChange from "../Input/hooks/useOnChange";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 5px;
  border: 1px solid ${(props) => props.theme.palette.defaultColor};
  border-radius: 5px;
`;

const ArrowUp = styled.div`
  display: flex;
  transform: rotate(90deg);
  cursor: pointer;
`;

const ArrowDown = styled.div`
  display: flex;
  transform: rotate(-90deg);
  cursor: pointer;
`;

const Input = styled.input`
  display: flex;
  border: none;
  text-align: center;
  max-width: 50px;

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
`;

const Icon = styled(StyledIcon)`
  margin: 0;
  padding: 0;
  fill: ${(props) => props.theme.palette.primaryTextColor};
  opacity: 0.5
    ${(props) =>
    props.focus &&
    `
    opacity: 1
  `};
`;

const SpinnButton = styled.span`
  background-color: ${(props) => props.theme.palette.defaultColor};
  border-radius: 100%;
  ${(props) =>
    props.focus &&
    `
      &:hover {
        background-color: ${props.theme.palette.initialColor};
      };
      &:active {
        background-color: ${props.theme.palette.defaultColor};
      };
  `}
`;

const iconSize = 20;

const InputNumber = React.forwardRef(({ focus = false, onChange, value, name, ...rest }, ref) => {
  const [count, setCount] = useState(value);

  const handleChange = e => setCount(e.target.validity.valid ? e.target.value : count);
  const handleInput = (e) =>
    setCount(e.target.validity.valid ? e.target.value : count);
  const handleOnBlur = (e) => !e.target.value && setCount(0);

  const handleAddCount = () => focus && setCount(parseInt(count) + 1);
  const handleSubCount = () => focus && count > 0 && setCount(count - 1);

  useEffect(() => {
    onChange && onChange(count);
  }, [count])

  return (
    <Wrapper>
      <SpinnButton onClick={handleSubCount} focus={focus}>
        <ArrowDown>
          <Icon icon="chevronLeft" size={iconSize} focus={focus} />
        </ArrowDown>
      </SpinnButton>

      <Input
        type="text"
        pattern="[0.0-9.0]*"
        maxlength="6"
        name={name}
        value={count}
        disabled={!focus}
        onChange={handleChange}
        onInput={handleInput}
        onBlur={handleOnBlur}
        ref={ref}
        {...rest}
      />

      <SpinnButton onClick={handleAddCount} focus={focus}>
        <ArrowUp>
          <Icon icon="chevronLeft" size={iconSize} focus={focus} />
        </ArrowUp>
      </SpinnButton>
    </Wrapper>
  );
});

export default InputNumber;
