import React, { useState } from "react";

import styled from "styled-components";

import BuilderWizardStep from "../BuilderWizardStep";

import FormSection from "../FormSection";

import Field from "../Field";
import Input from "../InputForm";
import TextArea from "../TextArea";
import InputRadio from "../InputRadio";
import SelectFile from "../SelectFile";
import CheckboxItem from "../CheckboxItem";

const BuilderCheckboxItem = styled(CheckboxItem)``;
const BuilderInputRadio = styled(InputRadio)``;

const BuilderFormSection = styled(FormSection)`
  border-bottom: none;
  padding: 10px;

  h3 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  span input {
    padding: 10px;
  }

  ${BuilderCheckboxItem}, ${BuilderInputRadio} {
    margin: 5px 0;
    color: #000;
  }

  ${BuilderCheckboxItem} {
    input + span {
      border: 2px solid ${(props) => props.theme.palette.primaryTextColor};
    }
  }
`;

export default ({
  exercise = {},
  fetchLevelsData = [],
  fetchCategoriesData = [],
  onExamFilesUpdate,
  onCoverFileUpdate,
  examFiles,
  coverFile,
  register,
}) => {
  const [enabledOthers, setEnabledOthers] = useState(false);

  return (
    <BuilderWizardStep>
      <BuilderFormSection title="Nome do exercício de simulação">
        <Field
          name="name"
          defaultValue={exercise.name}
          component={Input}
          placeholder="Digite aqui"
          ref={register}
        />
      </BuilderFormSection>
      <BuilderFormSection title="Descreva o objetivo do exercício">
        <Field
          name="objectives"
          component={TextArea}
          defaultValue={exercise.objectives}
          placeholder="Digite aqui"
          ref={register}
        />
      </BuilderFormSection>
      <BuilderFormSection title="Selecione a categoria que melhor descreve este exercício">
        {fetchCategoriesData.map((field) => (
          <BuilderCheckboxItem
            key={field.id}
            id={field.id}
            value={field.id}
            name={`categories[${field.id}]`}
            defaultChecked={exercise.categories && exercise.categories.some(category => category.id === field.id)}
            labelBefore={false}
            labelText={field.name}
            ref={register}
          />
        ))}
        <BuilderCheckboxItem
          name="others"
          labelBefore={false}
          labelText="Outros"
          onChange={e => setEnabledOthers(e.target.checked)}
        />
        {enabledOthers ? <Field
          name="categories[0]"
          component={Input}
          placeholder="Digite aqui a outra opção"
          ref={register}
        /> : ""}
      </BuilderFormSection>
      <BuilderFormSection title="Selecione o nível de dificuldade do exercício">
        {fetchLevelsData.map(field => (
          <BuilderInputRadio
            key={field.id}
            tint="primaryTextColor"
            inline={false}
            title={field.name}
            value={field.id}
            id={field.id}
            name="level"
            ref={register}
            defaultChecked={exercise.level && exercise.level.id === field.id}
          />
        ))}
      </BuilderFormSection>
      <BuilderFormSection title="Selecione os arquivos de mídia (imagens, vídeos, etc) que estarão disponíveis para visualização durante o exercício">
        <SelectFile multiple onFilesUpdate={onExamFilesUpdate} />
        {examFiles && examFiles.map((file, index) => (
          <input type="hidden" ref={register} key={index} name={`examsFiles[${index}]`} value={file.id} />
        ))}
      </BuilderFormSection>
      <BuilderFormSection title="Selecione uma imagem para a capa do exercício">
        <SelectFile onlyImage onFilesUpdate={onCoverFileUpdate} />
        {coverFile &&
          <input type="hidden" ref={register} name="coverPath" value={coverFile.filePath} />
        }
        <BuilderCheckboxItem labelBefore={false} labelText="Utilizar padrão de imagem com a logo xlung" />
      </BuilderFormSection>
    </BuilderWizardStep>
  );
};
