import React from "react";
import styled from "styled-components";

import BuilderWizardNavItem from "../BuilderWizardNavItem";

const Wrapper = styled.ul`
  counter-reset: step;
  list-style: none;
  display: flex;
  height: 100%;
  padding: 20px 10px;
`;

const BuilderWizardNav = ({ steps = [], selectedStep = {}, onSelectStep }) => {
  return (
    <Wrapper>
      {steps.map(step => (
        <BuilderWizardNavItem
          id={step.id}
          key={step.id}
          title={step.title}
          pos={step.pos}
          selected={selectedStep.pos === step.pos}
          passed={step.pos < selectedStep.pos}
          onSelectStep={onSelectStep}
          steps={steps}
        />
      ))}
    </Wrapper>
  );
};

export default BuilderWizardNav;
