import { useState, useEffect } from "react";
import useAuthFetchApi from "../../../hooks/useAuthFetchApi";

export default ({ register, exercise }) => {

  const [examFiles, setExamFiles] = useState([]);
  const [coverFile, setCoverFile] = useState('');

  const { data: fetchExamFiles, startFetch: startFetchUploadExamFiles } = useAuthFetchApi("/exercises/builder/upload/exam-files", "POST");
  const { data: fetchCoverFile, startFetch: startFetchUploadCoverFile } = useAuthFetchApi("/exercises/builder/upload/cover-file", "POST");
  const { startFetch: startFetchCategories, data: fetchCategoriesData } = useAuthFetchApi("/categories");
  const { startFetch: startFetchLevels, data: fetchLevelsData } = useAuthFetchApi("/levels");

  // useEffect(() => {
  //   exercise.examsFiles && setExamFiles(exercise.examsFiles);
  //   exercise.coverPath && setCoverFile({ filePath: exercise.coverPath });
  // }, [exercise])

  useEffect(() => {
    setExamFiles(fetchExamFiles);
  }, [fetchExamFiles])

  useEffect(() => {
    setCoverFile(fetchCoverFile);
  }, [fetchCoverFile])

  useEffect(() => {
    startFetchCategories();
    startFetchLevels();
  }, []);

  const onExamFilesUpdate = (files) => {
    if (files.length > 0) {
      const formData = new FormData()
      files.forEach(file => {
        formData.append('files', file)
      });
      startFetchUploadExamFiles(formData, {}, { file: true })
      console.log(fetchCoverFile);
    }
  }
  const onCoverFileUpdate = (files) => {
    if (files.length > 0) {
      const formData = new FormData()
      formData.append('file', files[0])
      startFetchUploadCoverFile(formData, {}, { file: true })
    }
  }

  return {
    fetchCategoriesData,
    fetchLevelsData,
    onExamFilesUpdate,
    onCoverFileUpdate,
    examFiles,
    coverFile,
  };
};
