import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { tableRowBase, tableColumnBase } from "./styles";

import StyledTableRow from "../TableRow";
import StyledTableColumn from "../TableColumn";
import InputNumber from "../InputNumber";
import CheckboxItem from "../CheckboxItem";

const TableColumn = styled(StyledTableColumn)`
  ${tableColumnBase};
  align-items: center;
  ${props => props.startContent && "justify-content: start;"};
`;

const TableRow = styled(StyledTableRow)`
  ${tableRowBase};
  padding: 10px;
  ${props => props.checked && `background-color: ${props.theme.palette.defaultColor}`};
`;

export default ({
  defaultValues = {},
  register,
  label = "",
  minAcceptable = 0,
  maxAcceptable = 0,
  answer = 0,
  weight = 0,
  id = 0,
  index,
}) => {
  const [checked, setChecked] = useState(false);
  const [score, setScore] = useState(0);

  useEffect(() => {
    defaultValues.evaluatedItem && setChecked(defaultValues.evaluatedItem.id === id)
  }, [defaultValues.evaluatedItem])

  const handleWeightChange = (value) => {
    setScore(value);
  }
  return (
    <TableRow hover={true} checked={checked}>
      <TableColumn startContent>
        <CheckboxItem
          labelBefore={false}
          labelText={label}
          checked={checked}
          name={`scoreItems[${index}][evaluatedItem][id]`}
          ref={register}
          value={id}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </TableColumn>
      <TableColumn>
        <InputNumber
          focus={checked}
          defaultValue={minAcceptable}
          value={defaultValues.minimum}
          ref={register}
          name={`scoreItems[${index}][minimum]`}
        />
      </TableColumn>
      <TableColumn>{defaultValues.value || answer}
        <input
          type="hidden"
          defaultValue={answer}
          value={defaultValues.value}
          ref={register}
          name={`scoreItems[${index}][value]`}
        />
      </TableColumn>
      <TableColumn>
        <InputNumber
          focus={checked}
          defaultValue={maxAcceptable}
          value={defaultValues.maximum}
          ref={register}
          name={`scoreItems[${index}][maximum]`}
        />
      </TableColumn>
      <TableColumn>
        <InputNumber
          focus={checked}
          defaultValue={weight}
          value={defaultValues.weight}
          ref={register}
          name={`scoreItems[${index}][weight]`}
          onChange={handleWeightChange}
        />
      </TableColumn>
      <TableColumn>{score}</TableColumn>
    </TableRow>
  );
}
