import React from "react";

const Tick = ({ tick, count, topTick = false, currentValue }) => (
  <div>
    {topTick && (
      <div
        style={{
          position: "absolute",
          marginTop: -35,
          fontSize: 10,
          color: "#7D7B7B",
          textAlign: "center",
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {currentValue === tick.value ? "" : tick.value}
      </div>
    )}
    <div
      style={{
        position: "absolute",
        marginTop: topTick ? -14 : 10,
        width: 1,
        height: 5,
        backgroundColor: "rgb(200,200,200)",
        left: `${tick.percent}%`,
      }}
    />
  </div>
);

export { Tick };
