import React from "react";

import styled from "styled-components";

const Container = styled.div`
  ${props => props.inline && "display: flex; flex-direction: row;"}

  background-color: #fff;
  padding: ${(props) => (props.nopadding ? "0px" : "30px")};
  border-bottom: 1px solid ${(props) => props.theme.palette.defaultColor};
`;

const TitleInput = styled.h3`
  flex-basis: 100%;
  margin: 0;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: ${(props) => (props.nopadding ? "30px 30px 0px 30px" : "0px")};
`;

const FormSection = ({ className, children, title, inline, nopadding }) => {
  return (
    <Container className={className} inline={inline} nopadding={nopadding}>
      {title && <TitleInput nopadding={nopadding}>{title}</TitleInput>}
      {children}
    </Container>
  );
};

export default FormSection;
