import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    padding: 15px;
    background-color: #fff;
`;

const ContentHeader = ({children}) => <Wrapper>{children}</Wrapper>;

export default ContentHeader;