import React from "react";

import Context from "./AuthContext";
import useFetchApi from "../../hooks/useFetchApi";

const setUserToStorage = (user) =>
  user && window.localStorage.setItem("xt-user", JSON.stringify(user));

const getUserFromStorage = () => {
  try {
    return JSON.parse(window.localStorage.getItem("xt-user")) || {};
  } catch (err) {
    return {};
  }
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(getUserFromStorage());
  const { isFetching, data, startFetch } = useFetchApi("/auth/login", "POST");

  const isAuthenticating = isFetching;
  const isAuthenticated = !!user.api_token;

  const login = (username, password) => {
    startFetch(
      JSON.stringify({
        username,
        password,
      })
    );
  };

  const logout = () => localStorage.removeItem('xt-user');

  const matchRoles = (rolesToMatch = []) =>
    !!rolesToMatch.filter((roleToMatch) =>
      (user.roles || []).includes(roleToMatch)
    ).length;

  React.useEffect(() => {
    const hasUser = data?.api_token;
    if (hasUser) {
      setUser(data);
    }
  }, [data, setUser]);

  React.useEffect(() => {
    setUserToStorage(user);
  }, [user]);

  const value = {
    user,
    login,
    logout,
    isAuthenticating,
    isAuthenticated,
    matchRoles,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default AuthProvider;
