import React from "react";
import styled from "styled-components";

import DropdownArrow from "../DropdownArrow";

import { base, default_, disabled } from "../MenuItem/styles.js";

const Wrapper = styled.li`
  ${base}
  ${default_};
  ${disabled};
`;

const Content = styled.div`
  border-top: none;
  opacity: ${(props) => (props.hidden ? "0" : "1")};
  max-height: ${(props) => (props.hidden ? "0" : "100%")};
  overflow: hidden;
  padding: 0;
  transition: all 0.3s;
`;

const MenuItemArrow = ({
  children,
  label,
  className,
  disabled = false,
  palette = "default",
  active = false,
  to = "#",
  handleClickMenuItem,
  hidden,
}) => {
  return (
    <>
      <Wrapper
        className={className}
        disabled={disabled}
        palette={palette}
        active={active}
        onClick={() => handleClickMenuItem(to)}
        hidden={hidden ? 1 : 0}
      >
        {label}
        <DropdownArrow direction={hidden} size="30" />
      </Wrapper>
      <Content hidden={hidden ? 1 : 0}>{children}</Content>
    </>
  );
};

export default MenuItemArrow;
