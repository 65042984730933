import { css } from "styled-components";
import { lighten } from "polished";

export const tableRowBase = css`
  padding: 16px 0;
  ${(props) =>
    props.hover &&
    `
    :hover {
      background-color: ${lighten(0.02, props.theme.palette.defaultColor)};
    }
  `}
`;

export const tableColumnBase = css`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0 10px;
`;
