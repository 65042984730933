import React from "react";
import styled from "styled-components";

import Button from "../../../../components/Button";
import { XRayExam, AudioExam } from "./";
import StyledIcon from "../../../Icon";

const Wrapper = styled.header`
  height: 120px;
  width: 300px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10%;
  right: 50%;
  left: 50%;
  border-radius: 5px;
  padding: 10px;
  background-color: ${(props) => props.theme.palette.darkerGrayColor};
  box-shadow: 0px 0px 3px ${(props) => props.theme.palette.darkerColor};
`;

const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.quaternaryTextColor};
`;

const HeaderItem = styled.section`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const LabelButton = styled.h1`
  margin: 0 5px;
  line-height: 0;
  font-size: 15px;
  color: ${(props) => props.theme.palette.quaternaryTextColor};
`;

const Icon = styled(StyledIcon)`
  margin: 0 5px;
  fill: #ffffff;
  size: 70px;
  &:hover,
  &:focus {
    fill: ${(props) => props.theme.palette.alertColor};
  }
`;

const RequestExams = ({ close }) => {
  const [openExam, seOpenExam] = React.useState("");

  if (openExam === "")
    return (
      <Wrapper>
        <HeaderSection>
          Exames
          <Icon icon="close" size={25} onClick={() => close()} />
        </HeaderSection>
        <HeaderItem>
          <Button
            palette="darker"
            variant="filled"
            size="tiny"
            onClick={() => seOpenExam("xray")}
          >
            Ver
          </Button>
          <LabelButton>Raio-X de tórax prévio à intubação</LabelButton>
        </HeaderItem>
        <HeaderItem>
          <Button
            palette="darker"
            variant="filled"
            size="tiny"
            onClick={() => seOpenExam("audio")}
          >
            Ouvir
          </Button>
          <LabelButton>Ausculta pulmonar bilateral</LabelButton>
        </HeaderItem>
      </Wrapper>
    );
  if (openExam === "xray")
    return <XRayExam close={close} back={() => seOpenExam("")} />;
  if (openExam === "audio")
    return <AudioExam close={close} back={() => seOpenExam("")} />;
};

export { RequestExams };
