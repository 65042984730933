import React from "react";
import styled from "styled-components";

import {
  base,
  disabled,
  size,
  initial,
  alert,
  primary,
  secondary,
  success,
  darkerGray,
  darker,
  info,
} from "./styles";

const Wrapper = styled.button`
  ${base}
  ${disabled}
  ${size}
  ${(props) => props.palette === "initial" && initial};
  ${(props) => props.palette === "alert" && alert};
  ${(props) => props.palette === "primary" && primary};
  ${(props) => props.palette === "secondary" && secondary};
  ${(props) => props.palette === "success" && success};
  ${(props) => props.palette === "darkerGray" && darkerGray};
  ${(props) => props.palette === "darker" && darker};
  ${(props) => props.palette === "info" && info};
`;

const Button = ({
  children,
  palette = "initial",
  variant = "filled",
  size = "small",
  disabled = false,
  onClick,
  as = "button",
  href,
  className,
}) => (
  <Wrapper
    className={className}
    palette={palette}
    variant={variant}
    size={size}
    as={as}
    href={href}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </Wrapper>
);

export default Button;
