import React from "react";
import styled from "styled-components";

import Card from "../Card";
import CardBody from "../CardBody";
import CardFooter from "../CardFooter";
import AlertModal from "../AlertModal";

import Button from "../Button";

import BuilderWizardNav from "../BuilderWizardNav";

import { DEFAULT_PADDING } from "../../settings";
import Icon from "../Icon";
import Form from "../Form";
import { darken } from "polished";

const Wrapper = styled.section``;

const NavWrapper = styled(Card).attrs((props) => ({
  ...props,
  padding: false,
}))`
  margin-bottom: ${DEFAULT_PADDING};
  border: none;
  position: relative;
  z-index: 1;
  margin-left: -15px;
  margin-right: -15px;
  border-radius: 0;
`;

const StepsWrapper = styled(Card).attrs((props) => ({
  ...props,
  padding: false,
}))`
  border-width: 0;
`;

const StepsWrapperBody = styled(CardBody)`
  min-height: 350px;
`;

const StepsWrapperFooter = styled(CardFooter)`
  justify-content: flex-end;
`;

const BuilderButton = styled(Button)`
  text-transform: none;
  padding: 0;
  height: 34px;
`;

const BuilderPrevButton = styled(BuilderButton)`
  color: ${(props) => props.theme.palette.primaryColor};
  background-color: #fff;
  padding-right: 1.4rem;
  margin-right: 20px;
  
  ${(props) =>
    !props.disabled &&
    `
    &:hover, &:focus {
      background-color: ${darken("0.05", "#fff")};
    }
  `};

  svg {
    fill: ${(props) => props.theme.palette.primaryColor};
  }
`;

const BuilderNextButton = styled(BuilderButton)`
  padding-left: 1.4rem;
  margin-right: 10px;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2)
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
`;

const BuilderFinishButton = styled(BuilderNextButton)`
  padding-right: 1.4rem;
`;

export default ({
  steps,
  selectedStep,
  onSelectStep,
  goToPrevStep,
  goToNextStep,
  stepsBody,
  handleSubmit,
  finishBuilder,
  isOpenFinishModal,
  setIsOpenFinishModal
}) => {
  return (
    <Wrapper>
      <NavWrapper>
        <BuilderWizardNav
          onSelectStep={onSelectStep}
          steps={steps}
          selectedStep={selectedStep}
        />
      </NavWrapper>
      <StepsWrapper>
        <StepsWrapperBody>
          <Form>
            {stepsBody[selectedStep.pos - 1]}
          </Form>
        </StepsWrapperBody>
        <StepsWrapperFooter>
          {selectedStep.pos !== 1 ?
            <BuilderPrevButton size="medium" palette="primary" onClick={handleSubmit(goToPrevStep)}>
              <Icon icon="chevronLeft" size="32" />
              Voltar
            </BuilderPrevButton>
            : ''
          }
          {selectedStep.pos === steps.length ?
            <BuilderFinishButton size="medium" palette="primary" onClick={() => setIsOpenFinishModal(true)}>
              Concluir
            </BuilderFinishButton> :
            <BuilderNextButton size="medium" palette="primary" onClick={handleSubmit(goToNextStep)}>
              Próximo passo
              <Icon icon="chevronRight" size="32" color="white" />
            </BuilderNextButton>
          }
          <AlertModal
            title="Concluir Exercício"
            subtitle="Tem certeza que deseja concluir este exercício?"
            isOpen={isOpenFinishModal}
            onConfirm={handleSubmit(finishBuilder)}
            onCancel={() => setIsOpenFinishModal(false)}
            type="alert"
          />
        </StepsWrapperFooter>
      </StepsWrapper>
    </Wrapper>
  );
};
