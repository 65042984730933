import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import ScoresTableLine from "../ScoresTableLine/ScoresTableLine";
import { tableColumnBase, tableRowBase } from "../ScoresTableLine/styles";
import StyledTable from "../Table";
import StyledTableColumn from "../TableColumn";
import StyledTableRow from "../TableRow";



const Wrapper = styled.div`
  background-color: #fff;
  border-bottom: 1px solid ${(props) => props.theme.palette.initialColor};
`;

const Table = styled(StyledTable)`
  margin: 0;
  padding: 0;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  font-weight: 400;
  display: flex;
  flex: 1;
  justify-content: center;
`;

const TableColumn = styled(StyledTableColumn)`
  ${tableColumnBase};
`;


const TableRow = styled(StyledTableRow)`
  ${tableRowBase};
  padding: 10px;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.palette.primaryTextColor};
  margin-left: 10px;
  margin-bottom: 50px;
`;

export default ({
  register,
  title,
  scoreItems = [],
  lines = [],
}) => {
  const maxScore = 100;
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Table>
        <thead>
          <TableRow hover={false}>
            <TableHeader>Item Avaliado</TableHeader>
            <TableHeader>Mínimo Acetável</TableHeader>
            <TableHeader>Gabarito</TableHeader>
            <TableHeader>Máximo Aceitável</TableHeader>
            <TableHeader>Peso</TableHeader>
            <TableHeader>Pontuação</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {lines.map((item, index) => {
          const defaultValues = scoreItems.find(scoreItem => scoreItem.evaluatedItem && scoreItem.evaluatedItem.id === item.id);
            return (
              <ScoresTableLine
                defaultValues={defaultValues}
                key={index}
                index={index}
                id={item.id}
                register={register}
                label={item.label}
                answer={Number(item.defaultValue)}
                // individualScore={item[2]}
                minAcceptable={Number(item.defaultValue)}
                maxAcceptable={Number(item.defaultValue)}
              />
            )
          })}
        </tbody>
        <tfoot>
          <TableRow>
            <TableColumn style={{ flexGrow: 7, justifyContent: "flex-end" }}>Pontuação Máxima: </TableColumn>
            <TableColumn>{maxScore} pontos</TableColumn>
          </TableRow>
        </tfoot>
      </Table>
    </Wrapper>
  );
}
