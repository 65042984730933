import React from "react";
import styled from "styled-components";
import CardHeader from "../CardHeader";
import ModalTitle from "../ModalTitle";
import StyledModalCloseButton from "../ModalCloseButton";

const Wrapper = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalCloseButton = styled(StyledModalCloseButton)`
  margin-left: auto;
`;

const ModalHeader = ({ title, onClose, className, ...rest }) => (
  <Wrapper className={className} {...rest}>
    <ModalTitle>{title}</ModalTitle>
    <ModalCloseButton onClick={onClose} />
  </Wrapper>
);

export default ModalHeader;
