import React from "react";
import styled, { css } from "styled-components";

const closed = css`
  height: 0;
  overflow: hidden;
`;

const openned = css`
  background-color: ${props => props.theme.palette.lightGrayColor};
  display: flex;
  flex: 1;
`;

const Wrapper = styled.section``;

const Button = styled.div`
  display: flex;
  background-color: ${props => props.theme.palette[props.tint]};
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-bottom: 1px solid ${props => props.theme.palette.secondaryColor};
  cursor: pointer;
  color: ${props =>
    props.active
      ? props.theme.palette.quaternaryTextColor
      : props.theme.palette.tertiaryTextColor};
`;

const View = styled.div`
  ${props => props.active && openned}
  ${props => !props.active && closed}
`;

const ColapseCard = ({
  tint = "darkerColor",
  title = "",
  padding = true,
  children,
  className,
  defaultActive = false
}) => {
  const [active, setActive] = React.useState(defaultActive);
  return (
    <Wrapper
      className={className}
      tint={tint}
      padding={padding}
      onClick={() => setActive(!active)}
    >
      <Button active={active} tint={tint}>
        {title}
      </Button>
      <View active={active}>{children}</View>
    </Wrapper>
  );
};

export default ColapseCard;
