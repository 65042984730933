import React from "react";
import styled from "styled-components";

import ExamDisplay from "../../ExamDisplay";

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.palette.quaternaryTextColor};
  background-color: ${props => props.theme.palette.darkerColor};
`;

const ExamWrapper = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: ${props => props.theme.palette.secondaryColor};
`;

const ExamItem = styled.div`
  width: 49.7%;
  margin-bottom: 1px;
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const ExamsSincronia = ({ results }) => {
  const stateColor = {
    ok: "successColor",
    warning: "alertColor",
    danger: "dangerColor"
  };

  const insExRatioFormat = result => {
    return result?.insExRatio?.i + ":" + result?.insExRatio?.e.toFixed(2);
  };

  return (
    <Wrapper>
      {results && (
        <ExamWrapper>
          <ExamItem>
            <ExamDisplay
              title="T. Insp"
              value={results.inspirationTime.toFixed(1)}
              label="(s)"
              tintValue="successColor"
            />
          </ExamItem>
          <ExamItem>
            <ExamDisplay
              title="T. Exp"
              value={results.expirationTime.toFixed(1)}
              label="(s)"
              tintValue="successColor"
            />
          </ExamItem>
          <ExamItem>
            <ExamDisplay
              title="TTOT"
              value={results.cycleTime.toFixed(1)}
              label="(s)"
              tintValue="successColor"
            />
          </ExamItem>

          <ExamItem>
            <ExamDisplay
              title="I:E"
              value={insExRatioFormat(results)}
              label="(s)"
              tintValue="successColor"
            />
          </ExamItem>
        </ExamWrapper>
      )}
    </Wrapper>
  );
};

export default ExamsSincronia;
