import React from "react";

import BuilderWizardStepSeven, {
  useBuilderWizardStepSevenForm,
} from "../../components/BuilderWizardStepSeven";

export default ({ register, exercise }) => {
  const {
    themes,
    handleAddTheme,
    handleDelTheme,
  } = useBuilderWizardStepSevenForm({ exercise });
  return (
    <BuilderWizardStepSeven
      register={register}
      exercise={exercise}
      themes={themes}
      handleAddTheme={handleAddTheme}
      handleDelTheme={handleDelTheme}
    />
  );
};
