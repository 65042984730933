import React from "react";
import styled from 'styled-components';

import BuilderWizardStep from "../BuilderWizardStep";

import Slider from "../Slider";
import BuilderFormSection from "../BuilderFormSection";
import TextArea from "../TextArea";
import Field from "../Field";

const SliderBox = styled.div`
  max-width: 300px;
`;

export default ({ register, exercise }) => {
  return (
    <BuilderWizardStep>
      <BuilderFormSection title="Descreva pontualmente as tarefas do exercício">
        <Field
          name="tasks"
          defaultValue={exercise.tasks}
          component={TextArea}
          placeholder="Digite aqui"
          ref={register}
        />
      </BuilderFormSection>
      <BuilderFormSection title="Quanto tempo o aluno terá disponível para a execução do exercício?">
        <SliderBox>
          <Slider ref={register} name="duration" initialSize={exercise.duration} />
        </SliderBox>
      </BuilderFormSection>
      <BuilderFormSection title="Descreva o cenário clínico do exercício">
        <Field
          name="scenario"
          defaultValue={exercise.scenario}
          component={TextArea}
          placeholder="Digite aqui"
          ref={register}
        />
      </BuilderFormSection>
    </BuilderWizardStep>
  );
};
