import React from "react";
import styled from "styled-components";

const Wrapper = styled.tr`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TableRow = ({ children, className }) => (
  <Wrapper className={className}>{children}</Wrapper>
);

export default TableRow;
