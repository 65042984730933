import React from "react";
import styled from "styled-components";

import { Link as StyledLink } from "react-router-dom";

import { base, default_, disabled } from "./styles.js";

const Link = styled(StyledLink)`
  text-decoration: none;
`;

const Wrapper = styled.li`
  ${base}
  ${default_};
  ${disabled};
`;

const MenuItem = ({
  children,
  className,
  disabled = false,
  palette = "default",
  active = false,
  subitem = false,
  to = "#",
}) => {
  return (
    <Link to={to}>
      <Wrapper
        className={className}
        disabled={disabled}
        palette={palette}
        active={active}
        subitem={subitem}
      >
        {children}
      </Wrapper>
    </Link>
  );
};

export default MenuItem;
