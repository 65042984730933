import React from "react";
import styled, { css } from "styled-components";

import StyledIcon from "../Icon";
import { styles } from "../Button";

const base = css`
  ${styles.base};
  border-radius: 50px;
  width: 32px;
  height: 32px;
  padding: 0;
`;

const iconInitialFilled = css`
  fill: ${(props) => props.theme.palette.primaryTextColor};
`;

const iconInitialOutlined = css`
  fill: ${(props) => props.theme.palette.primaryTextColor};
`;

const iconInitial = css`
  ${(props) => props.variant === "filled" && iconInitialFilled};
  ${(props) => props.variant === "outlined" && iconInitialOutlined};
`;

const iconPrimaryFilled = css`
  fill: ${(props) => props.theme.palette.quaternaryTextColor};
`;

const iconPrimaryOutlined = css`
  fill: ${(props) => props.theme.palette.primaryColor};
  ${(props) =>
    !props.disabled &&
    ` 
    &:hover {
      fill: ${props.theme.palette.quaternaryTextColor};
    }
    `};
`;

const iconPrimary = css`
  ${(props) => props.variant === "filled" && iconPrimaryFilled};
  ${(props) => props.variant === "outlined" && iconPrimaryOutlined};
`;

const Wrapper = styled.button`
${base}
${styles.disabled}
${styles.size}
${(props) => props.palette === "initial" && styles.initial};
${(props) => props.palette === "primary" && styles.primary};
${(props) => props.palette === "secondary" && styles.secondary};
`;

const Icon = styled(StyledIcon)`
  width: 100%;
  height: 100%;
  padding: 2px;
  box-sizing: border-box;
  ${(props) => props.palette === "initial" && iconInitial};
  ${(props) => props.palette === "primary" && iconPrimary};
`;

const IconButton = ({
  icon,
  size = 64,
  palette = "initial",
  variant = "outlined",
  disabled = true,
  className,
  onClick,
}) => {
  return (
    <Wrapper
      palette={palette}
      variant={variant}
      onClick={onClick}
      className={className}
    >
      <Icon icon={icon} size={size} palette={palette} variant={variant} />
    </Wrapper>
  );
};

export default IconButton;
