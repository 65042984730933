import React from "react";

import BuilderWizardStepSix, {
  useBuilderWizardStepSixForm,
} from "../../components/BuilderWizardStepSix";

const BuilderWizardStepSixContainer = ({ register, exercise }) => {
  const {
    fetchEvaluationData,
  } = useBuilderWizardStepSixForm();

  return (
    <BuilderWizardStepSix
      evaluationData={fetchEvaluationData || []}
      exercise={exercise}
      register={register}
    />
  );
};

export default BuilderWizardStepSixContainer;
