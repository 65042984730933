import React, { Fragment } from "react";
import styled from "styled-components";

const LabelContainer = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -110px;
`;

const LabelValue = styled.p`
  line-height: 5px;
  margin: 5px;
  font-size: 2rem;
  font-align: center;
  color: ${(props) => props.theme.palette.quaternaryTextColor};
`;

const Label = styled.p`
  line-height: 0;
  font-size: 12px;
  font-align: center;
  color: ${(props) => props.theme.palette.quaternaryTextColor};
`;

const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  getHandleProps,
  title,
}) => {
  return (
    <Fragment>
      <button
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          display: "flex",
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          width: 16,
          height: 16,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          borderWidth: 1,
          borderColor: "white",
          borderStyle: "solid",
          boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
          backgroundColor: disabled ? "#666" : "#01768C",
        }}
        {...getHandleProps(id)}
      />
      <div
        style={{
          display: "flex",
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          width: 16,
          height: 16,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          borderWidth: 1,
          borderColor: "white",
          borderStyle: "solid",
          boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
          backgroundColor: disabled ? "#666" : "#01768C",
        }}
      >
        <LabelContainer>
          <LabelValue>{value}</LabelValue>
          <Label>{title}</Label>
        </LabelContainer>
      </div>
    </Fragment>
  );
};

export { Handle };
