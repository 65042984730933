import React from "react";
import styled from "styled-components";

import Form from "../Form";
import Input from "../Input";
import InputPassword from "../InputPassword";
import StyledField from "../Field";
import Button from "../Button";

const Field = styled(StyledField)`
  margin: 15px 0;
`;

const LoginForm = ({
  onSubmit,
  errors = {},
  values = {},
  registerEmailField,
  registerPasswordField,
}) => {
  return (
    <Form onSubmit={onSubmit}>
      <Field
        label="Email"
        name="email"
        value={values.email}
        valid={!errors.email}
        size="large"
        errorMessage={errors.email?.message}
        ref={registerEmailField}
        component={Input}
      />
      <Field
        label="Senha"
        name="password"
        value={values.password}
        valid={!errors.password}
        icon="eye"
        errorMessage={errors.password?.message}
        ref={registerPasswordField}
        component={InputPassword}
      />
      <Button palette="primary">Entrar</Button>
    </Form>
  );
};

export default LoginForm;
