import { useState } from "react";

export default ({ exercise }) => {
  const defaultThemes =
    exercise.complementaryMaterial && exercise.complementaryMaterial.length > 0 ?
      exercise.complementaryMaterial.map((item, index) => ({ id: index, item })) :
      [{ id: 1 }];
  const [themes, setThemes] = useState(defaultThemes);

  const handleAddTheme = (e) => {
    e.preventDefault();
    setThemes([...themes, { id: themes.length + 1 }]);
  }

  const handleDelTheme = (id) => (e) => {
    e.preventDefault();
    setThemes(themes.filter(theme => Number(theme.id) !== id))
  }

  return {
    themes,
    handleAddTheme,
    handleDelTheme
  };
};
