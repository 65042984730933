import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import RouteContainer from "./RouteContainer";

import UserDisconnectedAlertModalContainer from "./alerts/UserDisconnectedAlertModalContainer";
import GlobalStyles from "../components/GlobalStyles";
import HomeContainer from "./views/HomeContainer";
import LoginContainer from "./views/LoginContainer";
import SimulatorContainer from "./views/SimulatorContainer";
import FeedbackContainer from "./views/FeedbackContainer";
import BuilderContainer from "./views/BuilderContainer";
import ProgressContainer from "./views/ProgressContainer";

import { AuthProvider } from "../contexts/Auth";
import { GridProvider } from "../contexts/Grid";
import { ThemeProvider } from "../contexts/Theme";
import { SidebarProvider } from "../contexts/Sidebar";
import { UserDisconnectedAlertModalProvider } from "../contexts/UserDisconnectedAlertModal";
import { NewsletterAlertModalProvider } from "../contexts/NewsletterAlertModal";

import routes from "../routes";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const AppContainer = () => {
  return (
    <UserDisconnectedAlertModalProvider>
      <NewsletterAlertModalProvider>
        <ThemeProvider>
          <GlobalStyles />
          <AuthProvider>
            <GridProvider>
              <SidebarProvider>
                <Router>
                  <Switch>
                    <RouteContainer exact path={routes.ROOT}>
                      <HomeContainer />
                    </RouteContainer>

                    <RouteContainer path={routes.LOGIN}>
                      <LoginContainer />
                    </RouteContainer>

                    <RouteContainer path={routes.FEEDBACK}>
                      <FeedbackContainer />
                    </RouteContainer>

                    <RouteContainer path={routes.SIMULATOR}>
                      <SimulatorContainer />
                    </RouteContainer>

                    <RouteContainer
                      path={routes.BUILDER}
                      // isProtected
                      // allowedRoles={["teacher", "admin"]}
                    >
                      <BuilderContainer />
                    </RouteContainer>

                    <RouteContainer
                      // path="/builder"
                      // isProtected
                      // allowedRoles={["teacher", "admin"]}
                    >
                      <Redirect to="/builder/step/1" />
                    </RouteContainer>

                    <RouteContainer path={routes.PROGRESS}>
                      <ProgressContainer />
                    </RouteContainer>

                  </Switch>
                  <UserDisconnectedAlertModalContainer />
                </Router>
              </SidebarProvider>
            </GridProvider>
          </AuthProvider>
        </ThemeProvider>
      </NewsletterAlertModalProvider>
    </UserDisconnectedAlertModalProvider>
  );
};
export default AppContainer;
