import React from "react";
import styled from "styled-components";

import ButtonLink from "../../ButtonLink";
import StyledIcon from "../../Icon";

import defaultAvatar from "../../../assets/images/avatar.png";

import { DEFAULT_PADDING } from "../../../settings";

import logo from "../../../assets/images/logoxlungWhite.svg";

const Wrapper = styled.header`
  min-height: 60px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  border-width: 1px;
  border-color: ${(props) => props.theme.palette.initialColor}
  padding: 0 ${DEFAULT_PADDING};
  background-color: ${(props) => props.theme.palette.darkerColor};
`;

const HeaderSection = styled.section`
  display: flex;
  align-items: center;
`;

const HeaderItem = styled.section`
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

const Icon = styled(StyledIcon)`
  margin: 0 5px;
  fill: #ffffff;
  size: 70px;
`;

const ProfilePicture = styled.img`
  border-radius: 100%;
  margin-right: 5px;
  height: 35px;
  width: 35px;
`;

const sizeIcons = 25;
const SimulatorHeader = ({ user = {}, logout }) => (
  <Wrapper>
    <HeaderSection>
      <ButtonLink basic>
        <Icon icon="arrowBack" size={sizeIcons} />
        Voltar para exercícios
      </ButtonLink>
    </HeaderSection>
    <img src={logo} alt="Xlung Logo" height={60} width={120} />
    <HeaderSection>
      <ButtonLink basic>
        <HeaderItem>
          <Icon icon="school" size={sizeIcons} />
        </HeaderItem>
        <ProfilePicture
          src={user.avatar || defaultAvatar}
          alt="UserProfilePicture"
        />
        <HeaderItem>{user.name || "Anônimo"}</HeaderItem>
      </ButtonLink>
      <HeaderItem>
        <ButtonLink basic onClick={logout}>
          Sair <Icon icon="exit" size={sizeIcons} />
        </ButtonLink>
      </HeaderItem>
    </HeaderSection>
  </Wrapper>
);

export default SimulatorHeader;
