import React from "react";
import styled from "styled-components";

import { base, disabled, valid, invalid } from "./styles";

export const Wrapper = styled.span`
  ${base}
  ${disabled}
  ${(props) => props.valid && valid};
  ${(props) => !props.valid && invalid};
`;

export default React.forwardRef(
  (
    {
      name,
      type = "text",
      placeholder,
      valid = true,
      disabled = false,
      className,
      ...rest
    },
    ref
  ) => {
    return (
      <Wrapper valid={valid} className={className}>
        <input
          ref={ref}
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          type={type}
          {...rest}
        />
      </Wrapper>
    );
  }
);
