import React from "react";
import styled, { css } from "styled-components";
import { darken } from "polished";

const badgeSelected = css`
  color: ${(props) => props.theme.palette.primaryTextColor};
  font-weight: 600;
  p {
    background-color: ${(props) => props.theme.palette.defaultColor};
  }
  
  ::before {
    background-color: ${(props) => props.theme.palette.defaultColor};
  } 
  
  + li::after {
    background-color: ${(props) => props.theme.palette.defaultColor};
  }
`;

const badgePassed = css`
  color: #fff;
  p {
    color: ${props => darken(0.3, props.theme.palette.defaultColor)};
  }
  
  ::before {
    background-color: ${(props) => props.theme.palette.successColor};
  } 
`;

const Wrapper = styled.li`
  list-style-type: none;
  float: left;
  width: calc(100% / ${props => props.totalItems});
  position: relative;
  text-align: center;
  color: ${props => darken(0.3, props.theme.palette.defaultColor)};
  cursor: pointer;

  ::before {
    content: counter(step);
    counter-increment: step;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border: 1px solid ${props => props.theme.palette.defaultColor};
    display: block;
    text-align: center;
    margin: 0 auto;
    border-radius: 50%;
    background-color: white;
  }
  
  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.palette.defaultColor};
    top: 15px;
    left: -50%;
    z-index: -1;
  }

  :first-child::after {
    content: none;
  }

  ${props => props.selected && badgeSelected};
  ${props => props.passed && badgePassed};
`;

const Text = styled.p`
  margin: 0;
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BuilderWizardNavItem = ({
  pos,
  title,
  selected = false,
  passed = false,
  onSelectStep,
  steps
}) => {
  const handleOnSelectStep = () => {
    onSelectStep && onSelectStep(pos);
  };
  return (
    <Wrapper title={title} totalItems={steps.length} selected={selected} passed={passed} onClick={handleOnSelectStep}>
      <Text>{title}</Text>
    </Wrapper>
  );
};

export default BuilderWizardNavItem;
