import React from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";

import SimulatorView from "../components/Simulator/SimulatorView";
import SimulatorHeader from "../components/Simulator/SimulatorHeader";

import { useAuthContext } from "../contexts/Auth";

const { REACT_APP_API_BASE_URI } = process.env;

const SimulatorViewContainer = props => {
  const { user, logout } = useAuthContext();

  const [simulator, setSimulator] = React.useState(null);

  const simulatorId = useParams().id;
  const history = useHistory();
  React.useEffect(() => {
    axios
      .get(`${REACT_APP_API_BASE_URI}/exercises/${simulatorId}`)
      .then(response => {
        setSimulator(response.data.simulation);
      });
  }, [simulatorId]);

  const endExercise = answer => {
    axios
      .post(`${REACT_APP_API_BASE_URI}/exercises/${simulatorId}/answers`, {
        answer
      })
      .then(async response => {
        localStorage.setItem(
          answer.exercise_uuid,
          JSON.stringify(response.data)
        );
        localStorage.setItem(
          answer.exercise_uuid + "results",
          JSON.stringify(answer)
        );
      })
      .then(() => history.push(`/simulator/feedback/${answer.exercise_uuid}`));
  };

  return (
    <>
      <SimulatorHeader user={user} logout={logout} />
      {simulator ? (
        <SimulatorView
          simulatorData={simulator}
          setSimulatorData={setSimulator}
          finishSimulation={endExercise}
          {...props}
        />
      ) : (
        <div>Loading</div>
      )}
    </>
  );
};

export default SimulatorViewContainer;
