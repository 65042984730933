import React from "react";
import styled, { css } from "styled-components";

import { useGridContext } from "../../contexts/Grid";
import { mediaQueries } from "../../settings";

const flexBasisCalc = (col, columns) => `${(col / columns) * 100}%`;

const flexBasis = (col, columns) => css`
  flex-basis: ${flexBasisCalc(col, columns)};
`;

const Wrapper = styled.div`
	display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-left: ${(props) => (props.nogutter ? "0px" : props.gutter)};
  padding-right: ${(props) => (props.nogutter ? "0px" : props.gutter)};
  
  ${(props) => flexBasis(props.columns, props.columns)}
  ${(props) =>
    props.small &&
    mediaQueries.small`
  	${flexBasis(props.small, props.columns)}; 
  `}
  ${(props) =>
    props.medium &&
    mediaQueries.medium`
  	${flexBasis(props.medium, props.columns)}; 
  `}
  ${(props) =>
    props.large &&
    mediaQueries.large`
  	${flexBasis(props.large, props.columns)}; 
  `}
`;

const GridColumn = ({ children, small, medium, large, nogutter = false }) => {
  const { columns, gutter } = useGridContext();
  return (
    <Wrapper
      columns={columns}
      gutter={gutter}
      nogutter={nogutter}
      small={small}
      medium={medium}
      large={large}
    >
      {children}
    </Wrapper>
  );
};

export default GridColumn;
