import React from "react";
import styled from "styled-components";

import BuilderWizardStep from "../BuilderWizardStep";
import ScoresTable from "../ScoresTable";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -15px;
  margin-right: -15px;
`;

export default ({
  register,
  evaluationData = [],
  exercise
}) => (
    <BuilderWizardStep>
      {evaluationData.map(evaluationCategory => (
        <Wrapper key={evaluationCategory.id}>
          <ScoresTable
            register={register}
            scoreItems={exercise.scoreItems}
            title={evaluationCategory.name}
            lines={evaluationCategory.evaluatedItem}
          />
        </Wrapper>
      ))}
    </BuilderWizardStep>
  );
