import React from "react";
import styled from "styled-components";

import "./style.css";

const HeartBar = styled.div`
  width: 0%;
  height: 20px;
  position: relative;
  background: ${props => props.theme.palette[props.tint]};
  -webkit-animation: anim0to100 ${props => props.frame}s infinite;
  animation: anim0to100 ${props => props.frame}s infinite;
`;

const HeartBeat = ({ heartRate = 1, rating = "ok" }) => {
  const stateColor = {
    ok: "successColor",
    warning: "alertColor",
    alert: "dangerColor"
  };

  const styleGaps = position => ({
    position: "absolute",
    left: position,
    top: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    width: "10px",
    boxShadow: "2px 0px 5px 4px #000"
  });

  return (
    <div className="health-bar-glass">
      <HeartBar frame={heartRate} tint={stateColor[rating]}></HeartBar>
      <div style={styleGaps("0%")} />
      <div style={styleGaps("15%")} />
      <div style={styleGaps("30%")} />
      <div style={styleGaps("45%")} />
      <div style={styleGaps("60%")} />
      <div style={styleGaps("75%")} />
      <div style={styleGaps("90%")} />
    </div>
  );
};

export default HeartBeat;
