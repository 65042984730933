import React from "react";

import BuilderWizard from "../../components/BuilderWizard";
import { useBuilderWizardContext } from "../../contexts/BuilderWizard";

import BuilderWizardStepOneContainer from "./BuilderWizardStepOneContainer";
import BuilderWizardStepTwoContainer from "./BuilderWizardStepTwoContainer";
import BuilderWizardStepThreeContainer from "./BuilderWizardStepThreeContainer";
import BuilderWizardStepFourContainer from "./BuilderWizardStepFourContainer";
import BuilderWizardStepFiveContainer from "./BuilderWizardStepFiveContainer";
import BuilderWizardStepSixContainer from "./BuilderWizardStepSixContainer";
import BuilderWizardStepSevenContainer from "./BuilderWizardStepSevenContainer";

export default () => {
  const {
    steps,
    selectStep,
    selectedStep,
    finishBuilder,
    goToNextStep,
    goToPrevStep,
    handleSubmit,
    register,
    exercise,
    isOpenFinishModal,
    setIsOpenFinishModal,
  } = useBuilderWizardContext();

  return (
    <BuilderWizard
      steps={steps}
      onSelectStep={selectStep}
      selectedStep={selectedStep}
      goToNextStep={goToNextStep}
      goToPrevStep={goToPrevStep}
      finishBuilder={finishBuilder}
      handleSubmit={handleSubmit}
      isOpenFinishModal={isOpenFinishModal}
      setIsOpenFinishModal={setIsOpenFinishModal}
      stepsBody={[
        <BuilderWizardStepOneContainer register={register} exercise={exercise} />,
        <BuilderWizardStepTwoContainer register={register} exercise={exercise} />,
        <BuilderWizardStepThreeContainer register={register} exercise={exercise} />,
        <BuilderWizardStepFourContainer register={register} exercise={exercise} />,
        <BuilderWizardStepFiveContainer register={register} exercise={exercise} />,
        <BuilderWizardStepSixContainer register={register} exercise={exercise} />,
        <BuilderWizardStepSevenContainer register={register} exercise={exercise} />
      ]}
    />
  );
};
