import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const GridRow = ({ children }) => <Wrapper>{children}</Wrapper>;

export default GridRow;
