import React from "react";
import styled from "styled-components";

import StyledIcon from "../Icon";

const Icon = styled(StyledIcon)`
  width: ${props => props.size || "10em"};
  height: ${props => props.size || "10em"};
  fill: ${props => props.theme.palette.lightGrayColor};
`;

const DropDown = styled.section`
  flex-direction: column;
  display: flex;
  aligh-items: center;
  font-size: 13px;
  color: ${props => props.theme.palette.quaternaryTextColor};
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const DropDownBox = styled.section`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: ${props =>
    props.direction === "down" ? "30px" : `${props.length * -12}px`};
  background-color: ${props => props.theme.palette.primary};
  z-index: 10;
`;

const DropDownItem = styled.button`
  display: flex;
  justify-content: center;
  border: 1px solid transparent;
  cursor: pointer;
  z-index: 11;
  width: 100%;
  font-size: 11px;
  background-color: ${props =>
    props.active
      ? props.theme.palette.darkerColor
      : props.theme.palette.darkerGrayColor};
  color: ${props => props.theme.palette.quaternaryTextColor};
  &:hover {
    color: ${props => props.theme.palette.quaternaryTextColor};
    background-color: ${props => props.theme.palette.darkerColor};
    border: 1px solid #f2f2f2;
  }
`;

const ButtonDropDown = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  font-size: 12px;
  min-width: 70px;
  color: ${props => props.theme.palette.quaternaryTextColor};
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const Dropdown = ({
  direction,
  label,
  currentSelected,
  setCurrentSelected,
  list,
  minWidth = "50px"
}) => {
  const [dropDown, setDropDown] = React.useState(false);

  return (
    <DropDown>
      <ButtonDropDown
        minWidth={minWidth}
        onClick={() => setDropDown(!dropDown)}
      >
        {label}
        {direction === "down" && (
          <Icon size="1.5em" icon={dropDown ? "chevronUp" : "chevronDown"} />
        )}
        {direction === "up" && (
          <Icon size="1.5em" icon={dropDown ? "chevronDown" : "chevronUp"} />
        )}
      </ButtonDropDown>
      {dropDown && (
        <DropDownBox direction={direction} length={list.length}>
          {list.map(item => (
            <DropDownItem
              key={item.id}
              active={item.value === currentSelected}
              onClick={() => {
                setDropDown(false);
                setCurrentSelected(item.value);
              }}
            >
              {item.name}
            </DropDownItem>
          ))}
        </DropDownBox>
      )}
    </DropDown>
  );
};

export default Dropdown;
