import React from "react";
import styled from "styled-components";

import { DEFAULT_PADDING } from "../../settings";

import useOnChange from "../Input/hooks/useOnChange";

const Wrapper = styled.label`
  ${props => !props.inline && 'display: block;'}
  line-height: 1.9;

  position: relative;
  padding-left: 25px;
  padding-right: ${DEFAULT_PADDING};
  cursor: pointer;
  color: ${(props) => props.theme.palette[props.tintText]};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :hover input ~ span {
    background-color: #ccc;
  }
  input:checked ~ span {
    background-color: ${(props) =>
    props.preset === "simulator" ? "transparent" : "white"};
    border: 2px solid ${(props) => props.theme.palette[props.tint]};
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => props.theme.palette[props.tint]};
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const CustomInput = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid ${(props) => props.theme.palette.sencodaryTextColor};
  border-radius: 50%;

  :after {
    content: "";
    position: absolute;
    display: none;
  }
  ${props => !props.inline && 'transform: translate(0px, 5px);'}
`;

const InputRadio = React.forwardRef(
  (
    {
      title,
      inline = true,
      className,
      tint = "primaryColor",
      tintText = "secondaryTextColor",
      preset = "default",
      onChange,
      ...rest
    },
    ref
  ) => {
    const { newValue, onChangeHandler } = useOnChange(onChange);
    return (
      <Wrapper className={className} inline={inline} tint={tint} tintText={tintText} preset={preset}>
        <Input
          ref={ref}
          type="radio"
          value={newValue}
          onChange={onChangeHandler}
          {...rest}
        />
        <CustomInput preset={preset} inline={inline} />
        {title}
      </Wrapper>
    );
  }
);

export default InputRadio;
