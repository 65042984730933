import React, { forwardRef } from "react";
import styled from "styled-components";

import { palette } from "../../settings";

import useOnChange from "../Input/hooks/useOnChange";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Text = styled.h4`
  padding-bottom: 10px;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.theme.palette.secondaryTextColor};
`;

const SliderInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  opacity: 1;
  outline: none;
  margin: 0 auto;
  border-radius: 5px;

  &::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background: ${(props) => props.theme.palette.primaryColor};
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 3px solid #ffffff;
  }

  &::-moz-range-thumb {
    appearance: none;
    -webkit-appearance: none;
    background: ${(props) => props.theme.palette.primaryColor};
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 3px solid #ffffff;
  }
`;

const active = palette.primaryColor;
const inactive = palette.quaternaryTextColor;

export default forwardRef(({
  initialSize = 50,
  min = 0,
  max = 100,
  step = 1,
  onChange,
  ...rest
}, ref) => {
  const { newValue, onChangeHandler } = useOnChange(onChange, initialSize);

  const progress = (newValue / max) * 100 + "%";
  let background = `linear-gradient(90deg, ${active} 0% ${progress}, ${inactive} ${progress} 100%)`;

  const handleChange = (e) => {
    onChangeHandler(e);
    const value = e.target.value;
    const progress = (value / max) * 100 + "%";
    background = `linear-gradient(90deg, ${active} 0% ${progress}%, ${inactive} ${progress}% 100%)`;
  };

  return (
    <Wrapper>
      <Text> {newValue} Minutos</Text>
      <SliderInput
        ref={ref}
        type="range"
        min={min}
        max={max}
        value={newValue}
        step={step}
        onChange={handleChange}
        style={{ background }}
        {...rest}
      />
    </Wrapper>
  );
});
