import React from "react";
import { useHistory } from "react-router-dom";
import UserDisconnectedAlertModal from "../../components/UserDisconnectedAlertModal";

import { useAuthContext } from "../../contexts/Auth";
import { useUserDisconnectedAlertModalContext } from "../../contexts/UserDisconnectedAlertModal";

import routes from "../../routes";

const UserDisconnectedAlertModalContainer = () => {
  const { logout } = useAuthContext();
  const { isOpen, setIsOpen } = useUserDisconnectedAlertModalContext();

  const history = useHistory();

  const onCancel = () => {
    setIsOpen(false);
  };
  const onConfirm = () => {
    setIsOpen(false);
    logout();
    history.push(routes.LOGIN);
  };

  return (
    <UserDisconnectedAlertModal
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

export default UserDisconnectedAlertModalContainer;
