import React from "react";

import AlertModal from "../AlertModal";

const UserDisconnectedAlertModal = (props) => {
  return (
    <AlertModal
      title="Você foi desconectado"
      subtitle="Efetue login novamente"
      {...props}
    />
  );
};

export default UserDisconnectedAlertModal;
