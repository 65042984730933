import { createGlobalStyle } from "styled-components";

import Catamaran from "../../assets/fonts/Catamaran-Regular.ttf";

const GlobalStyles = createGlobalStyle`
	@font-face {
		font-family: 'Catamaran';
		src: local('Catamaran'),url(${Catamaran}), format("ttf");
	};
	* {
		box-sizing: border-box;
	};
	*, html, body {
		font-family: "Catamaran", "Open Sans", sans-serif;
		font-size: 14px;
	}
	body {
		padding: 0;
		margin: 0;
		background-color: ${(props) => props.theme.palette.defaultColor};
	};
	.inputIconRight {
		position: absolute; float:left; right:12px; top:13px;
	}
`;

export default GlobalStyles;
