import React from "react";
import styled from "styled-components";

const SVG = styled.svg`
  fill: ${(props) => props.color || props.theme.palette.initialColor};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const Title = styled.p`
  ${(props) => {
    if (props.orientation === "vertical") {
      return `
        margin: 10px 0 0 0;
      `;
    }
    if (props.orientation === "horizontal") {
      return `
        margin: 0 0 0 10px;
      `;
    }
  }}
`;

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${(props) => {
    if (props.orientation === "vertical") {
      return `
        flex-direction: column;
      `;
    }
    if (props.orientation === "horizontal") {
      return `
        flex-direction: row;
      `;
    }
  }}
`;

const Loader = ({
  title = "Carregando",
  color,
  size = 30,
  orientation = "vertical",
  className,
}) => (
  <Wrapper orientation={orientation} className={className}>
    <SVG size={size} viewBox="0 0 34 34" color={color}>
      <path
        opacity=".25"
        d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
      />
      <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 16 16"
          to="360 16 16"
          dur="0.8s"
          repeatCount="indefinite"
        />
      </path>
    </SVG>
    {title && <Title orientation={orientation}>{title}</Title>}
  </Wrapper>
);

export default Loader;
