import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: space-between;
`;

const ContentHeaderRow = ({children}) => <Wrapper>{children}</Wrapper>;

export default ContentHeaderRow;