import React from "react";
import styled from "styled-components";

import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "../Sidebar";

import { DEFAULT_PADDING } from "../../settings";

const Wrapper = styled.div``;

const HeaderWrapper = styled.div`
  display: flex;
`;

const FooterWapper = styled.div`
  background-color: #fff;
  position: absolute;
  left: 0;
  width: 100%;
`;

const SidebarWrapper = styled.div`
  background-color: #fff;
  min-width: 350px;
`;

const MainWrapper = styled.div`
  flex: 1;
`;

const Main = styled.main`
  min-height: 900px;
  padding-left: ${DEFAULT_PADDING};
  padding-right: ${DEFAULT_PADDING};
`;

const View = ({
  user = {},
  isAuthenticated = localStorage.getItem('xt-user') !== null,
  matchRoles,
  handleClickMenuItem,
  hidden,
  setHidden,
  logout,
  children,
  subMenu,
  newsletterRegisterEmailField,
  onSubmitNewsletterForm,
  newsletterFormErrors,
  newsletterFormValues = {},
}) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <SidebarWrapper>
          <Sidebar
            isAuthenticated={isAuthenticated}
            matchRoles={matchRoles}
            handleClickMenuItem={handleClickMenuItem}
            hidden={hidden}
            setHidden={setHidden}
          />
        </SidebarWrapper>
        <MainWrapper>
          <Header
            user={user}
            isAuthenticated={isAuthenticated}
            logout={logout}
          />
          {subMenu}
          <Main>{children}</Main>
        </MainWrapper>
      </HeaderWrapper>
      <FooterWapper>
        <Footer
          newsletterRegisterEmailField={newsletterRegisterEmailField}
          onSubmitNewsletterForm={onSubmitNewsletterForm}
          newsletterFormErrors={newsletterFormErrors}
          newsletterFormValues={newsletterFormValues}
        />
      </FooterWapper>
    </Wrapper>
  );
};

export default View;
