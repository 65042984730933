import React from "react";
import styled from "styled-components";
import { confirmAlert } from "react-confirm-alert";

import Card from "../../Card";
import {
  InputModalNumber,
  InputModalSlider,
  InputModalSelect,
  InputModalSync
} from "../modals/inputModals";

import { DEFAULT_PADDING } from "../../../settings";

import "./overlayer.css";

const Wrapper = styled.header`
  position: relative;
  height: 75px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: ${DEFAULT_PADDING};
  background-color: ${props => props.theme.palette.darkerColor};
`;
const Inputs = styled.section`
  display: flex;
  flex: 1;
  width: 80%;
  flex-direction: row;
  align-items: center;
`;

const CardSection = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-right: 10px;
  align-items: center;
  background-color: transparent;
  padding: 0;
  border-width: 0;
  border-radius: 5px;
  cursor: pointer;
`;

const CardItem = styled.section`
  display: flex;
  height: ${props => props.size || "50px"};
  width: ${props => props.size || "81px"};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 10px;
  font-align: center;
`;

const CardValue = styled.h1`
  display: flex;
  font-size: ${props => props.fontSize || "20px"};
  font-align: center;
  color: ${props => props.theme.palette.quarterTextColor};
  padding: 0;
  margin: 0;
`;

const SimulatorBottomDisplay = ({
  parameters,
  setParameters,
  syncTrigger = {},
  onSubmit,
  builderMode,
  ...props
}) => {
  const [mode, setMode] = React.useState("PCV");
  const [modalsInput, setModalsInput] = React.useState({});

  const openDialog = value => {
    confirmAlert({
      title: "Confimar mudança de modo",
      message: "Tem certeza que deseja mudar de modo?",
      buttons: [
        {
          label: "SIM",
          onClick: () => {
            setMode(value);
            setParameters(null, null, value);
          }
        },
        {
          label: "NÃO",
          onClick: () => null
        }
      ],
      overlayClassName: "overlay-custom-class-name"
    });
  };

  return (
    <Wrapper {...props}>
      <div style={{ width: "20%", display: "flex", flexFlow: "row" }}>
        <CardSection>
          <Card palette="simulator" active={mode === "PCV"} padding={false}>
            <CardItem size={"40px"} onClick={() => openDialog("PCV")}>
              <CardValue fontSize={"13px"}>PCV</CardValue>
            </CardItem>
          </Card>
        </CardSection>
        <CardSection>
          <Card palette="simulator" active={mode === "VCV"} padding={false}>
            <CardItem size={"40px"} onClick={() => openDialog("VCV")}>
              <CardValue fontSize={"13px"}>VCV</CardValue>
            </CardItem>
          </Card>
        </CardSection>{" "}
        <CardSection>
          <Card palette="simulator" active={mode === "PSV"} padding={false}>
            <CardItem size={"40px"} onClick={() => openDialog("PSV")}>
              <CardValue fontSize={"13px"}>PSV</CardValue>
            </CardItem>
          </Card>
        </CardSection>
        <CardSection>
          <Card palette="simulator" active={mode === "CPAP"} padding={false}>
            <CardItem size={"40px"} onClick={() => openDialog("CPAP")}>
              <CardValue fontSize={"13px"}>CPAP</CardValue>
            </CardItem>
          </Card>
        </CardSection>
      </div>
      <Inputs>
        <CardSection>
          {/* card 1 */}
          {mode === "PCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ Δp: true })}>
                Δp (cmH²O)
                <CardValue>{parameters.pressure}</CardValue>
              </CardItem>
              {modalsInput.Δp && (
                <InputModalSlider
                  close={() => setModalsInput({ Δp: false })}
                  title="Acima do peep"
                  valueLabel="cmH²O"
                  domain={[4, 120]}
                  ticks={21}
                  value={parameters.pressure}
                  submitValue={value => setParameters("pressure", value, mode)}
                />
              )}
            </Card>
          )}
          {mode === "VCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ volume: true })}>
                Volume (ml)
                <CardValue>{parameters.volume}</CardValue>
              </CardItem>
              {modalsInput.volume && (
                <InputModalNumber
                  close={() => setModalsInput({ volume: false })}
                  title="Volume (ml)"
                  valueLabel="milímetros"
                  minValue={60}
                  maxValue={2000}
                  value={parameters.volume}
                  setValue={value => setParameters("volume", value, mode)}
                  incrementValue={10}
                />
              )}
            </Card>
          )}
          {mode === "PSV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ PS: true })}>
                PS (cmH²O)
                <CardValue>{parameters.supportPressure}</CardValue>
              </CardItem>
              {modalsInput.PS && (
                <InputModalSlider
                  close={() => setModalsInput({ PS: false })}
                  title="PS (suporte pressórico)"
                  valueLabel="cmH²O"
                  domain={[4, 50]}
                  ticks={21}
                  value={parameters.supportPressure}
                  submitValue={value =>
                    setParameters("supportPressure", value, mode)
                  }
                />
              )}
            </Card>
          )}
          {mode === "CPAP" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ PEEP: true })}>
                PEEP (cmH²O)
                <CardValue>{parameters.peep}</CardValue>
              </CardItem>
              {modalsInput.PEEP && (
                <InputModalSlider
                  close={() => setModalsInput({ PEEP: false })}
                  title="PEEP"
                  valueLabel="cmH²O"
                  domain={[0, 45]}
                  ticks={21}
                  value={parameters.peep}
                  submitValue={value => setParameters("peep", value, mode)}
                />
              )}
            </Card>
          )}
        </CardSection>
        <CardSection>
          {/* card 2 */}
          {mode === "PCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ f: true })}>
                f (rpm)
                <CardValue>{parameters.frequency}</CardValue>
              </CardItem>
              {modalsInput.f && (
                <InputModalSlider
                  close={() => setModalsInput({ f: false })}
                  title="Frequência Respiratória"
                  valueLabel="rpm"
                  domain={[4, 80]}
                  ticks={21}
                  value={parameters.frequency}
                  submitValue={value => setParameters("frequency", value, mode)}
                />
              )}
            </Card>
          )}
          {mode === "VCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ f: true })}>
                f (rpm)
                <CardValue>{parameters.frequency}</CardValue>
              </CardItem>
              {modalsInput.f && (
                <InputModalSlider
                  close={() => setModalsInput({ f: false })}
                  title="Frequência Respiratória"
                  valueLabel="rpm"
                  domain={[4, 80]}
                  ticks={21}
                  value={parameters.frequency}
                  submitValue={value => setParameters("frequency", value, mode)}
                />
              )}
            </Card>
          )}
          {mode === "PSV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ T_Subida: true })}>
                T. Subida (s)
                <CardValue>{parameters.riseTime.toFixed(2)}</CardValue>
              </CardItem>
              {modalsInput.T_Subida && (
                <InputModalNumber
                  close={() => setModalsInput({ T_Subida: false })}
                  title="T. Subida"
                  valueLabel="segundos"
                  minValue={0.01}
                  maxValue={0.5}
                  value={parameters.riseTime}
                  setValue={value => setParameters("riseTime", value, mode)}
                  incrementValue={0.01}
                  fixedValue={2}
                />
              )}
            </Card>
          )}
          {mode === "CPAP" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ FiO2: true })}>
                FiO2 (%)
                <CardValue>{parameters.fio2}</CardValue>
              </CardItem>
              {modalsInput.FiO2 && (
                <InputModalSlider
                  close={() => setModalsInput({ FiO2: false })}
                  title="FiO2"
                  valueLabel="%"
                  domain={[21, 100]}
                  ticks={20}
                  value={parameters.fio2}
                  submitValue={value => setParameters("fio2", value, mode)}
                />
              )}
            </Card>
          )}
        </CardSection>
        <CardSection>
          {/* card 3 */}
          {mode === "PCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ T_Insp: true })}>
                T. Insp. (s)
                <CardValue>{parameters.inspiratoryTime.toFixed(2)}</CardValue>
              </CardItem>
              {modalsInput.T_Insp && (
                <InputModalNumber
                  close={() => setModalsInput({ T_Insp: false })}
                  title="T. Insp"
                  valueLabel="segundos"
                  minValue={0.3}
                  maxValue={3}
                  value={parameters.inspiratoryTime}
                  setValue={value =>
                    setParameters("inspiratoryTime", value, mode)
                  }
                  incrementValue={0.05}
                  fixedValue={2}
                />
              )}
            </Card>
          )}
          {mode === "VCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ Fluxo: true })}>
                Fluxo (l/min)
                <CardValue>{parameters.flow}</CardValue>
              </CardItem>
              {modalsInput.Fluxo && (
                <InputModalNumber
                  close={() => setModalsInput({ Fluxo: false })}
                  title="Fluxo (l/min)"
                  valueLabel="l/min"
                  minValue={1}
                  maxValue={120}
                  value={parameters.flow}
                  setValue={value => setParameters("flow", value, mode)}
                  incrementValue={1}
                />
              )}
            </Card>
          )}
          {mode === "PSV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ fc: true })}>
                Fim do ciclo (%)
                <CardValue>{parameters.endOfCycle}</CardValue>
              </CardItem>
              {modalsInput.fc && (
                <InputModalSlider
                  close={() => setModalsInput({ fc: false })}
                  title="Fim do ciclo (%)"
                  valueLabel="%"
                  value={parameters.endOfCycle}
                  setValue={value => setParameters("endOfCycle", value, mode)}
                  domain={[10, 80]}
                  ticks={20}
                />
              )}
            </Card>
          )}
          {mode === "CPAP" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ Sensibilidade: true })}>
                Sensibilidade
                <CardValue>{syncTrigger.value}</CardValue>
              </CardItem>
              {modalsInput.Sensibilidade && (
                <InputModalSync
                  close={() => setModalsInput({ Sensibilidade: false })}
                  title="Sensibilidade"
                  valueLabel=""
                  type={syncTrigger.type}
                  minValue={syncTrigger.min}
                  maxValue={syncTrigger.max}
                  value={syncTrigger.value}
                  setValue={(value, type) =>
                    setParameters("syncTrigger", value, mode)
                  }
                  incrementValue={0.5}
                />
              )}
            </Card>
          )}
        </CardSection>
        <CardSection>
          {/* card 4 */}
          {mode === "PCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ T_Subida: true })}>
                T. Subida (s)
                <CardValue>{parameters.riseTime.toFixed(2)}</CardValue>
              </CardItem>
              {modalsInput.T_Subida && (
                <InputModalNumber
                  close={() => setModalsInput({ T_Subida: false })}
                  title="T. Subida"
                  valueLabel="segundos"
                  minValue={0.01}
                  maxValue={0.5}
                  value={parameters.riseTime}
                  setValue={value => setParameters("riseTime", value, mode)}
                  incrementValue={0.01}
                  fixedValue={2}
                />
              )}
            </Card>
          )}
          {mode === "VCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ T_Insp: true })}>
                T. Insp. (s)
                <CardValue>{parameters.inspiratoryTime.toFixed(2)}</CardValue>
              </CardItem>
              {modalsInput.T_Insp && (
                <InputModalSelect
                  close={() => setModalsInput({ T_Insp: false })}
                  title="T. Insp"
                  valueLabel="segundos"
                  minValue={0.3}
                  maxValue={3}
                  value={parameters.inspiratoryTime.toFixed(2)}
                  setValue={value =>
                    setParameters("inspiratoryTime", value, mode)
                  }
                  incrementValue={0.05}
                />
              )}
            </Card>
          )}

          {mode === "PSV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ PEEP: true })}>
                PEEP (cmH²O)
                <CardValue>{parameters.peep}</CardValue>
              </CardItem>
              {modalsInput.PEEP && (
                <InputModalSlider
                  close={() => setModalsInput({ PEEP: false })}
                  title="PEEP"
                  valueLabel="cmH²O"
                  domain={[0, 45]}
                  ticks={21}
                  value={parameters.peep}
                  submitValue={value => setParameters("peep", value, mode)}
                />
              )}
            </Card>
          )}
        </CardSection>
        <CardSection>
          {/* card 5 */}
          {mode === "PCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ PEEP: true })}>
                PEEP (cmH²O)
                <CardValue>{parameters.peep}</CardValue>
              </CardItem>
              {modalsInput.PEEP && (
                <InputModalSlider
                  close={() => setModalsInput({ PEEP: false })}
                  title="PEEP"
                  valueLabel="cmH²O"
                  domain={[0, 45]}
                  ticks={21}
                  value={parameters.peep}
                  submitValue={value => setParameters("peep", value, mode)}
                />
              )}
            </Card>
          )}
          {mode === "VCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ PEEP: true })}>
                PEEP (cmH²O)
                <CardValue>{parameters.peep}</CardValue>
              </CardItem>
              {modalsInput.PEEP && (
                <InputModalSlider
                  close={() => setModalsInput({ PEEP: false })}
                  title="PEEP"
                  valueLabel="cmH²O"
                  domain={[0, 45]}
                  ticks={21}
                  value={parameters.peep}
                  submitValue={value => setParameters("peep", value, mode)}
                />
              )}
            </Card>
          )}

          {mode === "PSV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ FiO2: true })}>
                FiO2 (%)
                <CardValue>{parameters.fio2}</CardValue>
              </CardItem>
              {modalsInput.FiO2 && (
                <InputModalSlider
                  close={() => setModalsInput({ FiO2: false })}
                  title="FiO2"
                  valueLabel="%"
                  domain={[21, 100]}
                  ticks={20}
                  value={parameters.fio2}
                  submitValue={value => setParameters("fio2", value, mode)}
                />
              )}
            </Card>
          )}
        </CardSection>
        <CardSection>
          {/* card 6 */}
          {mode === "PCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ FiO2: true })}>
                FiO2 (%)
                <CardValue>{parameters.fio2}</CardValue>
              </CardItem>
              {modalsInput.FiO2 && (
                <InputModalSlider
                  close={() => setModalsInput({ FiO2: false })}
                  title="FiO2"
                  valueLabel="%"
                  domain={[21, 100]}
                  ticks={20}
                  value={parameters.fio2}
                  submitValue={value => setParameters("fio2", value, mode)}
                />
              )}
            </Card>
          )}
          {mode === "VCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ FiO2: true })}>
                FiO2 (%)
                <CardValue>{parameters.fio2}</CardValue>
              </CardItem>
              {modalsInput.FiO2 && (
                <InputModalSlider
                  close={() => setModalsInput({ FiO2: false })}
                  title="FiO2"
                  valueLabel="%"
                  domain={[21, 100]}
                  ticks={20}
                  value={parameters.fio2}
                  submitValue={value => setParameters("fio2", value, mode)}
                />
              )}
            </Card>
          )}

          {mode === "PSV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ Sensibilidade: true })}>
                Sensibilidade
                <CardValue>{syncTrigger.value}</CardValue>
              </CardItem>
              {modalsInput.Sensibilidade && (
                <InputModalSync
                  close={() => setModalsInput({ Sensibilidade: false })}
                  title="Sensibilidade"
                  valueLabel=""
                  type={syncTrigger.type}
                  minValue={syncTrigger.min}
                  maxValue={syncTrigger.max}
                  value={syncTrigger.value}
                  setValue={(value, type) =>
                    setParameters("syncTrigger", value, mode)
                  }
                  incrementValue={0.5}
                />
              )}
            </Card>
          )}
        </CardSection>
        <CardSection>
          {/* card 7 */}
          {mode === "PCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ Pausa_Insp: true })}>
                Pausa Insp.(s)
                <CardValue>{parameters.inspiratoryPause.toFixed(1)}</CardValue>
              </CardItem>
              {modalsInput.Pausa_Insp && (
                <InputModalNumber
                  close={() => setModalsInput({ Pausa_Insp: false })}
                  title="Pausa Insp"
                  valueLabel="segundos"
                  minValue={0}
                  maxValue={2}
                  value={parameters.inspiratoryPause}
                  setValue={value =>
                    setParameters("inspiratoryPause", value, mode)
                  }
                  incrementValue={0.1}
                />
              )}
            </Card>
          )}
          {mode === "VCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ Pausa_Insp: true })}>
                Pausa Insp.(s)
                <CardValue>{parameters.inspiratoryPause.toFixed(1)}</CardValue>
              </CardItem>
              {modalsInput.Pausa_Insp && (
                <InputModalNumber
                  close={() => setModalsInput({ Pausa_Insp: false })}
                  title="Pausa Insp"
                  valueLabel="segundos"
                  minValue={0}
                  maxValue={2}
                  value={parameters.inspiratoryPause}
                  setValue={value =>
                    setParameters("inspiratoryPause", value, mode)
                  }
                  incrementValue={0.1}
                />
              )}
            </Card>
          )}
        </CardSection>
        <CardSection>
          {/* card 8 */}
          {mode === "PCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ Pausa_Exp: true })}>
                Pausa Exp.(s)
                <CardValue>{parameters.expiratoryPause.toFixed(1)}</CardValue>
              </CardItem>
              {modalsInput.Pausa_Exp && (
                <InputModalNumber
                  close={() => setModalsInput({ Pausa_Exp: false })}
                  title="Pausa Exp"
                  valueLabel="segundos"
                  minValue={0}
                  maxValue={4}
                  value={parameters.expiratoryPause}
                  setValue={value =>
                    setParameters("expiratoryPause", value, mode)
                  }
                  incrementValue={0.1}
                />
              )}
            </Card>
          )}
          {mode === "VCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ Pausa_Exp: true })}>
                Pausa Exp.(s)
                <CardValue>{parameters.expiratoryPause.toFixed(1)}</CardValue>
              </CardItem>
              {modalsInput.Pausa_Exp && (
                <InputModalNumber
                  close={() => setModalsInput({ Pausa_Exp: false })}
                  title="Pausa Exp"
                  valueLabel="segundos"
                  minValue={0}
                  maxValue={4}
                  value={parameters.expiratoryPause}
                  setValue={value =>
                    setParameters("expiratoryPause", value, mode)
                  }
                  incrementValue={0.1}
                />
              )}
            </Card>
          )}
        </CardSection>
        <CardSection>
          {/* card 9 */}
          {mode === "PCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ Sensibilidade: true })}>
                Sensibilidade
                <CardValue>{syncTrigger.value}</CardValue>
              </CardItem>
              {modalsInput.Sensibilidade && (
                <InputModalSync
                  close={() => setModalsInput({ Sensibilidade: false })}
                  title="Sensibilidade"
                  valueLabel=""
                  type={syncTrigger.type}
                  minValue={syncTrigger.min}
                  maxValue={syncTrigger.max}
                  value={syncTrigger.value}
                  setValue={(value, type) =>
                    setParameters("syncTrigger", value, mode)
                  }
                  incrementValue={0.5}
                />
              )}
            </Card>
          )}
          {mode === "VCV" && (
            <Card palette="simulator" padding={false}>
              <CardItem onClick={() => setModalsInput({ Sensibilidade: true })}>
                Sensibilidade
                <CardValue>{syncTrigger.value}</CardValue>
              </CardItem>
              {modalsInput.Sensibilidade && (
                <InputModalSync
                  close={() => setModalsInput({ Sensibilidade: false })}
                  title="Sensibilidade"
                  valueLabel=""
                  type={syncTrigger.type}
                  minValue={syncTrigger.min}
                  maxValue={syncTrigger.max}
                  value={syncTrigger.value}
                  setValue={(value, type) =>
                    setParameters("syncTrigger", value, mode)
                  }
                  incrementValue={0.5}
                />
              )}
            </Card>
          )}
        </CardSection>
      </Inputs>
    </Wrapper>
  );
};
export default SimulatorBottomDisplay;
