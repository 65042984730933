import React from "react";
import styled from "styled-components";

const Wrapper = styled.h4`
  margin: 0;
  color: ${(props) => props.theme.palette.secondaryTextColor};
`;

const ModalSubtitle = (props) => <Wrapper {...props} />;

export default ModalSubtitle;
