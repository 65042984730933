import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import ButtonWithIcon from "../ButtonWithIcon";
import Dropdown from "../Dropdown";
import StyledIcon from "../Icon";

import AlertModal from "../AlertModal";
import Grid from "../Grid";
import Column from "../GridColumn";
import Row from "../GridRow";

import SimulatorView from "../Simulator/SimulatorView";
import { values as simulatorDefault } from "../Simulator/SimulatorView/simulatorDefaultValues";

const BuilderWizardBlackStep = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #484848;
  color: #fff;
  width: 100%;
`;

const CardContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  padding: 10px;
  background-color: ${props => props.theme.palette.darkerColor};
  font-size: 18px;
  color: ${props => props.theme.palette[props.tint]};
`;

const InputsContainers = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  background-color: ${props => props.theme.palette.darkerColor};
  font-size: 18px;
  color: ${props => props.theme.palette[props.tint]};
`;

const ButtonContainer = styled.div`
  margin-right: 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 50%;
  height: 2rem;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  overflow: hidden;
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const ButtonSelect = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.7rem;
  background-color: ${props =>
    props.isActive
      ? props.theme.palette.darkerColor
      : props.theme.palette.darkerGrayColor};
  &:active {
    transform: scale(0.6);
  }
`;

const ButtonUpDown = styled.div`
  border-radius: 50px;
  margin: 0 5px;
  cursor: pointer;
  background-color: ${props => props.theme.palette.darkerColor};
  &:active {
    border: 1 solid #fff;
    opacity: 0.5;
    transform: scale(0.6);
  }
`;

const IconInput = styled(StyledIcon)`
  fill: #ffffff;
  &:hover {
    fill: ${props =>
      props.isDown
        ? props.theme.palette.dangerColor
        : props.theme.palette.infoColor};
  }
`;

const fieldStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "10px",
  fontSize: "0.7rem"
};

export default ({ register, exercise }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [patient, setPatient] = useState({ ...simulatorDefault.patient });
  let history = useHistory();

  const setSimulator = simulator => {
    console.log(simulator);
  };

  const setPatientParameters = (key, value) => {
    let newParameters = patient.parameters;
    newParameters[key] = value;
    setPatient({ ...patient, parameters: newParameters });
  };

  return (
    <BuilderWizardBlackStep>
      <Grid>
        <Row>
          <Column medium="5">
            <CardContainer>
              <Dropdown
                direction="down"
                label={"Condições Clínicas"}
                currentSelected={"Condições Clínicas"}
                setCurrentSelected={value => null}
                list={[
                  {
                    id: 1,
                    name: "Condições Clínicas",
                    value: "Condições Clínicas"
                  }
                ]}
              />
              <Dropdown
                direction="down"
                label={"Distúrbios Metabólicos"}
                currentSelected={"Distúrbios Metabólicos"}
                setCurrentSelected={value => null}
                list={[
                  {
                    id: 1,
                    name: "Distúrbios Metabólicos",
                    value: "Distúrbios Metabólicos"
                  }
                ]}
              />
              <Dropdown
                direction="down"
                label={"Altitude"}
                currentSelected={"Altitude"}
                setCurrentSelected={value => null}
                list={[{ id: 1, name: "Altitude", value: "Altitude" }]}
              />
            </CardContainer>
            <CardContainer>
              <div style={{ width: "100%" }}>
                Paciente
                <InputsContainers>
                  <div style={{ width: "49%" }}>
                    Pulmonary Physiology
                    <div style={fieldStyle}>
                      Rva (cmH2O/l/s)
                      <InputWrapper>
                        <ButtonUpDown>
                          <IconInput
                            icon="chevronDown"
                            isDown
                            size={20}
                            onClick={() =>
                              setPatientParameters(
                                "airwayResistance",
                                patient.parameters.airwayResistance - 1
                              )
                            }
                          />
                        </ButtonUpDown>
                        {patient.parameters.airwayResistance}
                        <ButtonUpDown>
                          <IconInput
                            icon="chevronUp"
                            size={20}
                            onClick={() =>
                              setPatientParameters(
                                "airwayResistance",
                                patient.parameters.airwayResistance + 1
                              )
                            }
                          />
                        </ButtonUpDown>
                      </InputWrapper>
                    </div>
                    <div style={fieldStyle}>
                      Cst (ml/cmH2O)
                      <InputWrapper>
                        <ButtonUpDown>
                          <IconInput
                            icon="chevronDown"
                            isDown
                            size={20}
                            onClick={() =>
                              setPatientParameters(
                                "lungCompliance",
                                patient.parameters.lungCompliance - 1
                              )
                            }
                          />
                        </ButtonUpDown>
                        {patient.parameters.lungCompliance}
                        <ButtonUpDown>
                          <IconInput
                            icon="chevronUp"
                            size={20}
                            onClick={() =>
                              setPatientParameters(
                                "lungCompliance",
                                patient.parameters.lungCompliance + 1
                              )
                            }
                          />
                        </ButtonUpDown>
                      </InputWrapper>
                    </div>
                    <div style={fieldStyle}>
                      VD/VT
                      <InputWrapper>
                        <ButtonUpDown>
                          <IconInput
                            icon="chevronDown"
                            isDown
                            size={20}
                            onClick={() =>
                              setPatientParameters(
                                "deadSpace",
                                patient.parameters.deadSpace - 1
                              )
                            }
                          />
                        </ButtonUpDown>
                        {patient.parameters.deadSpace}
                        <ButtonUpDown>
                          <IconInput
                            icon="chevronUp"
                            size={20}
                            onClick={() =>
                              setPatientParameters(
                                "deadSpace",
                                patient.parameters.deadSpace + 1
                              )
                            }
                          />
                        </ButtonUpDown>
                      </InputWrapper>
                    </div>
                    <div style={fieldStyle}>
                      Shunt Effect (%)
                      <InputWrapper>
                        <ButtonUpDown>
                          <IconInput
                            icon="chevronDown"
                            isDown
                            size={20}
                            onClick={() =>
                              setPatientParameters(
                                "shuntEffect",
                                patient.parameters.shuntEffect - 1
                              )
                            }
                          />
                        </ButtonUpDown>
                        {patient.parameters.shuntEffect}
                        <ButtonUpDown>
                          <IconInput
                            icon="chevronUp"
                            size={20}
                            onClick={() =>
                              setPatientParameters(
                                "shuntEffect",
                                patient.parameters.shuntEffect + 1
                              )
                            }
                          />
                        </ButtonUpDown>
                      </InputWrapper>
                    </div>
                    <div style={fieldStyle}>
                      Efeito da PEEP
                      <InputWrapper>
                        <ButtonSelect isActive>Ativo</ButtonSelect>
                        <ButtonSelect>Inativo</ButtonSelect>
                      </InputWrapper>
                    </div>
                  </div>
                  <div style={{ width: "49%" }}>
                    Demographic Data
                    <div style={fieldStyle}>
                      Sexo
                      <InputWrapper>
                        <ButtonSelect
                          isActive={patient.parameters.gender === "MALE"}
                          onClick={() => setPatientParameters("gender", "MALE")}
                        >
                          Masc
                        </ButtonSelect>
                        <ButtonSelect
                          isActive={patient.parameters.gender === "FEMALE"}
                          onClick={() =>
                            setPatientParameters("gender", "FEMALE")
                          }
                        >
                          Fem
                        </ButtonSelect>
                      </InputWrapper>
                    </div>
                    <div style={fieldStyle}>
                      Age (years)
                      <InputWrapper>
                        <ButtonUpDown>
                          <IconInput icon="chevronDown" isDown size={20} />
                        </ButtonUpDown>
                        {patient.parameters.age}
                        <ButtonUpDown>
                          <IconInput icon="chevronUp" size={20} />
                        </ButtonUpDown>
                      </InputWrapper>
                    </div>
                    <div style={fieldStyle}>
                      Height (m)
                      <InputWrapper>
                        <ButtonUpDown>
                          <IconInput icon="chevronDown" isDown size={20} />
                        </ButtonUpDown>
                        {patient.parameters.height}
                        <ButtonUpDown>
                          <IconInput icon="chevronUp" size={20} />
                        </ButtonUpDown>
                      </InputWrapper>
                    </div>
                    <div style={fieldStyle}>
                      Weight (Kg)
                      <InputWrapper>
                        <ButtonUpDown>
                          <IconInput icon="chevronDown" isDown size={20} />
                        </ButtonUpDown>
                        {patient.parameters.weight}
                        <ButtonUpDown>
                          <IconInput icon="chevronUp" size={20} />
                        </ButtonUpDown>
                      </InputWrapper>
                    </div>
                    <div style={fieldStyle}>
                      IBW (Kg)
                      <InputWrapper>
                        <ButtonUpDown>
                          <IconInput icon="chevronDown" isDown size={20} />
                        </ButtonUpDown>
                        {patient.parameters.idealBodyWeight}
                        <ButtonUpDown>
                          <IconInput icon="chevronUp" size={20} />
                        </ButtonUpDown>
                      </InputWrapper>
                    </div>
                  </div>
                </InputsContainers>
                <InputsContainers>
                  <div style={{ width: "100%" }}>
                    Muscle Effort
                    <Row>
                      <div style={{ width: "49%" }}>
                        <div style={fieldStyle}>
                          Pmus (cmH2O)
                          <InputWrapper>
                            <ButtonUpDown>
                              <IconInput icon="chevronDown" isDown size={20} />
                            </ButtonUpDown>
                            {"Value"}
                            <ButtonUpDown>
                              <IconInput icon="chevronUp" size={20} />
                            </ButtonUpDown>
                          </InputWrapper>
                        </div>
                      </div>

                      <div style={{ width: "49%" }}>
                        <div style={fieldStyle}>
                          Temp (ºC)
                          <InputWrapper>
                            <ButtonUpDown>
                              <IconInput icon="chevronDown" isDown size={20} />
                            </ButtonUpDown>
                            {"Value"}
                            <ButtonUpDown>
                              <IconInput icon="chevronUp" size={20} />
                            </ButtonUpDown>
                          </InputWrapper>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div style={{ width: "49%" }}>
                        <div style={fieldStyle}>
                          RR (bpm)
                          <InputWrapper>
                            <ButtonUpDown>
                              <IconInput icon="chevronDown" isDown size={20} />
                            </ButtonUpDown>
                            {"Value"}
                            <ButtonUpDown>
                              <IconInput icon="chevronUp" size={20} />
                            </ButtonUpDown>
                          </InputWrapper>
                        </div>
                      </div>

                      <div style={{ width: "49%" }}>
                        <div style={fieldStyle}>
                          Duration (s)
                          <InputWrapper>
                            <ButtonUpDown>
                              <IconInput icon="chevronDown" isDown size={20} />
                            </ButtonUpDown>
                            {patient.parameters.duration}
                            <ButtonUpDown>
                              <IconInput icon="chevronUp" size={20} />
                            </ButtonUpDown>
                          </InputWrapper>
                        </div>
                      </div>
                    </Row>
                  </div>
                </InputsContainers>
              </div>
            </CardContainer>
          </Column>
          <Column medium="7">
            <CardContainer>
              Permições do exercício
              <ButtonContainer>
                <ButtonWithIcon
                  icon={"chevronLeft"}
                  position="left"
                  palette="darkerGray"
                  variant="filled"
                  size="tiny"
                >
                  Voltar
                </ButtonWithIcon>

                <ButtonWithIcon
                  icon={"chevronRight"}
                  position="right"
                  palette="darkerGray"
                  variant="filled"
                  size="tiny"
                >
                  Próximo passo
                </ButtonWithIcon>
              </ButtonContainer>
            </CardContainer>
            <Column medium="6">
              <CardContainer></CardContainer>
            </Column>
            <Column medium="6">
              <CardContainer></CardContainer>
            </Column>
          </Column>
        </Row>
        <Row>
          <SimulatorView
            simulatorData={{ ...simulatorDefault, ...patient }}
            setSimulatorData={setSimulator}
            isBuilder
          />
        </Row>
      </Grid>
      <AlertModal
        title="Configure a simulação"
        subtitle="Reproduza no simulador o problema descrito no cenário clínico. ao término, pressione o botão Salvar."
        isOpen={isOpen}
        onConfirmText="Iniciar a configuração"
        onConfirm={() => setIsOpen(false)}
        onCancel={() => history.push("/builder/step/2")}
        type="info"
      />
    </BuilderWizardBlackStep>
  );
};
