import React from "react";
import styled from "styled-components";

import { palette } from "../../settings";
import Modal from "../Modal";
import ModalTitle from "../ModalTitle";
import ModalSubtitle from "../ModalSubtitle";
import StyledModalBody from "../ModalBody";
import StyledModalFooter from "../ModalFooter";
import StyledButton from "../Button";

import Icon from "../Icon";

const CancelButton = styled(StyledButton)`
  margin-right: 5px;
`;

const ConfirmButton = styled(StyledButton)``;

const ModalBody = styled(StyledModalBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ModalFooter = styled(StyledModalFooter)`
  justify-content: flex-end;
`;

const AlertModal = ({ title, subtitle, onCancel, onConfirm, type = 'primary', icon = "alert", ...rest }) => {
  const colors = {
    'primary': palette.initialColor,
    'alert': palette.alertColor,
    'success': palette.successColor,
    'info': palette.infoColor,
    'danger': palette.dangerColor
  }
  return (
    <Modal
      body={
        <ModalBody>
          <Icon
            icon={icon}
            color={colors[type] || palette.initialColor}
            size={64} />
          {title && <ModalTitle>{title}</ModalTitle>}
          {subtitle && <ModalSubtitle>{subtitle}</ModalSubtitle>}
        </ModalBody>
      }
      footer={
        <ModalFooter>
          {onCancel &&
            <CancelButton size="tiny" palette={type} variant="outlined" onClick={onCancel}>
              Cancelar
            </CancelButton>}
          <ConfirmButton size="tiny" palette={type} onClick={onConfirm}>
            {rest.onConfirmText || "Ok"}
          </ConfirmButton>
        </ModalFooter>
      }
      {...rest}
    />
  );
};

export default AlertModal;
