import React from "react";
import styled from "styled-components";

const Wrapper = styled.ul`
  padding: 0;
  margin: 0;
`;

const Menu = ({ children }) => <Wrapper>{children}</Wrapper>;

export default Menu;
