import { css } from "styled-components";
import { darken } from "polished";
import { DEFAULT_PADDING } from "../../settings";

export const base = css`
  display: flex;
  justify-content: space-between;
  padding: ${DEFAULT_PADDING};
  padding-left: 90px;
  font-size: 18px;
  font-weight: 600;
  transition-delay: 100ms;
  cursor: pointer;
`;

const defaultInactive = css`
  color: ${(props) => props.theme.palette.secondaryTextColor};
  background-color: ${(props) =>
    props.subitem && props.theme.palette.quaternaryTextColor};
  ${(props) =>
    !props.disabled &&
    `
  &:hover {
    background-color: ${props.theme.palette.defaultColor};
    color: ${props.theme.palette.primaryTextColor};
  }
  `}
`;

const defaultActive = css`
  background-color: ${(props) => props.theme.palette.defaultColor};
  color: ${(props) => props.theme.palette.primaryTextColor};
  ${(props) =>
    !props.disabled &&
    `
  &:hover {
    background-color: ${darken("0.03", props.theme.palette.defaultColor)};
  }
  `}
`;

export const default_ = css`
  ${(props) => {
    if (props.palette === "default") {
      if (props.active) {
        return defaultActive;
      }
      return defaultInactive;
    }
  }};
`;

export const disabled = css`
  ${(props) =>
    props.disabled &&
    `
  opacity: 0.3;
  cursor: not-allowed;
  `}
`;
