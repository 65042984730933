import React from "react";
import styled from "styled-components";

const Wrapper = styled.td``;

const TableColumn = ({ children, className, ...rest }) => (
  <Wrapper className={className} {...rest}>{children}</Wrapper>
);

export default TableColumn;
