import React from "react";
import styled from "styled-components";

const Display = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const Value = styled.h1`
  line-height: 30px;
  margin: 0;
  font-size: 1.3rem;
  color: ${props => props.theme.palette[props.tint]};
`;

const Label = styled.p`
  margin: 0;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const Wrapper = styled.section`
  padding: 5px 5px;
  flex-direction: column;
  background-color: ${props => props.theme.palette[props.tint]};
  font-size: 0.8rem;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const ExamDisplay = ({
  tintBackground = "darkerColor",
  tintValue = "quaternaryTextColor",
  title,
  value,
  label,
  className
}) => (
  <Wrapper className={className} tint={tintBackground}>
    {title}
    <Display>
      <Value tint={tintValue}>{value}</Value>
      <Label>{label}</Label>
    </Display>
  </Wrapper>
);

export default ExamDisplay;
