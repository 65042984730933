import React from "react";
import View from "../components/View";

import { useHistory } from "react-router-dom";

import { useAuthContext } from "../contexts/Auth";
import { useSidebarContext } from "../contexts/Sidebar";
import { useNewsletterForm } from "../components/Footer";

import useAuthFetchApi from "../hooks/useAuthFetchApi";
import { useNewsletterAlertModalContext } from "../contexts/NewsletterAlertModal";

const ViewContainer = (props) => {
  const { user, logout, isAuthenticated, matchRoles } = useAuthContext();
  const { hidden, setHidden } = useSidebarContext();

  const {
    registerEmailField,
    handleSubmit,
    errors,
    getValues,
    formState,
  } = useNewsletterForm();

  const { startFetch } = useAuthFetchApi("/common/newsletter", "POST");
  const { setIsOpen } = useNewsletterAlertModalContext();
  const { isValid } = formState;

  const onSubmit = handleSubmit((data) => {
    const { email } = data;
    if (isValid) {
      startFetch(JSON.stringify({ email }));
      setIsOpen(true);
    }
  });

  let history = useHistory();

  const handleClickMenuItem = (to) => {
    setHidden(!hidden);
    history.push(to);
  };

  return (
    <View
      user={user}
      isAuthenticated={isAuthenticated}
      matchRoles={matchRoles}
      handleClickMenuItem={handleClickMenuItem}
      hidden={hidden}
      setHidden={setHidden}
      logout={logout}
      newsletterRegisterEmailField={registerEmailField}
      onSubmitNewsletterForm={onSubmit}
      newsletterFormErrors={errors}
      newsletterFormValues={getValues()}
      {...props}
    />
  );
};

export default ViewContainer;
