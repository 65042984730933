import React from "react";
import { Route as ReactRoute } from "react-router-dom";

import RouteNoAllowedAlertModal from "../RouteNoAllowedAlertModal";

const Route = ({
  isProtected = false,
  areRolesMathed = true,
  isAuthenticated = localStorage.getItem('xt-user') !== null,
  onConfirmNotAllowedAlertModal,
  ...rest
}) => {
  if (isProtected && !isAuthenticated) {
    return (
      <RouteNoAllowedAlertModal
        isOpen
        onConfirm={onConfirmNotAllowedAlertModal}
      />
    );
  }

  if (isProtected && isAuthenticated && !areRolesMathed) {
    return (
      <RouteNoAllowedAlertModal
        isOpen
        onConfirm={onConfirmNotAllowedAlertModal}
      />
    );
  }

  return <ReactRoute {...rest} />;
};

export default Route;
