import React from "react";

import Context from "./UserDisconnectedAlertModalContext";

const UserDisconnectedAlertModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const value = {
    isOpen,
    setIsOpen,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default UserDisconnectedAlertModalProvider;
