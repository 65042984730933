import React from "react";

import ViewContainer from "../ViewContainer";

import Grid from "../../components/Grid";
import Row from "../../components/GridRow";
import Column from "../../components/GridColumn";
import Progress from "../../components/Progress";

import HeaderProgress from "../headers/HeaderProgress";

const ProgressContainer = () => {
  return (
    <ViewContainer subMenu={<HeaderProgress />}>
      <Grid>
        <Row>
          <Column>
            <Progress />
          </Column>
        </Row>
      </Grid>
    </ViewContainer>
  );
};

export default ProgressContainer;
