import React from "react";
import NewsletterAlertModal from "../../components/NewsletterAlertModal";

import { useNewsletterAlertModalContext } from "../../contexts/NewsletterAlertModal";

const NewsletterAlertModalContainer = () => {
  const { isOpen, setIsOpen } = useNewsletterAlertModalContext();

  const onConfirm = () => {
    setIsOpen(false);
  };

  return (
    <NewsletterAlertModal
      isOpen={isOpen}
      onConfirm={onConfirm}
    />
  );
};

export default NewsletterAlertModalContainer;