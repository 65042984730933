import React from "react";

import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail } from "./SliderRail";
import { Handle } from "./Handle";
import { Track } from "./Track";
import { Tick } from "./Tick";

const sliderStyle = {
  display: "flex",
  position: "relative",
  width: "100%",
};

const defaultValues = [0];

const SliderBar = ({
  domain = [0, 100],
  ticksCount = 10,
  startValue,
  setNewValue,
  title,
}) => {
  const [value, setValue] = React.useState(startValue || defaultValues.slice());

  const onChange = (values) => {
    setValue(values[0]);
    setNewValue(values[0]);
  };

  return (
    <Slider
      mode={1}
      step={1}
      domain={domain}
      rootStyle={sliderStyle}
      onUpdate={onChange}
      onChange={onChange}
      values={[value]}
    >
      <Ticks count={ticksCount}>
        {({ ticks }) => (
          <div className="slider-ticks">
            {ticks.map((tick) => (
              <Tick
                key={tick.id}
                tick={tick}
                count={ticks.length}
                topTick
                currentValue={value}
              />
            ))}
          </div>
        )}
      </Ticks>
      <Rail>
        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map((handle) => (
              <Handle
                key={handle.id}
                handle={handle}
                domain={domain}
                title={title}
                getHandleProps={getHandleProps}
              />
            ))}
          </div>
        )}
      </Handles>
      <Tracks right={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
              <Track
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
              />
            ))}
          </div>
        )}
      </Tracks>
      <Ticks count={ticksCount}>
        {({ ticks }) => (
          <div className="slider-ticks">
            {ticks.map((tick) => (
              <Tick key={tick.id} tick={tick} count={ticks.length} />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  );
};

export default SliderBar;
