import React, { Fragment, useState, useEffect } from "react";
import styled from 'styled-components';

import BuilderWizardStep from "../BuilderWizardStep";
import BuilderFormSection from "../BuilderFormSection";
import Field from "../Field";
import Input from "../Input";
import CheckboxItem from "../CheckboxItem";
import InputRadio from "../InputRadio";

const BuilderFormSectionEdited = styled(BuilderFormSection)`
  h3 {
    font-weight: 400;
  }
`;

const BuilderCheckboxItem = styled(CheckboxItem)`
  margin: 10px;

  strong {
    color: ${(props) => props.theme.palette.primaryTextColor};
  }
`;

const BuilderInputRadio = styled(InputRadio)``;

const Separator = styled.hr`
  margin-left: -15px;
  margin-right: -15px;
  border-top: 1px;
`;

export default ({ register, exercise }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    console.log(exercise.extraQuestions && exercise.extraQuestions.length > 0);
    setEnabled(exercise.extraQuestions && exercise.extraQuestions.length > 0)
  }, [exercise])

  const extraQuestion = "extraQuestions[0]";

  const ExtraQuestion = () => (
    [1, 2, 3, 4].map((number, index) => {
      const extraQuestionAlternatives = `${extraQuestion}[extraQuestionAlternatives][${index}]`;
      const extraQuestionAlternativesDefaults = exercise.extraQuestions && exercise.extraQuestions.length > 0 && exercise.extraQuestions[0].extraQuestionAlternatives[index];
      return (
        <Fragment key={index}>
          <Separator />
          <BuilderFormSection style={{ width: "calc(100% - 210px)", display: "inline-block" }} title={`Alternativa ${number}`}>
            <Field
              name={`${extraQuestionAlternatives}[alternative]`}
              value={extraQuestionAlternativesDefaults && extraQuestionAlternativesDefaults.alternative}
              component={Input}
              placeholder="Digite aqui o enunciado da alternativa"
              ref={register}
            />
          </BuilderFormSection>
          <BuilderFormSection style={{ width: "200px", display: "inline" }}>
            <BuilderInputRadio
              tint="primaryTextColor"
              ref={register}
              title="Verdadeiro"
              name={`${extraQuestionAlternatives}[answer]`}
              defaultChecked={extraQuestionAlternativesDefaults && extraQuestionAlternativesDefaults.answer === true}
              value={true}
            />
            <BuilderInputRadio
              tint="primaryTextColor"
              ref={register}
              title="Falso"
              name={`${extraQuestionAlternatives}[answer]`}
              defaultChecked={extraQuestionAlternativesDefaults && extraQuestionAlternativesDefaults.answer === false}
              value={false}
            />
          </BuilderFormSection>
          <BuilderFormSectionEdited style={{ width: "calc(100% - 210px)", display: "inline-block" }} title={<span><strong>Comentário {number}</strong> (Opcional)</span>}>
            <Field
              name={`${extraQuestionAlternatives}[comment]`}
              value={extraQuestionAlternativesDefaults && extraQuestionAlternativesDefaults.comment}
              component={Input}
              placeholder={`Digite aqui uma dica para a alternativa ${number}`}
              ref={register}
            />
          </BuilderFormSectionEdited>
        </Fragment>
      )
    })
  );
  return (
    <BuilderWizardStep>
      <BuilderCheckboxItem labelBefore={false} checked={enabled} onChange={e => setEnabled(e.target.checked)} labelText={<span><strong>Incluir questão</strong> (Opcional)</span>} />
      {enabled &&
        <Fragment>
          <BuilderFormSection title="Enunciado">
            <Field
              name={`${extraQuestion}[question]`}
              value={(exercise.extraQuestions && exercise.extraQuestions.length > 0) && exercise.extraQuestions[0].question}
              component={Input}
              placeholder="Digite aqui"
              ref={register}
            />
          </BuilderFormSection>
          <ExtraQuestion />
        </Fragment>
      }
    </BuilderWizardStep>
  );
};
