import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const Progress = () => (
        <Wrapper>
            
        </Wrapper>
    )

export default Progress
