import React from "react";
import styled from "styled-components";

import ExerciseTimer from "../ExerciseTimer";
import ColapseCard from "../../ColapseCard";
import Exams from "../Exams";
import ExamsVentilometria from "../ExamsVentilometria";
import ExamsSincronia from "../ExamsSincronia";
import ExamsMecanicaRespiratoria from "../ExamsMecanicaRespiratoria";
import ExamsTrabalhoRespiratorio from "../ExamsTrabalhoRespiratorio";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 15%;
  min-width: 200px;
  height: 100%;
`;

const RightPainel = ({
  timer,
  currentTimer,
  exams,
  gasometria = [],
  updateExams = {},
  isBuilder
}) => {
  const addZero = number => (number < 10 ? `0${number}` : number);

  const formatTimer = msDiff => {
    const min = Math.floor(msDiff / 60);
    const sec = Math.floor(msDiff % 60);
    return min === 0 && sec === 0 ? "00" : `${addZero(min)}:${addZero(sec)}`;
  };

  return (
    <Wrapper>
      <ExerciseTimer timer={timer} currentTimer={currentTimer} />
      <ColapseCard title="Ventilometria">
        <ExamsVentilometria results={updateExams} />
      </ColapseCard>
      <ColapseCard title="Sincronia">
        <ExamsSincronia results={updateExams} />
      </ColapseCard>
      <ColapseCard title="Mecânica Respiratória">
        <ExamsMecanicaRespiratoria results={updateExams} />
      </ColapseCard>
      <ColapseCard title="Trabalho Resp./Desmame">
        <ExamsTrabalhoRespiratorio results={updateExams} />
      </ColapseCard>
      {gasometria.map((gas, index) => (
        <ColapseCard
          title={`Gasometria #${index + 1} às ${formatTimer(gas.date)}`}
          defaultActive={true}
        >
          <Exams results={gas} gas />
        </ColapseCard>
      ))}
    </Wrapper>
  );
};

export default RightPainel;
