import React from "react";
import styled from "styled-components";

import Timer from "../../../components/Timer";

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  padding: 5px;
`;

const LabelWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.palette.quaternaryTextColor};
  font-size: 10px;
`;

const TimerLabel = styled.h1`
  line-height: 30px;
  margin: 0;
  color: ${props => props.theme.palette.quaternaryTextColor};
  font-size: 20px;
`;

const ExerciseTimer = ({ timer, currentTimer }) => {
  const countdown = () => {
    return (currentTimer / timer) * 100;
  };

  const addZero = number => (number < 10 ? `0${number}` : number);

  const formatTimer = () => {
    const min = Math.floor(currentTimer / 60);
    const sec = Math.floor(currentTimer % 60);
    return min === 0 && sec === 0 ? "00" : `${addZero(min)}:${addZero(sec)}`;
  };

  return (
    <Wrapper>
      <Timer percentage={countdown()} size="50" />
      <LabelWrapper>
        Tempo do exercício
        <TimerLabel>{formatTimer()}</TimerLabel>
      </LabelWrapper>
    </Wrapper>
  );
};

export default ExerciseTimer;
