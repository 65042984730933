import React from "react";
import InputWithIcon from "../InputWithIcon";

const InputPassword = React.forwardRef((props, ref) => {
  const [show, setShow] = React.useState(false);

  const toggleType = () => {
    setShow(!show);
  };

  const type = (show && "text") || "password";

  return (
    <InputWithIcon onClickIcon={toggleType} type={type} {...props} ref={ref} />
  );
});

export default InputPassword;
