import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
  position: relative;
`;

export default ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
