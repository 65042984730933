import React, { Fragment, forwardRef } from "react";
import styled from "styled-components";

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

const CustomCheckbox = styled.span`
  position: absolute;
  ${({ labelBefore }) => labelBefore && `right: 20px;`}
  top: 3px;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.palette.secondaryTextColor};
  border-radius: 2px;

  :after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export default forwardRef(({ labelBefore = true, ...rest }, ref) => (
  <Fragment>
    <Checkbox ref={ref} {...rest} />
    <CustomCheckbox labelBefore={labelBefore} />
  </Fragment>
));
