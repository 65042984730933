import React from "react";
import Icon from "../Icon";

import styled from 'styled-components';

const FileCard = styled.div`
  position: relative;
  margin: 5px;
  padding: 5px 10px;
  width: 150px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.palette.defaultColor};
  text-align: left;

  -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
`;

const FileName = styled.p`
  display: inline-block;
  vertical-align: middle;
  max-width: 100px;
  margin-left: 5px;
  font-size: .7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveIcon = styled(Icon)`
  position: absolute;
  margin: 7px 10px;
  right: 0;
  
  :hover {
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
  }
`;

export default ({ content, onRemoveFile }) => {
  const handleRemoveClick = e => {
    e.stopPropagation();
    onRemoveFile && onRemoveFile();
  }

  return (
    <FileCard title={content.name}>
      <Icon icon="checkBox" />
      <FileName>{content.name}</FileName>
      <RemoveIcon onClick={handleRemoveClick} icon="close" />
    </FileCard>
  );
};

