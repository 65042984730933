import React from "react";
import styled from "styled-components";

import SidebarHeader from "../SidebarHeader";
import Menu from "../Menu";
import StyledMenuItem from "../MenuItem";
import MenuItemArrow from "../MenuItemArrow";

const Wrapper = styled.aside`
  height: 100%;
`;

const MenuItem = styled(StyledMenuItem)`
  padding-left: 90px;
`;

const Sidebar = ({
  isAuthenticated,
  matchRoles,
  handleClickMenuItem,
  hidden,
  setHidden,
}) => {
  return (
    <Wrapper>
      <SidebarHeader />
      <Menu>
        <MenuItem active to="/">
          Início
        </MenuItem>

        <MenuItem to="/builder">
          Builder
        </MenuItem>

        {/* {!matchRoles(["teacher", "admin"]) && (
          <MenuItem active to="/">
            Início
          </MenuItem>
        )} */}

        {/* {!isAuthenticated && (
          <>
            <MenuItemArrow
              handleClickMenuItem={handleClickMenuItem}
              hidden={hidden}
              setHidden={setHidden}
              label="Assinaturas"
            >
              <MenuItem subitem to="/free">
                Free
              </MenuItem>
              <MenuItem subitem to="/premium">
                Premium
              </MenuItem>
            </MenuItemArrow>
            <MenuItem to="/" disabled={!isAuthenticated}>
              Favoritos
            </MenuItem>
          </>
        )} */}

        {/* {isAuthenticated && matchRoles(["student"]) && (
          <>
            <MenuItem to="/account">Conta</MenuItem>
            <MenuItemArrow
              handleClickMenuItem={handleClickMenuItem}
              hidden={hidden}
              setHidden={setHidden}
              label="Meus Exercícios"
              to="/myexercises"
            >
              <MenuItem subitem to="/progress">
                Progresso
              </MenuItem>
            </MenuItemArrow>
            <MenuItem to="/favourites">Favoritos</MenuItem>
          </>
        )} */}
        {/* {!matchRoles(["teacher", "admin"]) && (
          <>
            <MenuItem to="/opinion">Dê sua opinião</MenuItem>
            <MenuItem to="/help">Ajuda</MenuItem>
          </>
        )} */}

        {/* {isAuthenticated && matchRoles(["admin"]) && (
          <>
            <MenuItem to="/">Relatórios</MenuItem>
          </>
        )}
        {isAuthenticated && matchRoles(["teacher", "admin"]) && (
          <>
            <MenuItem to="/builder">Builder</MenuItem>
            <MenuItem to="/exercises">Exercícios</MenuItem>
            <MenuItem to="/account">Conta</MenuItem>
          </>
        )} */}

        {/* {isAuthenticated && matchRoles(["admin"]) && (
          <>
            <MenuItem to="/help">Ajuda</MenuItem>
          </>
        )} */}
      </Menu>
    </Wrapper>
  );
};

export default Sidebar;
