import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ContentHeaderColumn = ({children}) => <Wrapper>{children}</Wrapper>;

export default ContentHeaderColumn;