import React from "react";
import styled from "styled-components";
import { animated } from "react-spring";
import Spritesheet from "react-responsive-spritesheet";

import HeartBeat from "../../../components/HeartBeat";
import lung from "../../../assets/images/lung.png";

import StyledIcon from "../../../components/Icon";

const Icon = styled(StyledIcon)`
  width: 2em;
  height: 2em;
  margin: 5px;
  cursor: pointer;
  fill: ${props => props.theme.palette[props.fill]};
  &:hover,
  &:focus {
    fill: ${props => props.theme.palette.alertColor};
  }
`;

const Wrapper = styled.main`
  height: 50%;
  flex-direction: column;
  display: flex;
  position: relative;
  align-items: center;
  margin: 15px 0;
  padding: 10px;
  background-color: ${props => props.theme.palette.darkerColor};
  font-size: 18px;
  color: ${props => props.theme.palette[props.tint]};
`;

const Header = styled.header`
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const IconContainer = styled.div`
  display: flex;
  position: absolute;
  left: 10px;
  top: 40px;
  bottom: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`;

const Label = styled.div`
  font-size: 1.5rem;
  margin-top: 10px;
  color: ${props => props.theme.palette[props.tint]};
`;

const MonitorDisplay = ({
  monitorData,
  lungAnimation,
  lungSound,
  setLungSound,
  volumeNode,
  setVolume
}) => {
  const heartRateFrames = () => {
    const fixValue = 60;
    return fixValue / monitorData.heartRate.value;
  };

  const stateColor = {
    ok: "successColor",
    warning: "alertColor",
    alert: "dangerColor"
  };

  return (
    <Wrapper>
      <Header>Monitor</Header>
      <IconContainer>
        <Icon
          icon={lungSound ? "soundOn" : "soundOff"}
          fill="initial"
          onClick={() => setLungSound(!lungSound)}
        />
        <Icon
          icon={volumeNode > 0 ? "soundOn" : "soundOff"}
          fill="initial"
          onClick={() => setVolume()}
        />
      </IconContainer>
      <animated.div
        style={{
          transform: `perspective(600px) scale(0.8)`
        }}
      >
        <Spritesheet
          ref={lungAnimation}
          image={lung}
          widthFrame={280}
          heightFrame={245}
          steps={20}
          fps={12}
        />
      </animated.div>

      <Label tint={stateColor[monitorData.SpO2.rating]}>
        <span style={{ fontSize: "1rem" }}>SpO</span>
        <sub style={{ fontSize: "0.8rem" }}>2</sub>{" "}
        {monitorData.SpO2.value.toFixed(0)}%
      </Label>

      <Label tint={stateColor[monitorData.heartRate.rating]}>
        <span style={{ fontSize: "1rem" }}> FC</span>{" "}
        {monitorData.heartRate.value.toFixed(0)}
        <span style={{ fontSize: "0.7rem" }}> bpm</span>
      </Label>
      <HeartBeat
        heartRate={heartRateFrames()}
        rating={monitorData.heartRate.rating}
      />
    </Wrapper>
  );
};

export default MonitorDisplay;
