import { useForm } from "react-hook-form";

const useLoginForm = () => {
  const { register, ...rest } = useForm({
    mode: "onBlur",
  });

  const emailRE = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const registerEmailField = (ref) =>
    register(ref, {
      required: {
        value: true,
        message: "Este campo é obrigatório",
      },
      validate: (value) => emailRE.test(value) || "Digite um email válido",
    });

  const registerPasswordField = (ref) => {
    register(ref, {
      required: "Este campo é obrigatório",
      minLength: {
        value: 3,
        message: "Deve conter ao menos 3 caracteres",
      },
    });
  };

  return {
    registerEmailField,
    registerPasswordField,
    ...rest,
  };
};

export default useLoginForm;
