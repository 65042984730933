import React from "react";
import styled from "styled-components";

import ExamDisplay from "../../ExamDisplay";

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.palette.quaternaryTextColor};
  background-color: ${props => props.theme.palette.darkerColor};
`;

const ExamWrapper = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: ${props => props.theme.palette.secondaryColor};
`;

const ExamItem = styled.div`
  width: 49.7%;
  margin-bottom: 1px;
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const Exams = ({ results = [], gas = false }) => {
  const stateColor = {
    ok: "successColor",
    warning: "alertColor",
    alert: "dangerColor"
  };
  return (
    <Wrapper>
      <ExamWrapper>
        {gas
          ? Object.keys(results).map((key, index) => (
              <ExamItem key={index}>
                {key !== "date" && (
                  <ExamDisplay
                    title={key.replace("2", "₂").replace("3", "₃")}
                    value={results[key].value}
                    label={results[key].unit}
                    tintValue={stateColor[results[key].rating]}
                  />
                )}
              </ExamItem>
            ))
          : results.map((result, index) => (
              <ExamItem key={result.label + index}>
                <ExamDisplay
                  title={result.title}
                  value={result.value}
                  label={result.label}
                  tintValue={result.tintValue}
                />
              </ExamItem>
            ))}
      </ExamWrapper>
    </Wrapper>
  );
};

export default Exams;
