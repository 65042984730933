import React from "react";
import styled from "styled-components";

import Button from "../../../Button";
import StyledIcon from "../../../Icon";

const Wrapper = styled.header`
  height: 200px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-item: center;
  position: absolute;
  bottom: 0;
  left: 110%;
  border-radius: 5px;
  padding: 10px;
  background-color: ${(props) => props.theme.palette.darkerColor};
  box-shadow: 0px 0px 3px ${(props) => props.theme.palette.mediumGrayColor};
`;

const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 25px;
`;

const HeaderLabel = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: ${(props) => props.theme.palette.quaternaryTextColor};
`;

const HeaderItem = styled.section`
  min-height: 100px;
  display: flex;
  position: relative;
  padding: 0 20px;
  padding-bottom: 30px;
  align-items: flex-end;
  background-color: ${(props) => props.theme.palette.darkerGrayColor};
`;

const ButtonContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  color: ${(props) => props.theme.palette.quaternaryTextColor};
`;

const Icon = styled(StyledIcon)`
  margin: 0;
  fill: #ffffff;
  size: 70px;
  &:hover,
  &:focus {
    fill: ${(props) => props.theme.palette.alertColor};
  }
`;

const InputModalTips = ({ close, title, tip }) => (
  <Wrapper>
    <HeaderSection>
      <HeaderLabel>{title}</HeaderLabel>
      <Icon icon="close" size={25} onClick={() => close()} />
    </HeaderSection>
    <HeaderItem>{tip}</HeaderItem>
    <ButtonContainer>
      <Button
        palette="info"
        variant="filled"
        size="tiny"
        onClick={() => close()}
      >
        Ok
      </Button>
    </ButtonContainer>
  </Wrapper>
);

export { InputModalTips };
