import React from "react";
import styled from "styled-components";

const Wrapper = styled.table`
  width: 100%;
`;

const Table = ({ children, className }) => (
  <Wrapper className={className}>{children}</Wrapper>
);

export default Table;
