import React from "react";
import styled from "styled-components";

import Button from "../../../Button";
import SliderBar from "../../../SliderBar";
import StyledIcon from "../../../Icon";

const Wrapper = styled.header`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-item: center;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 70px;
  border-radius: 5px;
  padding: 10px;
  background-color: ${props => props.theme.palette.darkerColor};
  box-shadow: 0px 0px 3px ${props => props.theme.palette.mediumGrayColor};
  zindex: 1;
`;

const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 25px;
`;

const HeaderLabel = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const HeaderItem = styled.section`
  min-height: 130px;
  display: flex;
  padding: 0 20px;
  padding-bottom: 30px;
  align-items: flex-end;
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const ButtonContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const Icon = styled(StyledIcon)`
  margin: 0;
  fill: #ffffff;
  size: 70px;
  &:hover,
  &:focus {
    fill: ${props => props.theme.palette.alertColor};
  }
`;

const InputModalSlider = ({
  close,
  title,
  valueLabel,
  ticks,
  domain = [0, 10],
  value,
  submitValue = () => null
}) => {
  const [newValue, setNewValue] = React.useState(value);

  return (
    <Wrapper>
      <HeaderSection>
        <HeaderLabel>{title}</HeaderLabel>
        <Icon icon="close" size={25} onClick={() => close()} />
      </HeaderSection>
      <HeaderItem>
        <SliderBar
          startValue={value || 0}
          setNewValue={setNewValue}
          title={valueLabel}
          ticksCount={ticks}
          domain={domain}
        />
      </HeaderItem>
      <ButtonContainer>
        <Button
          palette="darkerGray"
          variant="filled"
          size="tiny"
          onClick={() => close()}
        >
          Cancela
        </Button>
        <Button
          palette="info"
          variant="filled"
          size="tiny"
          onClick={() => {
            submitValue(newValue);
            close();
          }}
        >
          Ok
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export { InputModalSlider };
