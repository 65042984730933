import React, { forwardRef } from "react";
import styled from "styled-components";
import Checkbox from "../Checkbox";

const Text = styled.span`
  margin-left: ${props => props.labelAfter ? "25px" : "20px"};
`;

const Wrapper = styled.label`
  color: ${(props) => props.theme.palette.secondaryTextColor};
  line-height: 1.7;

  display: block;
  position: relative;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :hover input + span {
    background-color: ${(props) => props.theme.palette.initialColor};
  }

  input:checked + span {
    background-color: ${(props) => props.theme.palette.primaryTextColor};
  }

  input:checked + span:after {
    display: block;
  }

  input + span:after {
    left: 3px;
    top: 0;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export default forwardRef(({
  className,
  labelBefore = true,
  labelText = "",
  value,
  name,
  ...rest
}, ref) => (
    <Wrapper className={className} >
      {labelBefore ? <Text>{labelText}</Text> : ""}
      <Checkbox ref={ref} name={name || labelText} key={rest.key} labelBefore={labelBefore} value={value} {...rest} />
      {!labelBefore ? <Text labelAfter={!labelBefore}>{labelText}</Text> : ""}
    </Wrapper>
  ));
