import React from "react";
import styled from "styled-components";

import Button from "../../../../components/Button";
import StyledIcon from "../../../Icon";

const Wrapper = styled.header`
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 70px;
  border-radius: 5px;
  padding: 10px;
  background-color: ${props => props.theme.palette.darkerColor};
  box-shadow: 0px 0px 3px ${props => props.theme.palette.mediumGrayColor};
  zindex: 1;
`;

const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const HeaderItem = styled.section`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const InputSection = styled.section`
  display: flex;
  padding: 5px;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.palette.mediumGrayColor};
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const ButtonContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const Icon = styled(StyledIcon)`
  margin: 0 5px;
  fill: #ffffff;
  size: 70px;
  &:hover,
  &:focus {
    fill: ${props => props.theme.palette.alertColor};
  }
`;

const IconInputContainer = styled.section`
  display: flex;
  margin: 0 5px;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: ${props => props.theme.palette.darkerColor};
`;

const LabelInput = styled.h1`
  margin: 0 20px;
  line-height: 0;
  font-size: 15px;
`;

const Label = styled.h1`
  margin.top: 20px;
  line-height: 0;
  font-size: 13px;
  color: ${props => props.theme.palette.tertiaryTextColor};
`;

const IconInput = styled(StyledIcon)`
  fill: #ffffff;
  size: 70px;
  &:hover,
  &:focus {
    fill: ${props => props.theme.palette.infoColor};
  }
`;

const InputModalNumber = ({
  close,
  title,
  valueLabel,
  minValue = 0,
  maxValue = 100,
  value = 0,
  setValue = () => null,
  incrementValue = 1,
  fixedValue = 1
}) => {
  const [newValue, setNewValue] = React.useState(value);

  const updateValue = incrementValue => {
    const updatedValue = newValue + incrementValue;
    if (updatedValue >= minValue && updatedValue <= maxValue)
      setNewValue(updatedValue);
  };

  return (
    <Wrapper>
      <HeaderSection>
        {title}
        <Icon icon="close" size={25} onClick={() => close()} />
      </HeaderSection>
      <HeaderItem>
        <InputSection>
          <IconInputContainer>
            <IconInput
              icon="chevronDown"
              size={25}
              onClick={() => updateValue(-incrementValue)}
            />
          </IconInputContainer>
          <LabelInput>{newValue.toFixed(fixedValue)}</LabelInput>
          <IconInputContainer>
            <IconInput
              icon="chevronUp"
              size={25}
              onClick={() => updateValue(incrementValue)}
            />
          </IconInputContainer>
        </InputSection>
        <Label>{valueLabel}</Label>
      </HeaderItem>
      <ButtonContainer>
        <Button
          palette="darkerGray"
          variant="filled"
          size="tiny"
          onClick={() => close()}
        >
          Cancela
        </Button>
        <Button
          palette="info"
          variant="filled"
          size="tiny"
          onClick={() => {
            setValue(newValue);
            close();
          }}
        >
          Ok
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export { InputModalNumber };
