import React from "react";
import styled from "styled-components";

import Icon from "../Icon";

const Wrapper = styled.div`
  transform: ${(props) =>
    props.direction ? `rotate(-90deg)` : `rotate(90deg)`};
`;

const DropdownArrow = ({ direction, ...rest }) => {
  return (
    <Wrapper direction={direction ? 1 : 0}>
      <Icon icon="chevronLeft" {...rest} />
    </Wrapper>
  );
};

export default DropdownArrow;
