import React from "react";

import Context from "./GridContext";

import { DEFAULT_PADDING } from "../../settings";

const GridProvider = ({ children }) => {
  const [columns, setColumns] = React.useState(12);
  const [gutter, setGutter] = React.useState(DEFAULT_PADDING);
  const value = {
    columns,
    setColumns,
    gutter,
    setGutter,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default GridProvider;
