import React, {useState} from "react";
import axios from 'axios';

import { Redirect, useHistory } from "react-router-dom";

import LoginModal from "../../components/LoginModal";

import ViewContainer from "../ViewContainer";

import { useLoginForm } from "../../components/LoginForm";
// import { useAuthContext } from "../../contexts/Auth";

import { useSnackbar } from 'react-simple-snackbar'


import routes from "../../routes";

const { REACT_APP_API_BASE_URI } = process.env;


const LoginContainer = () => {
  const TOKEN_KEY = 'xt-user';

  const [openSnackbar, closeSnackbar] = useSnackbar()

  // const { login, isAuthenticated, isAuthenticating } = useAuthContext();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const history = useHistory();

  const {
    registerEmailField,
    registerPasswordField,
    handleSubmit,
    errors,
    getValues,
    formState,
  } = useLoginForm();

  const { isValid, isSubmitted } = formState;

  const onSubmit =  handleSubmit((data) => {
    if (isValid) {
      console.log('URL: ', REACT_APP_API_BASE_URI);
      axios.post(`${REACT_APP_API_BASE_URI}/signin`, {
        "email": data.email,
        "password": data.password,
      })
      .then(function (response) {
        localStorage.setItem(TOKEN_KEY, JSON.stringify(response.data.client));
        setIsAuthenticated(true);
        // history.push(routes.ROOT);
        history.go(0)

      })
      .catch(function (error) {
        const message = error?.response?.data?.error || "Falha ao acessar"
        openSnackbar(message);
      });
    }
  });

  const onClose = () => {
    history.push(routes.ROOT);
  };

  const isToRedirect = isSubmitted && isAuthenticated;

  return (
    <>
      {(isToRedirect && <Redirect to="/" />) || (
        <ViewContainer>
          <LoginModal
            isOpen={true}
            onSubmit={onSubmit}
            registerEmailField={registerEmailField}
            registerPasswordField={registerPasswordField}
            errors={errors}
            values={getValues()}
            isAuthenticating={isAuthenticating}
            onClose={onClose}
          />
        </ViewContainer>
      )}
    </>
  );
};

export default LoginContainer;
