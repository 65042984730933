import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 0;
`;

const Label = styled.label`
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 0.7em;
`;

const ErrorMessage = styled.p`
  margin: 5px 0 0;
  font-size: 0.8em;
  color: ${(props) => props.theme.palette.dangerColor};
`;

const Field = React.forwardRef(
  (
    {
      label,
      name,
      errorMessage,
      valid = true,
      component: Component,
      className,
      ...rest
    },
    ref
  ) => {
    const componentProps = {
      name,
      valid,
      ref,
      ...rest,
    };

    const renderComponent = () => {
      if ("function" === typeof Component) {
        return Component(componentProps);
      }
      if ("object" === typeof Component) {
        return <Component {...componentProps} />;
      }
    };

    return (
      <Wrapper className={className}>
        {label && <Label htmlFor={name}>{label}</Label>}
        {renderComponent()}
        {!valid && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Wrapper>
    );
  }
);

export default Field;
