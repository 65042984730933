import React from "react";
import styled from "styled-components";

import ExamDisplay from "../../ExamDisplay";

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.palette.quaternaryTextColor};
  background-color: ${props => props.theme.palette.darkerColor};
`;

const ExamWrapper = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: ${props => props.theme.palette.secondaryColor};
`;

const ExamItem = styled.div`
  width: 49.7%;
  margin-bottom: 1px;
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const ExamsMecanicaRespiratoria = ({ results = [] }) => {
  return (
    <Wrapper>
      <ExamWrapper>
        <ExamItem>
          <ExamDisplay
            title="P. Pico"
            value={results?.peakPressure || "-"}
            label="(cmH₂O)"
            tintValue="successColor"
          />
        </ExamItem>

        <ExamItem>
          <ExamDisplay
            title="PPlateau"
            value={results?.plateauPressure || "-"}
            label="(cmH₂O)"
            tintValue="successColor"
          />
        </ExamItem>

        <ExamItem>
          <ExamDisplay
            title="Driving P."
            value={results?.drivingPressure || "-"}
            label="(cmH₂O)"
            tintValue="successColor"
          />
        </ExamItem>

        <ExamItem>
          <ExamDisplay
            title="PEEP Total"
            value={results?.peepTotal || "-"}
            label="(cmH₂O)"
            tintValue="successColor"
          />
        </ExamItem>
      </ExamWrapper>
    </Wrapper>
  );
};

export default ExamsMecanicaRespiratoria;
