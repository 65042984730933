import React from "react";
import styled, { css } from "styled-components";
import Button from "../Button";
import StyledIcon from "../Icon";

const iconInitial = css`
  fill: ${props => props.theme.palette.primaryTextColor};
`;

const iconPrimary = css`
  fill: ${props => props.theme.palette.quaternaryTextColor};
`;

const iconLeft = css`
  margin-right: 5px;
`;

const iconRight = css`
  margin-left: 5px;
  flex-direction: row-reverse;
`;

const Icon = styled(StyledIcon)`
  width: 1.4em;
  height: 1.4em;
  flex-direction: row-reverse;
  ${props => props.position === "left" && iconLeft};
  ${props => props.position === "right" && iconRight};
  ${props => props.palette === "initial" && iconInitial};
  ${props => props.palette === "primary" && iconPrimary};
  ${props => props.palette === "darkerGray" && iconPrimary};
  ${props => props.palette === "darker" && iconPrimary};
`;

const ButtonIcon = styled(Button)`
  height: 40px;
  ${props => props.position === "right" && iconRight};
`;

const ButtonWithIcon = ({
  palette = "initial",
  position = "left",
  icon,
  children,
  ...rest
}) => (
  <ButtonIcon palette={palette} {...rest} position={position}>
    {icon && <Icon icon={icon} palette={palette} position={position} />}
    {children}
  </ButtonIcon>
);

export default ButtonWithIcon;
