import React from "react";
import styled from "styled-components";

const ToggleContainer = styled.div`
  width: 70px;
  background-color: #002b49;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  margin: 10px 0;
  position: relative;
`;

const DialogButton = styled.div`
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  text-align: center;
`;

const SwitchButton = ({ selected = { label: "" }, onSwitch = () => null }) => {
  return (
    <ToggleContainer onClick={() => onSwitch()}>
      <DialogButton>{selected.label}</DialogButton>
    </ToggleContainer>
  );
};
export default SwitchButton;
