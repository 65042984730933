import { useState, useEffect } from "react";

const useOnChange = (onChange, currentValue) => {
  const [newValue, setNewValue] = useState(currentValue);

  const onChangeHandler = (e) => {
    setNewValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  useEffect(() => {
    setNewValue(currentValue);
  }, [currentValue, setNewValue]);

  return {
    onChangeHandler,
    newValue,
  };
};

export default useOnChange;
