import React from "react";
import styled from "styled-components";

import AudioPlayer from "react-h5-audio-player";

import mockAudio from "../../../../assets/audios/mockAudio.mp3";

import StyledIcon from "../../../Icon";

const Wrapper = styled.header`
  height: 120px;
  width: 300px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10%;
  right: 50%;
  left: 50%;
  border-radius: 5px;
  padding: 10px;
  background-color: ${props => props.theme.palette.darkerGrayColor};
  box-shadow: 0px 0px 3px ${props => props.theme.palette.darkerColor};
`;

const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const HeaderItem = styled.section`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const LabelButton = styled.h1`
  margin: 0 5px;
  line-height: 0;
  font-size: 15px;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const Icon = styled(StyledIcon)`
  margin: 0 5px;
  fill: #ffffff;
  size: 70px;
  &:hover,
  &:focus {
    fill: ${props => props.theme.palette.alertColor};
  }
`;

const AudioExam = ({ close }) => (
  <Wrapper>
    <HeaderSection>
      <HeaderItem onClick={() => close()}>
        <Icon icon="arrowBack" size={25} />
        <LabelButton>Voltar para Exames</LabelButton>
      </HeaderItem>
      <Icon icon="close" size={25} onClick={() => close()} />
    </HeaderSection>
    <AudioPlayer
      style={{ backgroundColor: "#484848" }}
      src={mockAudio}
      showJumpControls={false}
      autoPlayAfterSrcChange={false}
      showDownloadProgress={false}
      customAdditionalControls={[]}
    />
  </Wrapper>
);

export { AudioExam };
