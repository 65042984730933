import React from "react";
import { useHistory } from "react-router-dom";

import Route from "../components/Route";
import { useAuthContext } from "../contexts/Auth";

import routes from "../routes";

const RouteContainer = ({ allowedRoles = [], ...rest }) => {
  const { matchRoles } = useAuthContext();

  const history = useHistory();

  const onConfirmNotAllowedAlertModal = () => {
    history.push(routes.ROOT);
  };

  const areRolesMathed =
    (!allowedRoles.length && true) || matchRoles(allowedRoles);

  return (
    <Route
      isAuthenticated={localStorage.getItem('xt-user') !== null}
      areRolesMathed={areRolesMathed}
      onConfirmNotAllowedAlertModal={onConfirmNotAllowedAlertModal}
      {...rest}
    />
  );
};

export default RouteContainer;
