import React from "react";

import AlertModal from "../AlertModal";

const RouteNoAllowedAlertModal = (props) => {
  return (
    <AlertModal
      title="Acesso não permitido"
      subtitle="Usuário sem permissão de acesso"
      {...props}
    />
  );
};

export default RouteNoAllowedAlertModal;
