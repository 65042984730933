import React from "react";
import styled, { css } from "styled-components";

import { styles, useOnChange } from "../Input";
import StyledIcon from "../Icon";

import { DEFAULT_PADDING } from "../../settings";

const Wrapper = styled.span`
  ${styles.base}
  ${styles.disabled}
  ${styles.size}
  ${(props) => props.flex && styles.flex};
  ${(props) => props.valid && styles.valid};
  ${(props) => !props.valid && styles.invalid};
  ${(props) => props.noBordered && `border-color: #fff`}
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  ${(props) => props.onClick && styles.iconOnClick};
`;

const iconInvalid = css`
  fill: ${(props) => props.theme.palette.dangerColor};
`;

const Icon = styled(StyledIcon)`
  ${(props) => !props.valid && iconInvalid};
  margin: 0 ${DEFAULT_PADDING};
`;

const InputWithIcon = React.forwardRef(
  (
    {
      name,
      type = "text",
      size,
      value = "",
      valid = true,
      disabled = false,
      placeholder,
      icon,
      color,
      onChange,
      onFocus,
      onBlur,
      onClickIcon,
      flex = false,
      className,
      noBordered = false,
    },
    ref
  ) => {
    const { value: val, onChangeHandler } = useOnChange(onChange, value);
    return (
      <Wrapper valid={valid} noBordered={noBordered} flex={flex}>
        <input
          ref={ref}
          disabled={disabled}
          size={size}
          placeholder={placeholder}
          name={name}
          value={val}
          type={type}
          onChange={onChangeHandler}
          onFocus={onFocus}
          onBlur={onBlur}
          className={className}
          color={color}
        />
        <IconWrapper onClick={onClickIcon}>
          <Icon icon={icon} color={color} size={24} valid={valid} />
        </IconWrapper>
      </Wrapper>
    );
  }
);

export default InputWithIcon;
