import { css } from "styled-components";
import { DEFAULT_PADDING } from "../../settings";

export const base = css`
  display: flex;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  font-size: 0.9em;
  input {
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    border: 0;
    margin: 0;
    background-color: inherit;
    font-size: inherit;
    padding: ${DEFAULT_PADDING};
    color: inherit;
  }
`;

export const disabled = css`
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.3;
    input {
      cursor: not-allowed;
    }
`};
`;

export const valid = css`
  color: ${(props) => props.theme.palette.primaryTextColor};
  border-color: ${(props) => props.theme.palette.initialColor};
`;

export const flex = css`
  flex: 1;
`;

export const invalid = css`
  color: ${(props) => props.theme.palette.dangerColor};
  border-color: ${(props) => props.theme.palette.dangerColor};
`;

export const iconOnClick = css`
  &:hover {
    cursor: pointer;
  }
`;
