import React from "react";
import styled from "styled-components";

import StyledIcon from "../../../Icon";
import xrayImage from "../../../../assets/images/xray.png";

const Wrapper = styled.header`
  width: 300px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10%;
  right: 50%;
  left: 50%;
  border-radius: 5px;
  padding: 15px;
  background-color: ${(props) => props.theme.palette.darkerGrayColor};
  box-shadow: 0px 0px 3px ${(props) => props.theme.palette.darkerColor};
  color: ${(props) => props.theme.palette.quaternaryTextColor};
`;

const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: ${(props) => props.theme.palette.quaternaryTextColor};
`;

const HeaderItem = styled.section`
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
`;

const LabelButton = styled.h1`
  margin: 0 5px;
  line-height: 0;
  font-size: 15px;
  color: ${(props) => props.theme.palette.quaternaryTextColor};
`;

const Icon = styled(StyledIcon)`
  fill: #ffffff;
  size: 70px;
  cursor: pointer;
  &:hover,
  &:focus {
    fill: ${(props) => props.theme.palette.alertColor};
  }
`;

const Image = styled.img`
  display: block;
  height: 70px;
  width: 100%;
  align-self: center;
  margin-top: 10px;
  height: fit-content;
  object-fit: cover;
  border: 5px;
  border-style: solid;
  border-color: ${(props) => props.theme.palette.quaternaryTextColor};
`;

const XRayExam = ({
  close,
  back,
  description = "Raio-X de tórax prévio à intubação",
}) => (
  <Wrapper>
    <HeaderSection>
      <HeaderItem onClick={() => back()}>
        <Icon icon="arrowBack" size={25} />
        <LabelButton>Voltar para Exames</LabelButton>
      </HeaderItem>
      <Icon icon="close" size={25} onClick={() => close()} />
    </HeaderSection>
    {description}
    <Image src={xrayImage} alt="xray" />
  </Wrapper>
);

export { XRayExam };
