import React from "react";
import styled from "styled-components";
import CardBody from "../CardBody";

const Wrapper = styled(CardBody)`
  min-height: 100px;
`;

const ModalBody = ({ children, className }) => (
  <Wrapper className={className}>{children}</Wrapper>
);

export default ModalBody;
