import React from "react";

const Track = ({ source, target, getTrackProps, disabled }) => {
  return (
    <div
      style={{
        position: "absolute",
        transform: "translate(0%, -50%)",
        height: 10,
        zIndex: 1,
        backgroundColor: disabled ? "#999" : "#0DB8D9",
        borderRadius: 7,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
};

export { Track };
