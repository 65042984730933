import React from "react";
import styled from "styled-components";

import ExamDisplay from "../../ExamDisplay";

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.palette.quaternaryTextColor};
  background-color: ${props => props.theme.palette.darkerColor};
`;

const ExamWrapper = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: ${props => props.theme.palette.secondaryColor};
`;

const ExamItem = styled.div`
  width: 49.7%;
  margin-bottom: 1px;
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const ExamsTrabalhoRespiratorio = ({ results }) => {
  const stateColor = {
    ok: "successColor",
    warning: "alertColor",
    danger: "dangerColor"
  };

  return (
    <Wrapper>
      {results && (
        <ExamWrapper>
          <ExamItem>
            <ExamDisplay
              title="P0.1"
              value={
                results?.pressureAt100ms
                  ? results?.pressureAt100ms.toFixed(1)
                  : "-"
              }
              label="(cmH₂O)"
              tintValue="successColor"
            />
          </ExamItem>

          <ExamItem>
            <ExamDisplay
              title="W"
              value={results?.workDone ? results?.workDone.toFixed(1) : "-"}
              label="(Joules/l)"
              tintValue="successColor"
            />
          </ExamItem>

          <ExamItem>
            <ExamDisplay
              title="PTP"
              value={
                results?.pressureTimeProduct
                  ? results?.pressureTimeProduct.toFixed(1)
                  : "-"
              }
              label="(cmH₂O.s.m⁻¹)"
              tintValue="successColor"
            />
          </ExamItem>

          <ExamItem>
            <ExamDisplay
              title="f/VC"
              value={results?.tobinIndex ? results?.tobinIndex.toFixed(1) : "-"}
              label="(rpm/L)"
              tintValue="successColor"
            />
          </ExamItem>
        </ExamWrapper>
      )}
    </Wrapper>
  );
};

export default ExamsTrabalhoRespiratorio;
