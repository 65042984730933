import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Context from "./BuilderWizardContext";

import useAuthFetchApi from "../../hooks/useAuthFetchApi";
import { useParams, useHistory } from "react-router-dom";

const setExerciseToStorage = exerciseId =>
  window.localStorage.setItem("xt-exercise", JSON.stringify(exerciseId));

const getExerciseFromStorage = () => {
  try {
    return Number(window.localStorage.getItem("xt-exercise"));
  } catch (err) {
    return null;
  }
};

const removeExerciseToStorage = () =>
  window.localStorage.removeItem("xt-exercise");
export default ({ children }) => {
  const exerciseId = getExerciseFromStorage();

  const stepsList = [
    {
      id: 1,
      title: "Objetivo do aprendizado",
      status: "untouched",
      fields: {},
      pos: 1,
      last: false
    },
    {
      id: 2,
      title: "Missão e cenário clínico",
      status: "edited",
      fields: {},
      pos: 2,
      last: false
    },
    {
      id: 3,
      title: "Configuração da simulação",
      status: "completed",
      fields: {},
      pos: 3,
      last: false
    },
    {
      id: 4,
      title: "Questão Avulsa",
      status: "untouched",
      fields: {},
      pos: 4,
      last: false
    },
    {
      id: 5,
      title: "Solução da simulação",
      status: "untouched",
      fields: {},
      pos: 5,
      last: false
    },
    {
      id: 6,
      title: "Pontuação",
      status: "untouched",
      fields: {},
      pos: 6,
      last: false
    },
    {
      id: 7,
      title: "Feedback",
      status: "untouched",
      fields: {},
      pos: 7,
      last: true
    }
  ];
  const [steps] = useState(stepsList);
  const [selectedStep, setSelectedStep] = useState({});
  const [exercise, setExercise] = useState({ id: exerciseId });
  const [isOpenFinishModal, setIsOpenFinishModal] = useState(false);
  const { register, handleSubmit } = useForm();

  let { step } = useParams();
  let history = useHistory();

  const { data: returnedDraft, startFetch: startFetchDraft } = useAuthFetchApi(
    "/exercises/builder/draft/",
    "POST"
  );
  const {
    data: returnedDraftUpdate,
    startFetch: startFetchDraftUpdate
  } = useAuthFetchApi(`/exercises/builder/draft/${exercise.id}`, "PUT");
  const { data: returnedData, startFetch: startFetchBuilder } = useAuthFetchApi(
    "/exercises/builder",
    "POST"
  );
  const {
    data: exerciseFromData,
    startFetch: startFetchGetExercise
  } = useAuthFetchApi(`/exercises/${exercise.id}`, "GET");

  const selected = stepPos => steps.find(step => step.pos === stepPos);

  useEffect(() => {
    exercise.id && startFetchGetExercise();
  }, []);

  useEffect(() => {
    exerciseFromData && setExercise(exerciseFromData);
  }, [exerciseFromData]);

  useEffect(() => {
    setSelectedStep(steps.find(item => item.pos === Number(step)));
  }, [step, steps]);

  const submitExercise = async data => {
    if (Object.keys(data).length > 0) {
      const mountedExercise = mountExercise(data);
      if (mountedExercise.id) {
        startFetchDraftUpdate(JSON.stringify(mountedExercise));
        setExercise(mountedExercise);
      } else {
        startFetchDraft(JSON.stringify(mountedExercise));
        returnedDraft && setExerciseToStorage(returnedDraft.id);
        returnedDraft &&
          setExercise({ ...mountedExercise, id: returnedDraft.id });
      }
    }
  };

  const mountExercise = data => {
    const newExercise = { ...exercise, ...data };
    newExercise.id = exercise.id || getExerciseFromStorage();
    newExercise.categories =
      newExercise.categories &&
      newExercise.categories
        .filter(category => category)
        .map(category => (Number(category) > 0 ? Number(category) : category));
    newExercise.examsFiles =
      newExercise.examsFiles &&
      newExercise.examsFiles.map(examFiles => Number(examFiles));
    newExercise.scoreItems =
      newExercise.scoreItems &&
      newExercise.scoreItems
        .filter(item => item.evaluatedItem.length > 0)
        .map(item => ({
          ...item,
          evaluatedItemId: item.evaluatedItemId[0]
        }));
    return newExercise;
  };

  const finishBuilder = data => {
    // console.log(data)
    // startFetchBuilder(JSON.stringify({ ...exercise, ...data }));
    const exercise = mountExercise(data);
    removeExerciseToStorage();
    // history.push(`/exercises`);
  };

  const goToNextStep = data => {
    submitExercise(data);
    const stepPos = selectedStep.pos + 1;
    selected(stepPos) && history.push(`/builder/step/${stepPos}`);
  };

  const goToPrevStep = data => {
    submitExercise(data);
    const stepPos = selectedStep.pos - 1;
    selected(stepPos) && history.push(`/builder/step/${stepPos}`);
  };

  const selectStep = stepPos => {
    selected(stepPos) && history.push(`/builder/step/${stepPos}`);
  };

  const value = {
    steps,
    goToNextStep,
    goToPrevStep,
    selectedStep,
    selectStep,
    register,
    handleSubmit,
    finishBuilder,
    exercise,
    isOpenFinishModal,
    setIsOpenFinishModal
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
