import React from "react";
import styled from "styled-components";

const Wrapper = styled.h3`
  margin: 0;
`;

const ModalTitle = (props) => <Wrapper {...props} />;

export default ModalTitle;
