import React from "react";
import styled from "styled-components";

import Dygraph from "dygraphs";

const Wrapper = styled.section`
  height: 200px;
  width: 100%;
  color: white;
  padding: 5px 0;
  cursor: crosshair;
`;

const Graph = ({
  graph,
  tint,
  message,
  data,
  scale,
  timeScale,
  setSelectValue,
  isPause
}) => {
  const interval = React.useRef();
  React.useEffect(() => {
    graph.current = new Dygraph(document.getElementById(message), [[0, 0]], {
      height: 190,
      width: "100%",
      legend: "follow",
      valueRange: [scale.min, scale.max],
      axisLineColor: "#FFF",
      includeZero: true,
      drawAxesAtZero: true,
      strokeWidth: 2,
      colors: [tint],
      labels: ["time", message],
      highlightCircleSize: 5,
      showLabelsOnHighlight: false,
      connectSeparatedPoints: true,
      axes: {
        x: {
          drawAxis: true,
          drawGrid: false,
          pixelsPerLabel: 1,
          ticker: function(min) {
            const value = [];
            for (let i = 0; i <= timeScale / 100; i++) {
              value.push({ v: min + i, label: "'" });
            }
            return value;
          }
        },
        y: {
          drawGrid: false,
          valueFormatter: function(value) {
            setSelectValue(Math.round(value));
            return "'";
          }
        }
      },
      zoomCallback: function(minDate, maxDate, yRanges) {
        this.updateOptions({
          dateWindow: null,
          valueRange: null
        });
      }
    });

    interval.current && clearInterval(interval.current);
    if (!isPause) {
      interval.current = setInterval(() => {
        if (data.length > 0) {
          const currentData =
            data.length > timeScale
              ? data.slice(data.length - timeScale, data.length)
              : data;
          graph.current.updateOptions({
            file: currentData,
            opts: {
              highlightSeriesOpts: {
                highlightCircleSize: 5
              }
            }
          });
        }
      }, 10);
    } else {
      if (data.length > 0) {
        const currentData =
          data.length > timeScale
            ? data.slice(data.length - timeScale, data.length)
            : data;
        graph.current.updateOptions({
          file: currentData,
          opts: {
            highlightSeriesOpts: {
              highlightCircleSize: 5
            }
          }
        });
      }
    }

    return () => clearInterval(interval.current);
  }, [graph, data, message, scale.max, scale.min, tint, timeScale, isPause]);

  return (
    <Wrapper>
      <div id={message} />
    </Wrapper>
  );
};

export default Graph;
