import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import { palette } from "../../settings";

const ThemeProvider = ({ children }) => {
  const theme = { palette };
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

export default ThemeProvider;
