import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Icon from "../Icon";
import defaultBg from "../../assets/images/default-bg.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${props => props.theme.palette.initialColor};
  margin: 20px 10px 25px 10px;
  height: 150px;
  border-radius: 7px;
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  display: flex;
  visibility: ${props => (props.premium ? "visible" : "hidden")};
  height: 30px;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
  z-index: 2;
`;

const CarouselItemDescription = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  top: 155px;
  font-size: 18px;
  color: ${props => props.theme.palette.primaryTextColor};
  justify-content: center;
`;

const CarouselContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CarouselItemHeader = ({ premium = false }) => {
  return (
    <HeaderWrapper premium={premium}>
      <Icon icon="lock" size="30" color="#fff" />
    </HeaderWrapper>
  );
};

const CarouselBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const CarouselItem = ({
  exerciseId,
  premium = false,
  description,
  bgImage
}) => {
  const history = useHistory();

  return (
    <Wrapper
      onClick={() => {
        history.push(`/simulator/${exerciseId}`);
      }}
    >
      {bgImage && (
        <CarouselBackground>
          <img
            src={defaultBg}
            alt="Xlung Exercise"
            width={"100%"}
            height={"100%"}
          />
        </CarouselBackground>
      )}
      <CarouselItemHeader premium={premium} />
      <CarouselContent>
        <Icon icon="sampleXlung" size="70" color="#FFFFFF" />
      </CarouselContent>
      <CarouselItemDescription>{description}</CarouselItemDescription>
    </Wrapper>
  );
};

export default CarouselItem;
