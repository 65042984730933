import React, { useCallback, Fragment, useState, forwardRef, useEffect } from "react";
import styled from "styled-components";
import { useDropzone } from 'react-dropzone'

import Icon from "../Icon";
import SelectedFile from "../SelectedFile/SelectedFile";

const ContainerUpload = styled.div`
  width: 100%;
  position: relative;

  border: 1px dashed ${(props) => props.theme.palette.tertiaryTextColor};
  border-radius: 5px;
  padding: 30px 0px;
  text-align: center;
  cursor: pointer;
`;

const ContainerIcons = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
`;

const Description = styled.h4`
  color: ${(props) => props.theme.palette.primaryTextColor};
`;

const Text = styled.span`
  color: ${(props) => props.theme.palette.primaryTextColor};
`;

const Files = styled.div`
  display: flex;
  flex-flow: row wrap;
  max-width: 600px;
  margin: 0 auto;
`;

export default forwardRef(({ onlyImage = false, description, multiple = false, onFilesUpdate }, ref) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    onFilesUpdate && onFilesUpdate(files);
  }, [files])

  const onDrop = useCallback(acceptedFiles => {
    if (multiple) {
      const filtredFiles = acceptedFiles.filter(acceptedFile => {
        return files.find(file => file.path === acceptedFile.path) === undefined;
      });

      setFiles([...files, ...filtredFiles]);
    } else {
      setFiles(acceptedFiles)
    }
  }, [files, multiple]);

  const removeFile = file =>
    setFiles(files.filter(content => content.path !== file.path));

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: multiple,
    accept: onlyImage ? 'image/*' : ''
  });

  const thumbs = files.map(file => (
    <SelectedFile
      key={file.path.toString()}
      content={file}
      onRemoveFile={() => removeFile(file)}
    />
  ));

  return (
    <Fragment>
      <Description>{description}</Description>
      <ContainerUpload {...getRootProps()}>
        <ContainerIcons>
          {!onlyImage && <Icon icon="theaters" size="23" />}
          <Icon icon="insert_photo" size="23" />
        </ContainerIcons>
        {files.length > 0 ? <Files>{thumbs}</Files> :
          <Text>Arraste para cá ou clique aqui para selecionar o arquivo</Text>
        }
        <input {...getInputProps()} />
      </ContainerUpload>
    </Fragment>
  );
});
