import { useEffect, useState } from "react";
import useAuthFetchApi from "../../../hooks/useAuthFetchApi";

export default () => {
  const {
    startFetch: startFetchEvaluation,
    data: fetchEvaluationData,
  } = useAuthFetchApi("/evaluation");

  useEffect(() => {
    startFetchEvaluation();
  }, []);

  return {
    fetchEvaluationData,
  };
};
