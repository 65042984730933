import React from "react";
import { useParams, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import ViewContainer from "./ViewContainer";

import atention from "../assets/images/atention.png";
import game from "../assets/images/game.png";
import clock from "../assets/images/clock.svg";
import moodFace from "../assets/images/moodFace.svg";
import calendar from "../assets/images/calendar.png";

const FeedbackViewContainer = () => {
  const simulatorId = useParams().id;
  const [simulatorAnswer, setSimulatorAnswer] = React.useState(null);
  const [simulatorResults, setSimulatorResults] = React.useState(null);

  const getAnswers = () => {
    const answers = localStorage.getItem(simulatorId) || null;
    const results = localStorage.getItem(simulatorId + "results") || null;

    console.log(JSON.parse(answers));
    console.log(JSON.parse(results));

    if (answers) setSimulatorAnswer(JSON.parse(answers));
    if (results) setSimulatorResults(JSON.parse(results));
  };
  React.useEffect(() => {
    getAnswers();
  }, []);

  const getSpendedTime = () => {
    return (simulatorAnswer.duration - simulatorResults.time_remain) * 60;
  };

  const levelEnum = {
    EASY: "Facíl",
    NORMAL: "Normal",
    HARD: "Difícil"
  };

  return (
    <>
      {simulatorAnswer && (
        <ViewContainer>
          <div
            style={{
              backgroundColor: "white",
              display: "flex"
            }}
          >
            <p style={{ marginLeft: "10%" }}>Gabarito</p>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "90%",
              flexFlow: "row",
              margin: "20px auto",
              gap: "15px"
            }}
          >
            <div
              style={{
                display: "flex",
                flexFlow: "column",
                width: "80%",
                gap: "15px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  backgroundColor: "white",
                  padding: "15px",
                  height: "200px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexFlow: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <span style={{ paddingRight: "10px" }}>
                    {ReactHtmlParser(simulatorAnswer.description)}
                  </span>
                  <div>
                    LEGENDA
                    <div>
                      <span style={{ color: "#02D984", marginRight: "10px" }}>
                        Correto
                      </span>
                      <span style={{ color: "#D93617", marginRight: "10px" }}>
                        Incorreto
                      </span>
                      <span style={{ color: "#2A2A2A", marginRight: "10px" }}>
                        Avaliado
                      </span>
                      <span style={{ color: "#7D7B7B", marginRight: "10px" }}>
                        Não avaliado
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexFlow: "column"
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                      <img src={moodFace} alt="Nota" width="35" />
                      <div>
                        nota<div>0,0</div>
                      </div>
                    </div>
                    <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                      <img src={clock} alt="Tempo" width="35" />
                      <div>
                        Tempo Gasto
                        <div>{getSpendedTime().toFixed(0)} segundos</div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px"
                    }}
                  >
                    <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                      <img src={game} alt="dificudade" />
                      <div>
                        Dificuldade
                        <div>{levelEnum[simulatorAnswer.level]}</div>
                      </div>
                    </div>
                    <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                      <img src={calendar} alt="calendario" />
                      <div>
                        Acesso
                        <div>
                          {new Date().getDate()}/{new Date().getMonth()}/
                          {new Date().getFullYear()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexFlow: "row",
                  alignItems: "center",
                  gap: "15px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    flex: 1,
                    height: "500px",
                    justifyContent: " space-between",
                    backgroundColor: "white",
                    padding: "15px 0"
                  }}
                >
                  <section>
                    <strong style={{ marginLeft: "10px" }}>
                      Gasometria Arterial
                    </strong>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "2fr 1fr 1fr",
                        textAlign: "center"
                      }}
                    >
                      <p />
                      <p>Esperado</p>
                      <p>Realizado</p>
                    </div>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            pH
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .bloodGas.pH.critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.bloodGas.pH.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.gasometria.ph.value.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            PaCO₂
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .bloodGas.paCO2.critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.bloodGas.paCO2.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.gasometria.paco2.value.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            HCO₃
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .bloodGas.hCO3.critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.bloodGas.hCO3.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.gasometria.hco3.value.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            PaO₂
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .bloodGas.paO2.critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.bloodGas.paO2.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.gasometria.PaO2.value.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            SaO₂
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .bloodGas.saO2.critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.bloodGas.saO2.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.gasometria.sao2.value.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            PaO₂/FɪO₂
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .bloodGas.paO2FiO2.critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.bloodGas.paO2FiO2.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.gasometria.PAO2.value.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>

                  <p style={{ marginLeft: "10px" }}>Acertos: 0.0%</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    flex: 1,
                    height: "500px",
                    justifyContent: " space-between",
                    backgroundColor: "white",
                    padding: "15px 0"
                  }}
                >
                  <section>
                    <strong style={{ marginLeft: "10px" }}>
                      Ajustes do ventilador - Modo PCV
                    </strong>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "2fr 1fr 1fr",
                        textAlign: "center"
                      }}
                    >
                      <p />
                      <p>Esperado</p>
                      <p>Realizado</p>
                    </div>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            ∆p (cmH₂O)
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .ventilationModeParameters.pressure.critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.ventilationModeParameters.pressure.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.ventilator.parameters.pressure.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            T. Subida (s)
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .ventilationModeParameters.riseTime.critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.ventilationModeParameters.riseTime.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.ventilator.parameters.riseTime.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            f (rpm)
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .ventilationModeParameters.frequency.critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.ventilationModeParameters.frequency.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.ventilator.parameters.frequency.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            T. Insp. (s)
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .ventilationModeParameters.inspiratoryTime
                                .critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.ventilationModeParameters.inspiratoryTime.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.ventilator.parameters.inspiratoryTime.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            Pausa Insp.(s)
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .ventilationModeParameters.inspiratoryPause
                                .critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.ventilationModeParameters.inspiratoryPause.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.ventilator.parameters.inspiratoryPause.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            Pausa Exp. (s)
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .ventilationModeParameters.expiratoryPause
                                .critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.ventilationModeParameters.expiratoryPause.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.ventilator.parameters.expiratoryPause.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            FɪO₂ (%)
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .ventilationModeParameters.fio2.critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.ventilationModeParameters.fio2.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.ventilator.parameters.fio2.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr"
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            PEEP (cmH₂O)
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px",
                              backgroundColor: simulatorAnswer.expectedResults
                                .ventilationModeParameters.peep.critical
                                ? "#c2c2c2"
                                : "white"
                            }}
                          >
                            {simulatorAnswer.expectedResults.ventilationModeParameters.peep.value.toFixed(
                              2
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderCollapse: "collapse",
                              padding: "10px"
                            }}
                          >
                            {simulatorResults.simulation.ventilator.parameters.peep.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>

                  <p style={{ marginLeft: "10px" }}>Acertos: 0.0%</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexFlow: "column",
                width: "40%",
                gap: "15px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  backgroundColor: "white",
                  padding: "15px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "200px"
                }}
              >
                <div
                  style={{
                    textAlign: "center"
                  }}
                >
                  <img src={atention} alt="atention" />
                  <p>Cadastre-se para comentar sobre o exercício.</p>
                </div>
                <p>Cadastrar ou Fazer Login</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  flex: 1,
                  height: "500px",
                  justifyContent: " space-between",
                  backgroundColor: "white",
                  padding: "15px 0"
                }}
              >
                <section>
                  <strong style={{ marginLeft: "10px" }}>
                    Monitorização ventilatória
                  </strong>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 1fr 1fr",
                      textAlign: "center"
                    }}
                  >
                    <p />
                    <p>Esperado</p>
                    <p>Realizado</p>
                  </div>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 1fr 1fr"
                        }}
                      >
                        <td
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          f (rpm)
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px",
                            backgroundColor: simulatorAnswer.expectedResults
                              .ventilatoryParameters.frequency.critical
                              ? "#c2c2c2"
                              : "white"
                          }}
                        >
                          {simulatorAnswer.expectedResults.ventilatoryParameters.frequency.value.toFixed(
                            2
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          {simulatorResults.simulation.ventilator.parameters.frequency.toFixed(
                            2
                          )}
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 1fr 1fr"
                        }}
                      >
                        <td
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          T. Insp. (s)
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px",
                            backgroundColor: simulatorAnswer.expectedResults
                              .ventilatoryParameters.inspiratoryTime.critical
                              ? "#c2c2c2"
                              : "white"
                          }}
                        >
                          {simulatorAnswer.expectedResults.ventilatoryParameters.inspiratoryTime.value.toFixed(
                            2
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          {simulatorResults.simulation.ventilator.parameters.inspiratoryTime.toFixed(
                            2
                          )}
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 1fr 1fr"
                        }}
                      >
                        <td
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          T. Exp. (s)
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px",
                            backgroundColor: simulatorAnswer.expectedResults
                              .ventilatoryParameters.expiratoryPause.critical
                              ? "#c2c2c2"
                              : "white"
                          }}
                        >
                          {simulatorAnswer.expectedResults.ventilatoryParameters.expiratoryPause.value.toFixed(
                            2
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          {simulatorResults.simulation.ventilator.parameters.expiratoryPause.toFixed(
                            2
                          )}
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 1fr 1fr"
                        }}
                      >
                        <td
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          VC (ml)
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px",
                            backgroundColor: simulatorAnswer.expectedResults
                              .ventilatoryParameters.volume.critical
                              ? "#c2c2c2"
                              : "white"
                          }}
                        >
                          {simulatorAnswer.expectedResults.ventilatoryParameters.volume.value.toFixed(
                            2
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          7,31
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 1fr 1fr"
                        }}
                      >
                        <td
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          VE (L/min)
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          {simulatorAnswer.expectedResults.bloodGas.paO2FiO2.value.toFixed(
                            2
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          7,31
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 1fr 1fr"
                        }}
                      >
                        <td
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          P0.1 (cmH₂O)
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          {simulatorAnswer.expectedResults.bloodGas.paO2FiO2.value.toFixed(
                            2
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          7,31
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 1fr 1fr"
                        }}
                      >
                        <td
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          PTP (cmH₂O.s.m⁻¹)
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          {simulatorAnswer.expectedResults.bloodGas.paO2FiO2.value.toFixed(
                            2
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          7,31
                        </td>
                      </tr>
                      <tr
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 1fr 1fr"
                        }}
                      >
                        <td
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          W (Joules/l)
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          {simulatorAnswer.expectedResults.bloodGas.paO2FiO2.value.toFixed(
                            2
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderCollapse: "collapse",
                            padding: "10px"
                          }}
                        >
                          7,31
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <p style={{ marginLeft: "10px" }}>Acertos: 0.0%</p>
              </div>
            </div>
          </div>
        </ViewContainer>
      )}
    </>
  );
};

export default FeedbackViewContainer;
