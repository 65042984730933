import React from "react";
import styled from "styled-components";

import ExamDisplay from "../../ExamDisplay";

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.palette.quaternaryTextColor};
  background-color: ${props => props.theme.palette.darkerColor};
`;

const ExamWrapper = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: ${props => props.theme.palette.secondaryColor};
`;

const ExamItem = styled.div`
  width: 49.7%;
  margin-bottom: 1px;
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const ExamsVentilometria = ({ results }) => {
  return (
    <Wrapper>
      {results && (
        <ExamWrapper>
          <ExamItem>
            <ExamDisplay
              title="VCi"
              value={(600 * results.tidalVolume).toFixed(1)}
              label="(ml)"
              tintValue="successColor"
            />
          </ExamItem>
          <ExamItem>
            <ExamDisplay
              title="VCe"
              value={(600 * results.expiredTidalVolumePerKilogram).toFixed(1)}
              label="(ml)"
              tintValue="successColor"
            />
          </ExamItem>
          <ExamItem>
            <ExamDisplay
              title="VCi/ peso ideal"
              value={(1000 * results.tidalVolumePerKilogram).toFixed(1)}
              label="(ml/kg)"
              tintValue="successColor"
            />
          </ExamItem>
          <ExamItem>
            <ExamDisplay
              title="VCe/ peso ideal"
              value={(1000 * results.expiredTidalVolumePerKilogram).toFixed(1)}
              label="(ml/kg)"
              tintValue="successColor"
            />
          </ExamItem>

          <ExamItem>
            <ExamDisplay
              title="F. Resp"
              value={results.frequency}
              label="(rpm)"
              tintValue="successColor"
            />
          </ExamItem>

          <ExamItem>
            <ExamDisplay
              title="VE"
              value={results.ve.toFixed(2)}
              label="(l/min)"
              tintValue="successColor"
            />
          </ExamItem>
        </ExamWrapper>
      )}
    </Wrapper>
  );
};

export default ExamsVentilometria;
