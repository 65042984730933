import React from "react";

import styled from "styled-components";

const Container = styled.div`
  ${props => props.inline && "display: flex; flex-direction: row;"}

  background-color: #fff;
  padding: 10px;
`;

const TitleInput = styled.h3`
  flex-basis: 100%;
  margin: 0;
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  padding: ${(props) => (props.nopadding ? "30px 30px 0px 30px" : "0px")};
`;

export default ({ className, children, title, inline, nopadding, ...props }) => {
  return (
    <Container className={className} inline={inline} nopadding={nopadding} {...props}>
      {title && <TitleInput nopadding={nopadding}>{title}</TitleInput>}
      {children}
    </Container>
  );
};
