import React, { Fragment } from "react";

import BuilderWizardStep from "../BuilderWizardStep";
import styled from "styled-components";

import StyledField from "../Field";
import Input from "../Input";
import StyledTextArea from "../TextArea";
import StyledButton from "../Button";
import BuilderFormSection from "../BuilderFormSection";
import { lighten } from "polished";
import Icon from "../Icon";

const BuilderFormSectionEdited = styled(BuilderFormSection)`
  h3 {
    font-weight: 400;
  }
`;

const Button = styled(StyledButton)`
  font-size: 1rem;
  padding: 0.4rem 1rem;
  text-transform: none;
  color: ${(props) => props.theme.palette.secondaryTextColor};
  border-color: ${(props) => props.theme.palette.secondaryTextColor};

  &:hover, &:focus {
    background-color: ${(props) => lighten(0.4, props.theme.palette.secondaryTextColor)};
  }
`;

const TextArea = styled(StyledTextArea)`
  margin: 0;
`;

const Label = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.palette.primaryTextColor};
  margin: 10px 0;
  font-weight: bolder;
`;

const Field = styled(StyledField)`
  ${Label} {
    text-transform: none;
    color: ${(props) => props.theme.palette.primaryTextColor};
    margin: 10px 0;
    font-weight: bolder;
  }
`;

const Separator = styled.hr`
  margin-left: -15px;
  margin-right: -15px;
  border-top: 1px;
`;

const RemoveButton = styled(Button)`
  position: absolute;
  right: 0;
  margin-right: 10px;
`;

export default ({ register, handleAddTheme, handleDelTheme, themes, exercise }) => (
  <BuilderWizardStep>
    <BuilderFormSectionEdited title={<span><strong>Insira o link do vídeo (comentário sobre a simulação) que estará
        disponível para visualização após o exercício</strong> (Opcional)</span>} />
    <BuilderFormSection title="Link do vídeo">
      <Field
        name="linkToVideoComment"
        defaultValue={exercise.linkToVideoComment}
        component={Input}
        placeholder="Insira aqui o link do vídeo"
        ref={register}
      />
    </BuilderFormSection>
    {themes.map(({ id, item }, index) => (
      <Fragment key={id}>
        <Separator />
        {id > 1 &&
          <RemoveButton
            variant="outlined"
            size="tiny"
            onClick={handleDelTheme(id)}
          >
            <Icon icon="clear" />
          </RemoveButton>
        }
        <BuilderFormSectionEdited title={<span><strong>Adicione o material complementar a respeito do exercício</strong> (Opcional)</span>} />
        <BuilderFormSection title="Tema">
          <Field
            name={`complementaryMaterial[${index}][theme]`}
            defaultValue={item && item.theme}
            component={Input}
            placeholder="Ex: Auto PEEP"
            ref={register}
          />
        </BuilderFormSection>
        <BuilderFormSection title="Link">
          <Field
            name={`complementaryMaterial[${index}][link]`}
            defaultValue={item && item.link}
            component={Input}
            placeholder="Insira aqui o link do material"
            ref={register}
          />
        </BuilderFormSection>
        <BuilderFormSection title="Comentário">
          <Field
            name={`complementaryMaterial[${index}][comment]`}
            defaultValue={item && item.comment}
            component={TextArea}
            placeholder="Insira aqui um comentário sobre o tema"
            ref={register}
          />
        </BuilderFormSection>
      </Fragment>
    ))}
    <BuilderFormSection>
      <Button
        variant="outlined"
        onClick={handleAddTheme}
      >
        + Adicionar outro tema
          </Button>
    </BuilderFormSection>
  </BuilderWizardStep >
);
