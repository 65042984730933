import React from "react";
import styled from "styled-components";

import Button from "../../../../components/Button";
import StyledIcon from "../../../Icon";

const Wrapper = styled.header`
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 70px;
  border-radius: 5px;
  padding: 10px;
  background-color: ${props => props.theme.palette.darkerColor};
  box-shadow: 0px 0px 3px ${props => props.theme.palette.mediumGrayColor};
  zindex: 1;
`;

const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const HeaderItem = styled.section`
  min-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: ${props => props.theme.palette.darkerGrayColor};
`;

const ButtonContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  z-index: 10;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const Icon = styled(StyledIcon)`
  margin: 0 5px;
  fill: #ffffff;
  size: 70px;
  &:hover,
  &:focus {
    fill: ${props => props.theme.palette.alertColor};
  }
`;

const IconInputContainer = styled.section`
  position: relative;
  margin: 0 5px;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background-color: ${props =>
    props.selected
      ? props.theme.palette.darkerColor
      : props.theme.palette.secondaryColor};
`;

const LabelInput = styled.span`
  font-size: 12px;
`;

const InputModalSelect = ({
  close,
  title,
  value = 0,
  setValue = () => null
}) => {
  const [newValue, setNewValue] = React.useState(value);

  const onSelect = value => {
    setNewValue(value);
  };

  return (
    <Wrapper>
      <HeaderSection>
        {title}
        <Icon icon="close" size={25} onClick={() => close()} />
      </HeaderSection>
      <HeaderItem>
        <IconInputContainer
          selected={newValue === 0.5}
          onClick={() => onSelect(0.5)}
        >
          <div style={{ position: "absolute", top: 0, left: 5 }}>
            <svg id="line">
              <polyline
                points="0,25 0,5 20,5 20,25"
                stroke={newValue === 0.5 ? "white" : "black"}
                strokeWidth="2"
                fill="none"
              />
            </svg>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: -30,
              left: 0,
              right: 0,
              fontSize: "0.5rem",
              textAlign: "center"
            }}
          >
            <LabelInput>0.50</LabelInput>
          </div>
        </IconInputContainer>
        <IconInputContainer
          selected={newValue === 0.67}
          onClick={() => onSelect(0.67)}
        >
          <div style={{ position: "absolute", top: 0, left: 5 }}>
            <svg id="line1">
              <polyline
                points="0,25 0,5 20,15 20,25"
                stroke={newValue === 0.67 ? "white" : "black"}
                strokeWidth="2"
                fill="none"
              />
            </svg>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: -30,
              left: 0,
              right: 0,
              fontSize: "0.5rem",
              textAlign: "center"
            }}
          >
            <LabelInput>0.67</LabelInput>
          </div>
        </IconInputContainer>
        <IconInputContainer
          selected={newValue === 1}
          onClick={() => onSelect(1)}
        >
          <div style={{ position: "absolute", top: 0, left: 5 }}>
            <svg id="line2">
              <polyline
                points="0,25 0,5 20,25"
                stroke={newValue === 1.0 ? "white" : "black"}
                strokeWidth="2"
                fill="none"
              />
            </svg>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: -30,
              left: 0,
              right: 0,
              fontSize: "12px",
              textAlign: "center"
            }}
          >
            <LabelInput>1.0</LabelInput>
          </div>
        </IconInputContainer>
      </HeaderItem>
      <ButtonContainer>
        <Button
          palette="darkerGray"
          variant="filled"
          size="tiny"
          onClick={() => close()}
        >
          Cancela
        </Button>
        <Button
          palette="info"
          variant="filled"
          size="tiny"
          onClick={() => {
            setValue(newValue);
            close();
          }}
        >
          Ok
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export { InputModalSelect };
