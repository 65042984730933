import React from "react";
import styled from "styled-components";

import Modal from "../Modal";
import ModalHeader from "../ModalHeader";

import StyledModalBody from "../ModalBody";

import Form from "../LoginForm";
import StyledLoader from "../Loader";

import LogoSvg from "../../assets/images/logoxlung.svg";

import { DEFAULT_PADDING } from "../../settings";

const LogoImg = styled.img`
  width: 150px;
  display: flex;
  margin: 0 auto;
`;

const ModalBody = styled(StyledModalBody)`
  padding: 30px;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LinkRegister = styled.a`
  margin-top: ${DEFAULT_PADDING};
  display: flex;
  justify-content: flex-end;
`;

const Loader = styled(StyledLoader)`
  display: flex;
  margin-top: ${DEFAULT_PADDING};
`;

const LoginModal = ({
  onSubmit,
  registerEmailField,
  registerPasswordField,
  values,
  errors,
  isAuthenticating = false,
  onClose,
  ...rest
}) => {
  return (
    <Modal
      header={<ModalHeader onClose={onClose} />}
      body={
        <ModalBody>
          <LogoImg src={LogoSvg} />
          {isAuthenticating && <Loader title="Autenticando" />}
          <Form
            onSubmit={onSubmit}
            registerEmailField={registerEmailField}
            registerPasswordField={registerPasswordField}
            errors={errors}
            values={values}
          />
          <LinkRegister href="##">Não possui conta? Cadastre-se</LinkRegister>
        </ModalBody>
      }
      {...rest}
    />
  );
};

export default LoginModal;
