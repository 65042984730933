import React from "react";
import BuilderWizardStepOne, { useBuilderWizardStepOneForm } from "../../components/BuilderWizardStepOne";

export default ({ exercise, register }) => {
  const {
    examFiles,
    coverFile,
    fetchCategoriesData,
    fetchLevelsData,
    onExamFilesUpdate,
    onCoverFileUpdate,
  } = useBuilderWizardStepOneForm({ exercise, register });

  return (
    <BuilderWizardStepOne
      exercise={exercise || {}}
      fetchCategoriesData={fetchCategoriesData || []}
      fetchLevelsData={fetchLevelsData || []}
      examFiles={examFiles}
      coverFile={coverFile}
      onExamFilesUpdate={onExamFilesUpdate}
      onCoverFileUpdate={onCoverFileUpdate}
      register={register}
    />
  );
};
