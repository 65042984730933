import React from "react";
import styled from "styled-components";

import xlung from "../../assets/images/logoxlung.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`;

const Logo = styled.img`
  width: 180px;
`;

const SidebarHeaer = () => (
  <Wrapper>
    <Logo src={xlung} alt="Xlung" />
  </Wrapper>
);

export default SidebarHeaer;
