import React from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const Form = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

export default Form;
