import React from "react";

import BuilderWizardContainer from "../builder/BuilderWizardContainer";
import ViewContainer from "../ViewContainer";

import { BuilderWizardProvider } from "../../contexts/BuilderWizard";

const BuilderContainer = () => {
  return (
    <ViewContainer>
      <BuilderWizardProvider>
        <BuilderWizardContainer />
      </BuilderWizardProvider>
    </ViewContainer>
  );
};

export default BuilderContainer;
