import { useForm } from "react-hook-form";

const useNewsletterForm = () => {
  const { register, ...rest } = useForm({
    mode: "onBlur",
  });

  const emailRE = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const registerEmailField = (ref) =>
    register(ref, {
      required: {
        value: true,
        message: "Este campo é obrigatório",
      },
      validate: (value) => emailRE.test(value) || "Digite um email válido",
    });

  return {
    registerEmailField,
    ...rest,
  };
};

export default useNewsletterForm;
