export default {
  ROOT: "/",
  LOGIN: "/login",
  OPINION: "/opinion",
  ACCOUNT: "/account",
  EXERCISES: "/exercises",
  MYEXERCISES: "/myexercises",
  FAVOURITES: "/favourites",
  HELP: "/help",
  BUILDER: "/builder/step/:step",
  PROGRESS: "/progress",
  SIMULATOR: "/simulator/:id",
  FEEDBACK: "/simulator/feedback/:id"
};
