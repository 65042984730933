import React from "react";
import styled, { css } from "styled-components";
import { DEFAULT_PADDING } from "../../settings";

const initial = css`
  background-color: #fff;
  border-color: ${props => props.theme.palette.initialColor};
  color: ${props => props.theme.palette.primaryTextColor};
`;

const simulator = css`
  background-color: ${props =>
    props.active
      ? props.theme.palette.infoColor
      : props.theme.palette.darkerGrayColor};
  border-width: 0px;
  color: ${props => props.theme.palette.quaternaryTextColor};
`;

const Wrapper = styled.section`
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding: ${props => (props.padding && DEFAULT_PADDING) || "0"};
  ${props => props.palette === "initial" && initial};
  ${props => props.palette === "simulator" && simulator};
`;

const Card = ({
  palette = "initial",
  padding = true,
  children,
  className,
  active,
  onClick = () => null
}) => (
  <Wrapper
    className={className}
    palette={palette}
    padding={padding}
    active={active}
    onClick={() => onClick()}
  >
    {children}
  </Wrapper>
);

export default Card;
