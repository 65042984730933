import React from "react";
import styled, { css } from "styled-components";
import Icon from "../Icon";
import Slider from "react-styled-carousel";
import CarouselItem from "../../components/CarouselItem";

const Wrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  padding: 20px 0;
`;

const Container = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`;

const CarouselHeader = styled.div`
  display: flex;
  margin: 0 30px;
`;

const CarouselTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

const CarouselStatements = styled.div`
  display: flex;
  align-items: center;
  margin: 0 30px;
  font-size: 20px;
  color: ${props => props.theme.palette.secondaryColor};
`;

const left = css`
  left: -20px;
`;

const right = css`
  right: -20px;
`;

const ControlButton = styled.button`
  ${props => props.left && left}
  ${props => props.right && right}
    position: absolute;
  padding: 0;
  height: 0;
  top: 80px;
  border: none;
  cursor: pointer;
  outline: none;
`;

const Carousel = ({ title, statements, cardsToShow, exercises }) => {
  return (
    <Wrapper>
      <CarouselHeader>
        <CarouselTitle>{title}</CarouselTitle>
        <CarouselStatements>{statements}</CarouselStatements>
      </CarouselHeader>

      <Container>
        <Slider
          cardsToShow={cardsToShow}
          DotsWrapper={() => null}
          showDots={false}
          autoSlide={false}
          showArrows={true}
          LeftArrow={
            <ControlButton left>
              <Icon icon="chevronLeft" size="36" />
            </ControlButton>
          }
          RightArrow={
            <ControlButton right>
              <Icon icon="chevronRight" size="36" />
            </ControlButton>
          }
        >
          {exercises.map((exercise, index) => (
            <CarouselItem
              key={index}
              // bgImage={exercise.image}
              description={exercise.name}
              premium={exercise.premium}
              exerciseId={exercise.id}
            />
          ))}
        </Slider>
      </Container>
    </Wrapper>
  );
};

export default Carousel;
