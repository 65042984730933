import React from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div``;

const Text = styled.h4`
  color: ${(props) => props.theme.palette.primaryTextColor};
  font-size: 18px;
`;

const base = css`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  resize: none;

  border-radius: 5px;
  font-size: 16px;

  ::placeholder {
    color: ${(props) => props.theme.palette.tertiaryTextColor};
    font-size: 16px;
  }
`;

const valid = css`
  border: 1px solid ${(props) => props.theme.palette.tertiaryTextColor};
`;

const invalid = css`
  border: 1px solid ${(props) => props.theme.palette.dangerColor};
`;

const TextAreaInput = styled.textarea`
  ${base}
  ${(props) => props.valid && valid};
  ${(props) => !props.valid && invalid};
`;

const TextArea = React.forwardRef(
  (
    { name = "textarea", description, valid = true, onChange, placeholder, ...rest },
    ref
  ) => (
      <Wrapper>
        {description && <Text>{description}</Text>}
        <TextAreaInput
          ref={ref}
          name={name}
          placeholder={placeholder}
          rows="5"
          valid={valid}
          onChange={onChange}
          {...rest}
        />
      </Wrapper>
    )
);

export default TextArea;
