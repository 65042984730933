import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const getButtonColor = props => {
  if (props.basic) return props.theme.palette.quaternaryTextColor;
  if (!props.active) return props.theme.palette.secondaryTextColor;
  return props.theme.palette.primaryTextColor;
};

const base = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 5px;
  color: ${props => getButtonColor(props)};
  &:hover {
    color: ${props => props.theme.palette.primaryTextColor};
    cursor: pointer;
  }
`;

let Wrapper = styled(Link)`
  ${base}
`;

let LinkExternal = styled.a`
  ${base}
`;

const ButtonLink = ({
  children,
  to = "#",
  active = false,
  basic = true,
  onClick,
  className,
  ...rest
}) => {
  if (to.indexOf("http") !== -1 || to.indexOf("mailto:") !== -1) {
    return (
      <LinkExternal
        href={to}
        active={active ? 1 : 0}
        basic={basic.toString()}
        onClick={onClick}
        className={className}
        {...rest}
      >
        {children}
      </LinkExternal>
    );
  }
  return (
    <Wrapper
      to={to}
      active={active ? 1 : 0}
      basic={basic.toString()}
      onClick={onClick}
      className={className}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

export default ButtonLink;
