import React from "react";

const INITIAL_OFFSET = 25;
const circleConfig = {
  x: "30",
  y: "30",
  radio: "16",
  strokeWidth: "3",
};

const Timer = ({ className, size = "50", percentage }) => {
  return (
    <>
      <svg className={className} width={size} height={size}>
        <circle
          className="ring"
          cx={size / 2}
          cy={size / 2}
          r={circleConfig.radio}
          fill="transparent"
          strokeWidth={circleConfig.strokeWidth}
          stroke="red"
        />

        <circle
          className="background"
          cx={size / 2}
          cy={size / 2}
          r={circleConfig.radio}
          fill="none"
          strokeWidth={circleConfig.strokeWidth}
          stroke="white"
          strokeDasharray={`${percentage} ${100 - percentage}`}
          strokeDashoffset={INITIAL_OFFSET}
        />
        <circle
          className="path"
          cx={size / 2}
          cy={size / 2}
          r={circleConfig.radio / 3}
          fill="none"
          strokeWidth={10}
          stroke={percentage > 25 ? "white" : "red"}
          strokeDasharray={`${percentage / 3} ${(100 - percentage) / 3}`}
          strokeDashoffset={INITIAL_OFFSET * 3}
        />
      </svg>
    </>
  );
};

export default Timer;
